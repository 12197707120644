{
  "budget": "Budget",
  "income": "Income | Incomes",
  "variable-expense": "Variable expense | Variable expenses",
  "fixed-expense": "Fixed expense | Fixed expenses",
  "result": "Result | Results",
  "accounted-result": "Accounted result",
  "balance": "Balance",
  "expenses": "Expenses",
  "budget-result": "Budget-result",
  "choose-your-language": "Choose your language",
  "choose-the-family-you-want-to-work-with": "Choose the family you want to work with",
  "new-family": "New family",
  "accounts-in": "Accounts in",
  "new": "New",
  "delete": "Delete",
  "accounted": "Accounted",
  "budget-categories": "Budget categories",
  "edit": "Edit",
  "new-category": "New category",
  "edit-category": "Edit category",
  "create": "Create",
  "save": "Save",
  "category-name": "Category name",
  "description": "Description",
  "name-is-required": "Name is required",
  "name-must-be-less-than-255-characters": "Name must be less than 255 characters",
  "edit-account": "Edit account",
  "account-number": "Account number",
  "name": "Name",
  "statistics": "Statistics",
  "welcome-to-factum": "Welcome to Factum",
  "sign-in": "Sign In",
  "oops-nothing-here": "Oops. Nothing here...",
  "go-home": "Go Home",
  "home": "Home",
  "login-to-use-factum": "Login to use Factum",
  "email": "Email",
  "password": "Password",
  "keep-me-logged-in": "Keep me logged in",
  "forgot-password": "Forgot password",
  "cancel": "Cancel",
  "close-window": "Close window",
  "required": "Required",
  "enter-your-e-mail-to-reset-your-password": "Enter your e-mail to reset your password",
  "invalid-e-mail": "Invalid e-mail.",
  "invalid-username-or-password": "Invalid username or password",
  "your-user-account-was-created-successfully": "Your user account was created successfully!",
  "account-type": "Account type",
  "email-verified": "Email verified",
  "account-created": "Account created",
  "create-your-factum-account": "Create your Factum account",
  "this-email-is-already-registered": "This email is already registered",
  "you-can-now-log-in": "You can now log in",
  "check-your-inbox-to-continue!": "Check your inbox to continue!",
  "create-account": "Create account",
  "sign-up-for-your-free-factum-account-now": "Sign up for your free Factum account now",
  "first-name": "First name",
  "last-name": "Last name",
  "birthdate": "Birthdate",
  "repeat-password": "Repeat password",
  "register": "Register",
  "the-passwords-does-not-match": "The passwords does not match",
  "change-password": "Change password",
  "reset-your-password": "Reset your password",
  "an-error-occured-when-changing-the-password": "An error occured when changing the password",
  "sendt": "Sendt",
  "you-must-verify-your-email": "You must verify your email",
  "resend-email": "Resend email",
  "logout": "Logout",
  "invitations-to-families": "Invitations to families",
  "you-got-invited-by": "You got invited by",
  "role": "Role",
  "accept": "Accept",
  "decline": "Decline",
  "families": "Families",
  "inventory": "Inventory",
  "calendar": "Calendar",
  "more": "More",
  "my-profile": "My profile",
  "add-profile-picture": "Add profile picture",
  "language": "Language",
  "norwegian": "Norwegian",
  "english": "English",
  "profile-image": "Profile image",
  "personal-information": "Personal information",
  "my-families": "My families",
  "saving": "Saving",
  "add": "Add",
  "from-date": "From date",
  "to-date": "To date",
  "recurrency": "Recurrency",
  "amount": "Amount",
  "actions": "Actions",
  "repeat-every": "Repeat every",
  "months": "Month | Months",
  "years": "Years",
  "new-account": "New account",
  "save-and-close": "Save and close",
  "category": "Category",
  "difference": "Difference",
  "forecast": "Forecast",
  "fill": "Fill",
  "date": "Date",
  "action": "Action",
  "subtract": "Subtract",
  "set-to": "Set to",
  "events": "Events",
  "top-expenses": "Top expenses",
  "others": "Others",
  "expense": "Expense",
  "accounting": "Accounting",
  "vouchers": "Vouchers",
  "search": "Search",
  "vouchers-per-page": "Vouchers per page",
  "new-voucher": "New voucher",
  "edit-voucher": "Edit voucher",
  "drop-files-here": "Drop files here",
  "drag-n-drop": "Drag & Drop",
  "or-select-files": "or select files",
  "no-files": "No files",
  "account": "Account",
  "note": "Note",
  "credit": "Credit",
  "debit": "Debit",
  "invalid-account-number": "Invalid account number",
  "money": "Money",
  "bank-account": "Bank account",
  "cash": "Cash",
  "savings-account": "Savings account",
  "initial-balance": "Initial balance",
  "liquidity-forecast": "Liquidity forecast",
  "money-accounts": "Money accounts",
  "no-money-accounts": "No money accounts",
  "create-one-now": "Create one now",
  "transactions": "Transactions",
  "no-transactions-matches-the-filter": "No transactions matches the filter",
  "every": "every",
  "month": "month",
  "family-name": "Family name",
  "create-a-family": "Create a family",
  "create-a-new-family-and-start-from-scratch": "Create a new family and start from scratch",
  "you-are-not-member-of-any-families": "You are not member of any families",
  "you-need-to-be-member-of-a-family-in-order-to-use-factum": "You need to be member of a family in order to use Factum",
  "you-have-two-options": "You have two options",
  "wait-for-an-invitation": "Wait for an invitation",
  "have-someone-else-invite-you-to-their-family": "Have someone else invite you to their family",
  "others-can-invite-you-by-your-email": "Others can invite you by your email",
  "when-someone-invites you-the-invitation-will-show-up-here": "When someone invites you, the invitation will show up here",
  "you-are-logged-in-as": "You are logged in as",
  "you-need-to-wait-a-bit-before-you-can-do-this-again": "You need to wait a bit before you can do this again",
  "owner": "Owner",
  "user": "User",
  "reader": "Reader",
  "edit-family": "Edit family",
  "members": "Members",
  "remove": "Remove",
  "invite-someone": "Invite someone",
  "send-invitation": "Send invitation",
  "the-invitation-was-sendt": "The invitation was sent",
  "invited-by": "Invited by",
  "simple-registration": "Simple registration",
  "current-money-balance": "Current money balance",
  "upcoming-events": "Upcoming events",
  "in": "In",
  "budget-increased-by": "Budget increased by",
  "budget-decreased-by": "Budget decreased by",
  "budget-changed-to": "Budget changed to",
  "ok": "Ok",
  "delete-voucher": "Delete voucher",
  "do-you-really-want-to-delete-this-voucher": "Do you really want to delete this voucher?",
  "delete-account": "Delete account",
  "do-you-really-want-to-delete-this-account": "Do you really want to delete this account?",
  "january": "January",
  "february": "February",
  "march": "March",
  "april": "April",
  "may": "May",
  "june": "June",
  "july": "July",
  "august": "August",
  "september": "September",
  "october": "October",
  "november": "November",
  "december": "December",
  "select-period": "Select period",
  "this-affects-many-parts-of-the-system": "This affects many parts of the system",
  "dark": "Dark",
  "no-category-selected": "No category selected",
  "no-vouchers-matches-the-filter": "No vouchers matches the filter",
  "filter": "Filter",
  "each": "each",
  "MONTH": "Month",
  "YEAR": "Year",
  "from": "From",
  "budgets": "Budgets",
  "back": "Back",
  "remaining": "Remaining",
  "budget-account": "Budget Account",
  "event": "Event",
  "increase-by": "Increase by",
  "to-be-placed": "To be placed",
  "recurring-budgets": "Recurring budgets",
  "recurring-budgets-set-a-fixed-amount-for-the-account-within-a-specific-interval": "Recurring budgets set a fixed amount for the account within a specific interval",
  "this-can-be-overridden-by-using-events": "This can be overridden by using events",
  "current": "Current",
  "edit-budget": "Edit budget",
  "budget-events": "Budget events",
  "budget-events-can-modify-a-recurring-budget-when-something-occurs": "Budget events can modify a recurring budget when something occurs",
  "edit-budget-event": "Edit budget event",
  "new-budget": "New budget",
  "no-budgets-for-this-account": "No budgets for this account",
  "no-budget-events-for-this-account": "No budget events for this account",
  "shows-a-list-of-vouchers-within-a-period-of-time": "Shows a list of vouchers within a period of time",
  "no-accounts": "No accounts",
  "shows-the-usage-in-this-account-over-time": "Shows the usage in this account over time",
  "created-by": "Created by",
  "updated-by": "Updated by",
  "error-during-save": "Error during save",
  "an-error-occured-during-saving": "An error occured during saving",
  "no-budget-events-this-month": "No budget events this month",
  "no-persons": "No persons",
  "select-icon": "Select icon",
  "apple": "Apple",
  "software": "Software",
  "select-an-icon": "Select an icon",
  "remove-icon": "Remove icon",
  "settings": "Settings",
  "notifications": "Notifications",
  "use-simple-language": "Use simple language",
  "from-account": "From account",
  "to-account": "To account",
  "push": "Push",
  "daily-accounting-reminder": "Daily accounting reminder",
  "budget-exceeded": "Budget exceeded",
  "voucher-created": "Voucher created",
  "users": "Users",
  "invitations": "Invitations",
  "persons": "Persons",
  "make-changes-to-your-family": "Make changes to your family",
  "family": "Family",
  "manage-the-users-that-can-access-this-family": "Manage the users that can access this family",
  "guest": "Guest",
  "remove-user": "Remove user",
  "saved-changes": "Saved changes",
  "admin": "Admin",
  "do-you-really-want-to-remove-this-user": "Do you really want to remove this user? The user will loose access to the family immediately.",
  "the-user-was-successfully-removed": "The user was successfully removed",
  "invite-someone-to-this-family-or-edit-already-sent-invitations": "Invite someone to this family, or edit already sent invitations",
  "invite-someone-using-their-email-address": "Invite someone using their email address",
  "delete-invitation": "Delete invitation",
  "the-invitation-was-deleted": "The invitation was deleted",
  "do-you-really-want-to-delete-this-invitation": "Do you really want to delete this invitation",
  "the-invitation-was-sent": "The invitation was sent",
  "send": "Send",
  "a-list-of-persons-in-the-family": "A list of persons in the family",
  "this-information-is-used-to-calculate-budgets-and-show-statistics-of-income-and-expenses": "This information is used to calculate budgets, and show statistics of income and expenses",
  "add-person": "Add person",
  "birth-year": "Birth year",
  "gender": "Gender",
  "male": "Male",
  "female": "Female",
  "create-a-new-family-now": "Create a new family now",
  "add-family": "Add family",
  "reading-mode": "Reading mode",
  "you-dont-have-access-to-perform-any-changes-to-this-family": "You dont have access to perform any changes to this family",
  "server-error": "Server error",
  "missing-anything": "Missing anything?",
  "developing-a-great-app-is-a-continous-job": "Developing a great app is a continous job",
  "we-would-love-to-get-your-ideas": "We would love to get your ideas",
  "write-us-something": "Write us something",
  "missing-an-icon-or-maybe-you-just-want-to-say-hi": "Missing an icon? Or maybe you just want to say hi?",
  "we-appreciate-every-contact-we-get": "We appreciate every contact we get",
  "thank-you": "Thank you",
  "your-message-was-sent": "Your message was sent",
  "we-will-reply-to-your-registered-email-as-soon-as-we-can": "We will reply to your registered email as soon as we can",
  "welcome": "Welcome",
  "norway": "Norway",
  "sweden": "Sweden",
  "norwegian-krone": "Norwegian krone",
  "swedish-krone": "Swedish krone",
  "country": "Country",
  "currency": "Currency",
  "next": "Next",
  "car-expenses": "Car expenses",
  "kindergarden": "Kindergarden",
  "tobacco": "Tobacco",
  "car-loan": "Car loan",
  "rent": "Rent",
  "mortgage": "Mortgage",
  "kids-expenses": "Kids expenses",
  "pets-expenses": "Pets expenses",
  "super-admin": "Super-admin",
  "tools-for-managing-factum": "Tools for managing Factum",
  "feedbacks": "Feedbacks",
  "activated": "Activated",
  "no-users-matching-the-filter": "No users matching the filter",
  "created": "Created",
  "updated": "Updated",
  "family-count": "Family count",
  "last-seen": "Last seen",
  "never": "Never",
  "last-activity": "Last activity",
  "no-families": "No families",
  "budget-accounts": "Budget accounts",
  "this-family-has-not-completed-setup": "This family has not completed setup",
  "no-feedbacks-matching-the-filter": "No feedbacks matching the filter",
  "message": "Message",
  "mark-as-resolved": "Mark as resolved",
  "write-to-us": "Write to us",
  "email-was-sent": "Email was sent",
  "resend-verification-email": "Resend verification email",
  "disable-user": "Disable user",
  "this-user-is-deactivated": "This user is deactivated",
  "disabled-by": "Disabled by",
  "enable": "Enable",
  "the-user-was-disabled": "The user was disabled",
  "the-user-was-enabled": "The user was enabled",
  "your-account-is-disabled": "Your account is disabled",
  "push-notifications": "Push notifications",
  "delete-family": "Delete family",
  "deleting-this-family-can-not-be-undone": "Deleting this family can not be undone",
  "all-data-will-be-permanently-destroyed": "All data will be permanently destroyed",
  "type-the-excact-family-name-to-confirm-that-you-really-want-to-delete-this-family-forever": "Type the excact family name to confirm that you really want to delete this family forever",
  "permanently-delete-family": "Permanently delete family",
  "switched-to": "Switched to",
  "leave-family": "Leave family",
  "do-you-really-want-to-leave-this-family": "Do you really want to leave this family?",
  "leave": "Leave",
  "invalid-first-name": "Invalid firstname",
  "invalid-last-name": "Invalid lastname",
  "invalid-password": "Invalid password",
  "creating-an-account-is-free-and-without-any-strings-attached": "Creating an account is free and without any strings attached.",
  "you-can-delete-the-account-at-any-point": "You can delete the account at any point.",
  "creating-account": "Creating account",
  "password-requirements": "Password requirements",
  "min-8-chars-one-number-and-one-special-char": "Min. 8 characters, including a minimum of 1 number and 1 special char",
  "related": "Related",
  "relations": "Relations",
  "danger-zone": "Danger zone",
  "this-user-has-not-confirmed-its-email-address-and-is-not-yet-activated": "This user has not confirmed its email address and is not yet activated",
  "logging-in": "Logging in...",
  "notes": "Notes",
  "title": "Title",
  "author": "Author",
  "no-notes": "No notes",
  "new-note": "New note",
  "delete-note": "Delete note",
  "do-you-really-want-to-delete-this-note": "Do you really want to delete this note?",
  "os": "OS",
  "agent": "Agent",
  "mobile": "Mobile",
  "device": "Device",
  "login-date": "Login date",
  "no-devices": "No devices",
  "devices": "Devices",
  "hello": "Hello",
  "active-users-right-now": "Active users right now",
  "count": "Count",
  "platforms": "Platforms",
  "new-users": "New users",
  "last-24-hours": "Last 24 hours",
  "active-users": "Active users",
  "right-now": "Right now",
  "users-count": "Users count",
  "total": "Total",
  "result-for": "Result for",
  "shows-how-your-money-balance-evolves": "Shows how your money balance evolves",
  "shows-how-your-expenses-are-distributed": "Shows how your expenses are distributed",
  "showing": "Showing",
  "we-could-not-find-the-page-you-are-looking-for": "We could not find the page you are looking for",
  "go-back": "Go back",
  "shows-a-list-of-your-planned-events-for-the-selected-period": "Shows a list of planned events",
  "no-accounts-in-this-category": "No accounts in this category",
  "categories": "Categories",
  "this-budget-is-empty": "This budget is empty",
  "start-by-adding-some-categories": "Start by adding some categories",
  "this-category-does-not-have-any-accounts": "This category does not have any accounts",
  "add-accounts-to-set-up-a-budget-with-numbers": "Add accounts to set up a budget with numbers",
  "no-data-to-display": "No data to display",
  "setup-a-budget-to-show-this-chart": "Setup a budget to show this chart",
  "other-accounts": "Other acounts",
  "my-accounts": "My accounts",
  "budget-gap": "Budget gap",
  "overconsumption": "Overconsumption",
  "no-overconsumption-this-period": "No overconsumption this period",
  "gap": "Gap",
  "liquidity": "Liquidity",
  "how-long-will-your-money-last": "How long will your money last",
  "liquidity-is-a-measure-of-how-solid-your-economy-is": "Liquidity is a measure of how solid your economy is",
  "the-higher-the-score-the-longer-your-money-will-last": "The higher the score, the longer your money will last",
  "a-score-of-2-is-considered-good-while-a-score-above-1-is-considered-okay": "A score of 2 is considered good, while a score of 1 is considered okay",
  "the-score-is-based-on-fixed-expenses-and-your-total-money-balance": "The score is based on fixed expenses and your total money balance",
  "how-is-the-score-calculated": "How is the score calculated?",
  "the-math-behind-is-easy": "The math behind is easy",
  "your-money-divided-by-your-fixed-expenses": "Your money divided by your fixed expenses",
  "score": "Score",
  "what-can-this-tell-you": "What can this tell you",
  "how-long-your-money-will-last-if-you-lost-all-your-income": "How long your money will last if you lost all your income",
  "how-able-you-are-to-pay-your-expenses": "How able you are to pay your expenses",
  "banks-use-this-score-to-determine-if-they-will-grant-loans": "Banks use this score to determine if they will grant loans",
  "liquidity-is-a-measure-of-how-able-you-are-to-pay-your-expenses": "Liquidity is a measure of how able you are to pay your expenses",
  "overconsumption-is-the-sum-of-accounted-amounts-for-accounts-where-you-have-spent-more-money-than-the-budget-allows": "Overconsumption is the sum of accounted amounts for accounts where you have spent more money than the budget allows",
  "when-your-fiexed-expenses-are-0-the-score-is-set-to-2-as-default": "When your fixed expenses are 0, the score is set to 2 as default",
  "exclude-from-balance": "Exclude from balance",
  "when-an-account-is-excluded-from-balance-its-balance-will-not-be-included-in-the-total-balance-in-charts-or-in-liquidity": "When an account is excluded from balance, the balance will not be included in the total money balance, money charts or liquidity calculations",
  "average": "Average",
  "average-accounted": "Average accounted",
  "value": "Value",
  "months-with-overconsumption": "Months with overconsumption",
  "percent": "percent",
  "average-overconsumption": "Average overconsumption",
  "new-budget-item": "New budget item",
  "loading": "Loading",
  "projects": "Projects",
  "add-project": "Add project",
  "no-projects": "No projects",
  "create-your-first-project": "Create your first project",
  "project": "Project",
  "start-date": "Start date",
  "end-date": "End date",
  "close": "Close",
  "new-project": "New project",
  "active": "Active",
  "finished": "Finished",
  "completed": "Completed",
  "no-vouchers": "No vouchers",
  "add-voucher": "Add voucher",
  "total-income": "Total income",
  "total-expenses": "Total expenses",
  "project-result": "Project result",
  "add-line": "Add line",
  "voucher": "Voucher",
  "creating-an-account-is-100-percent-free": "Creating an account is 100% free",
  "vouchers-are-transactions-in-your-accounting": "Vouchers are transactions in your accounting",
  "a-transaction-moves-money-from-one-account-to-another": "A transaction moves money from one account to another",
  "add-account": "Add account",
  "a-money-account-is-where-you-keep-your-money": "A money account is where you keep your money",
  "my-account": "My account",
  "no-income": "No income",
  "no-expenses": "No expenses",
  "we-have-sent-you-an-email-with-instructions-on-how-to-confirm-your-email-address": "We have sent you an email with instructions on how to confirm your email address",
  "if-you-did-not-receive-it-you-can-resend-it-using-the-button-below": "If you did not receive it, you can resend it using the button below",
  "sent": "Sent",
  "get-started": "Get started",
  "spend-some-minutes-getting-your-new-factum-family-ready": "Spend some minutes getting your new Factum-family ready",
  "general-settings": "General settings",
  "tell-us-where-your-family-is-located-and-which-currency-to-use": "Tell us where your family is located, and which currency to use",
  "family-members": "Family members",
  "to-be-able-to-compare-you-to-other-families-we-need-to-know-a-little-about-who-your-family-is": "To be able to compare you to other families, we need to know a little about who your family is",
  "right-now-your-budget-is-blank": "Right now your budget is blank",
  "do-you-want-us-to-setup-a-default-budget-for-you": "Do you want us to setup a default budget for you",
  "you-can-edit-this-later": "You can edit this later",
  "includes-groceries-but-does-not-split-between-food-and-other-household-articles": "Includes groceries, but does not split between food and other household articles",
  "ordinary-budget": "Ordinary budget",
  "detailed-budget": "Detailed budget",
  "empty": "Empty",
  "start-fresh-and-build-your-own-budget": "Start fresh and build your own budget",
  "other-expenses": "Other expenses",
  "check-the-boxes-corresponding-to-the-expenses-your-family-has": "Check the boxes corresponding to the expenses your family has",
  "splits-between-food-and-cleaning-items": "Splits between food and cleaning items",
  "add-your-money-accounts-with-todays-balance-to-let-us-show-you-how-your-money-develops-with-time": "Add your money accounts with todays balance to let us show you how your money develops with time",
  "complete": "Complete",
  "we-have-all-the-information-we-need": "We have all the information we need",
  "press-complete-to-start-the-installation": "Press complete to start the installation",
  "this-wizard-will-close-automatically-when-the-configuration-is-completed": "This wizard will close automatically when the configuration is completed",
  "this-might-take-a-while": "This might take a while...",
  "thats-it": "Thats it",
  "creating-persons": "Creating persons",
  "creating-money-accounts": "Creating money accounts",
  "creating-budget": "Creating budget",
  "you-are": "You are",
  "euro": "Euro",
  "united-states-dollar": "United States dollar",
  "too-many-attempts-try-again-later": "Too many attempts. Try again later",
  "verify-your-email": "Verify your email",
  "become-a-family-member": "Become a family member",
  "we-would-love-to-hear-from-you": "We would love to hear from you",
  "projects-are-a-great-way-to-get-an-overview-of-income-or-expenses-related-to-an-event-or-a-project": "Projects are a great way to get an overview of income or expenses related to an event or a project",
  "you-can-set-goals-for-how-much-you-want-to-earn-spend-or-save": "You can set goals for how much you want to earn, spend, or save",
  "swedish": "Swedish",
  "ai-interpretation": "AI Interpretation",
  "how-does-an-ai-interpret-your-financial-situation": "How does an AI interpret your financial situation?",
  "activate-ai-now": "Activate AI now",
  "get-more-out-of-factum-with-ai": "Get more out of Factum with AI",
  "ai-can-help-you-understand-more-of-your-economy-and-will-provide-easy-access-to-a-lot-of-features": "AI can help you understand more of your economy, and will provide easy access to a lot of features",
  "turn-off-ai": "Turn off AI",
  "ai-is-activated-for-this-family": "AI is activated for this family",
  "ai-is-not-activated-for-this-family": "AI is not activated for this family",
  "write-a-message": "Write a message",
  "factus-can-answer-economy-related-questions-and-help-you-do-stuff-in-factum": "Factus can answer economy related questions and help you do stuff in Factum",
  "ask-factus-about-something": "Ask Factus about something",
  "you-dont-have-access-to-factus": "You dont have access to Factus",
  "en-error-occured": "An error occured"
}

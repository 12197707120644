<template>
    <v-card
        class="text-center mt-5"
        variant="text"
    >
        <v-icon v-if="props.icon" size="10rem" color="gray">{{ props.icon }}</v-icon>
        <v-card-title>
            {{ props.title }}
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <slot name="content" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        color="primary"
                        variant="outlined"
                        @click.prevent="emits('actionButtonClick')"
                    >
                        {{ props.actionButtonText }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    const props = defineProps({
        title: {
            type: String,
            default: "",
            required: true,
        },
        icon: {
            type: [String, null],
            default: null,
            required: false,
        },
        actionButtonText: {
            type: String,
            default: "",
            required: true,
        }
    })

    const emits = defineEmits([
        'actionButtonClick'
    ])
</script>

<template>
    <v-card color="main" class="h-100" flat rounded="0">
        <div class="text-center pl-2 pr-2 pb-5">
            <v-img src="./images/icon_full.png" class="app-icon"/>
            <div class="text-h4">{{ $t('become-a-family-member') }}</div>
            <div class="subtitle mt-8">
                {{ $t('you-need-to-be-member-of-a-family-in-order-to-use-factum') }}.
                <br />
                {{ $t('you-have-two-options') }}:
            </div>
            <div class="text-left mt-5">
                <v-form v-model="familyFormValid" validate-on="input">
                    <v-card
                        :width="mobile ? '90%' : '70%'"
                        max-width="500px"
                        class="ml-auto mr-auto"
                        :loading="familyStore.isCreatingFamily"
                    >
                        <v-card-title>1. {{ $t('create-a-family') }}</v-card-title>
                        <v-card-subtitle>{{ $t('create-a-new-family-and-start-from-scratch') }}</v-card-subtitle>
                        <v-card-text>
                            <v-text-field
                                :label="$t('family-name')"
                                v-model="newFamily.familyName"
                                hide-details
                                :rules="[Rules.Required]"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                :block="mobile"
                                variant="flat"
                                color="success"
                                :text="$t('create')"
                                @click="create"
                                :disabled="!familyFormValid"
                            />
                        </v-card-actions>
                    </v-card>
                </v-form>
                <v-card
                    :width="mobile ? '90%' : '70%'"
                    class="ml-auto mr-auto mt-10"
                    max-width="500px"
                >
                    <v-card-title>2. {{ $t('wait-for-an-invitation') }}</v-card-title>
                    <v-card-subtitle>{{ $t('have-someone-else-invite-you-to-their-family') }}</v-card-subtitle>
                    <v-card-text class="text-success text-center" v-if="familyStore.invitations.length == 0">
                        <v-icon>mdi-send</v-icon>
                        <br />
                        {{ $t('others-can-invite-you-by-your-email') }}:
                        <b>{{ user?.email }}</b>
                        <br />
                        {{ $t('when-someone-invites you-the-invitation-will-show-up-here') }}
                    </v-card-text>
                    <v-card-text class="text-main text-center" v-if="invitations.length > 0">
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in invitations"
                                :key="item.id + '-' + index"
                            >
                                <template v-slot:subtitle>
                                    <v-list-item-title>
                                        <v-icon class="">mdi-account-multiple-plus</v-icon>
                                        {{ item.family.familyName }}
                                    </v-list-item-title>
                                </template>
                                    <div class="text-body-2 mb-2" style="white-space: break-spaces;">
                                        <span>{{ $t('you-got-invited-by') }} {{ item.createdBy.firstName + ' ' + item.createdBy.lastName }}.</span>
                                        <br />
                                        <span>{{ $t('role') }}: {{ item.role }}</span>
                                    </div>

                                    <v-row class="mb-2">
                                        <v-col>
                                            <v-btn density="compact" variant="outlined" @click.prevent="acceptInvitation(item)">{{ $t('accept') }}</v-btn>
                                        </v-col>
                                        <v-col>
                                            <v-btn density="compact" variant="outlined" color="error" @click.prevent="rejectInvitation(item)">{{ $t('decline') }}</v-btn>
                                        </v-col>
                                    </v-row>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </div>
            <div class="text-center mt-5">
                {{ $t('you-are-logged-in-as') + " " + user?.firstName + " " + user?.lastName}}
                <br />
                {{ "(" + user?.email + ")"}}

            </div>
            <v-btn
                variant="flat"
                :text="$t('logout')"
                class="mr-auto ml-auto mt-5"
                @click="logout"
            />
        </div>
    </v-card>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';
import { useFamilyStore } from '@stores/familyStore';
import { useAuthStore } from '@stores/authStore';
import { Family } from '@models/Family';
import { Ref } from 'vue';
import { ref } from 'vue';
import { User } from '@models/User';
import { computed } from 'vue';
import { FamilyInvitation } from '../../models/FamilyInvitation';
import { useRouter } from 'vue-router';
import { Rules } from '../../types/ValidationRules';

const familyStore = useFamilyStore();
const authStore = useAuthStore();
const router = useRouter();

const newFamily: Ref<Family> = ref(new Family());
const newFamilyErrors: Ref<Object|null> = ref(null);
const user: Ref<User> = computed(() => authStore.user);
const invitations: Ref<Array<FamilyInvitation>> = computed(() => familyStore.invitations);
const familyFormValid = ref(false);

const { mobile } = useDisplay();

function create() {
    newFamily.value.save().then(result => {
        user.value.families.push(result);
        authStore.setCurrentFamily(result);
        familyStore.getFamilies().then(() => {
            router.push({path: '/'});
        })

    }).catch(error => {
        console.error(error);
        newFamilyErrors.value = error.data
    })
}

function acceptInvitation(invitation: FamilyInvitation) {
    invitation.accept().then((result: Family) => {
        familyStore.addFamily(result);
        authStore.setCurrentFamily(result);
        router.push({path: '/'});
    }).catch(error => {
        console.error(error);
    })
}

function rejectInvitation(invitation: FamilyInvitation) {
    invitation.reject().then(() => {
        familyStore.removeInvitation(invitation);
    }).catch(error => {
        console.error(error);
    })
}

function logout(): void
{
    authStore.logout();
}

familyStore.getFamilies();
familyStore.getInvitations();

</script>

<style lang="scss" scoped>
    .app-icon {
        height: 10%;
        width: 10%;
        min-height: 200px;
        min-width: 200px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 40px
    }

  </style>

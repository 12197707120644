import axios from "axios";
import { defineStore } from "pinia";
import { computed, Ref, ref } from 'vue';
import { Project } from "@models/Project";
import NotificationCenter from "notifications/NotificationCenter";
import { ApiEvent } from "notifications/ApiEvent";

export const useProjectStore = defineStore('ProjectStore', () => {
    let projects: Ref<Array<Project>> = ref([]);
    let isLoadingProjects: Ref<boolean> = ref(false);

    const activeProjects = computed(() => {
        return projects.value.filter((project: Project) => project.isCompleted == false);
    })


    //Observing new projects and adding them to the internal array
    NotificationCenter.shared().observe(ApiEvent.ProjectCreated, (data) => {
        let index = projects.value.map(function(x) {return x.id; }).indexOf(data.project.id);

        if (index == -1) {
            let newProject = Project.fromJson(data.project);
            if (newProject) {
                projects.value.push(newProject);
            }
        }
    })

    function getProjects(): Promise<Array<Project>>
    {
        isLoadingProjects.value = true;

        return new Promise<Array<Project>>((resolve, reject) => {
            let url = Project.getRoute();

            axios.get(url).then(result => {
                const fetchedResult = Project.arrayFromJson(result.data);
                projects.value = fetchedResult;
                isLoadingProjects.value = false;
                resolve(projects.value);
            }).catch(error => {
                isLoadingProjects.value = false;
                console.error(error);
                reject(error);
            })

        })
    }

    async function getProjectById(id: Number|String): Promise<Project|null>
    {
        return new Promise<Project|null>((resolve, reject) => {
            let project = projects.value.find((project) => project.id == id);

            if (project) {
                resolve(project);
            } else {
                let p = new Project(false);
                p.id = Number(id);
                let url = Project.getRoute(p);
                axios.get(url).then((result) => {
                    let project = Project.fromJson(result.data);
                    resolve(project);
                }).catch(error => {
                    reject(error);
                })
            }
        });
    }

    return {
        projects,
        activeProjects,
        isLoadingProjects,

        getProjects,
        getProjectById,
    }
})

import { useAuthStore } from '@stores/authStore';
import Pusher from 'pusher-js';
import { ApiNotificationObserver } from 'interfaces/ApiNotificationObserver';
import { ApiEvent } from 'notifications/ApiEvent';
import { ref, Ref } from 'vue';
import { Family } from '@models/Family';

/**
 * An object retrieving notifications from the API through Pusher, forwarding
 * them to subscribers.
 */
export default class NotificationCenter {
    private static default: NotificationCenter;

    private _hasRegisteredWithPusher: boolean = false;

    private pusher: Pusher|null = null;
    private observers = new Array<Ref<ApiNotificationObserver>>();
    private manualObservers = new Array<Ref<{event: ApiEvent, callback: Function}>>();

    /**
     * Returns a new NotificationCenter connected with the API
     */
    private constructor()
    {
    }

    /**
     * The shared notification center is a singleton used for most cases.
     */
    public static shared()
    {
        if (! NotificationCenter.default) {
            NotificationCenter.default = new NotificationCenter();
        }

        return NotificationCenter.default;
    }

    /**
     * Subscribes to events from the API through Pusher, for the family currently selected
     */
    public subscribe()
    {
        if (this._hasRegisteredWithPusher) {
            return
        }

        let authStore = useAuthStore()

        if (this.pusher == null) {
            const pusherInstance = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
                cluster: 'eu',
                authEndpoint: "/broadcasting/auth",
                auth: {
                    headers: {
                        Authorization: "Bearer " + authStore.getBearerToken(),
                    },
                },
            });
            this.pusher = pusherInstance;
        }

        //Register user
        const userId = authStore.user?.id;

        if (userId == null || userId == undefined) {
            return;
        }

        const userChannel = this.pusher.subscribe(`private-User.${userId}.notifications`);

        userChannel.bind_global((eventName: string, data: any) => {
            this.notify(eventName as ApiEvent, data);
        })

        let family: Family|null = authStore.currentFamily;

        if (family == null || family.id == null) {
            return
        }
        const familyId = family.id;

        // Register family-channels
        const accountingChannel = this.pusher.subscribe(`private-Family.${familyId}.accounting`);
        const budgetChannel = this.pusher.subscribe(`private-Family.${familyId}.budget`);
        const projectsChannel = this.pusher.subscribe(`private-Family.${familyId}.projects`);

        accountingChannel.bind_global((eventName: string, data: any) => {
            this.notify(eventName as ApiEvent, data);
        })

        budgetChannel.bind_global((eventName: string, data: any) => {
            this.notify(eventName as ApiEvent, data);
        })

        projectsChannel.bind_global((eventName: string, data: any) => {
            this.notify(eventName as ApiEvent, data);
        })

        this._hasRegisteredWithPusher = true;
    }

    /**
     * Unsubscribes from API Events through Pusher
     */
    public unsubscribe() {
        if (this.pusher == null) {
            return;
        }

        let authStore = useAuthStore();

        //Unsubscribing user
        const userId = authStore.user?.id;

        if (userId == null || userId == undefined) {
            return;
        }
        this.pusher.unsubscribe(`private-User.${userId}.notifications`);

        //Unsubscribing family
        let family: Family|null = authStore.currentFamily;

        if (family == null || family.id == null) {
            return
        }
        const familyId = family.id;

        this.pusher.unsubscribe(`private-Family.${familyId}.accounting`);
        this.pusher.unsubscribe(`private-Family.${familyId}.budget`);
        this.pusher.unsubscribe(`private-Family.${familyId}.projects`);

        this._hasRegisteredWithPusher = false;
    }

    /**
     * Disconnects existing connections and re-connects
     */
    public reconnect() {
        this.unsubscribe();
        this.subscribe();
    }

    /**
     * Adds a new ApiNotificationObserver to the internal list of observers.
     * Registered observers will receive notifications for the events listed in the 'public observeEvents: ApiEvent[]' property
     * @param observer ApiNotificationObserver The object to be added as observer
     */
    public registerObserver(observer: ApiNotificationObserver) {
        let existing: Ref<ApiNotificationObserver> = this.observers.find((existingObserver) => existingObserver.value == observer);

        if (!existing) {
            this.observers.push(ref(observer));
        }
    }

    /**
     * Manually observe an ApiEvent and handle the event with a callback function
     */
    public observe(event: ApiEvent, callback: Function) {
        this.manualObservers.push(ref({event: event, callback: callback}));
    }

    /**
     * Notifies the observers about an event
     * @param event ApiEvent the event to notify about
     * @param data any The data to include with the event
     */
    private notify(event: ApiEvent, data: any) {
        let receivers: Array<Ref<ApiNotificationObserver>> = this.observers.filter((obj: Ref<ApiNotificationObserver>) => {
            return obj.value.observeEvents.includes(event);
        });

        receivers.forEach(observer => {
            observer.value.didReceiveNotification(event, data);
        });

        let manualObservers = this.manualObservers.filter((obj) => obj.value.event == event);

        manualObservers.forEach(observer => {
            observer.value.callback(data);
        })
    }

    /**
     * Fires a manual event in the local application
     * @param event
     * @param data
     */
    public notifyManually(event: ApiEvent, data: any)
    {
        this.notify(event, data);
    }

}

import { useAuthStore } from "@stores/authStore";
import axios from "axios";

export class Factus {

    static getRoute(): string
    {
        const familyId = useAuthStore().currentFamily.id;

        return `/api/families/${familyId}/factus`;
    }

    static ask(message: string): Promise<string>
    {
        return new Promise((resolve, reject) => {
            axios.post(this.getRoute() + "/ask", {message}).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            })
        })
    }
}


<template>
    <v-container fluid class="bg-main">
        <v-row>
            <v-col>
                <span class="text-h5 text-primary">{{ $t('settings') }}</span>
            </v-col>
        </v-row>
    </v-container>
    <v-container fluid :class="mobile ? 'pa-0' : ''">
        <v-card :loading="isLoading" class="fill-height">
            <v-card-actions>
                <v-col
                    cols="12"
                    align="center"
                >
                    <v-btn-toggle
                        v-model="currentView"
                        rounded="0"
                        color="primary"
                        group
                        mandatory
                    >
                        <v-btn value="user-settings">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ $t('user') }}</span>
                        </v-btn>
                        <v-btn value="notification-settings">
                            <v-icon>mdi-bell</v-icon>
                            <span>{{ $t('notifications') }}</span>
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-card-actions>
            <v-card-text v-if="currentView == 'user-settings'">
                <v-row>
                    <v-col>
                        <v-switch
                            :label="$t('use-simple-language')"
                            v-model="userSettings.useSimpleLanguage"
                            inset
                            color="success"
                            hide-details
                            @update:model-value="userSettings.save()"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="currentView == 'notification-settings'">
                <!--HEADER-->
                <v-row class="mb-2">
                    <v-col cols="6" md="4">
                    </v-col>
                    <v-col cols="3" md="1">
                        {{ $t('email') }}
                    </v-col>
                    <v-col cols="3" md="1">
                        {{ $t('push') }}
                    </v-col>
                </v-row>
                <v-divider />
                <!--Daily accounting reminder-->
                <v-row align="center" no-gutters>
                    <v-col cols="6" md="4">
                        {{ $t('daily-accounting-reminder') }}
                    </v-col>
                    <v-col cols="3" md="1"></v-col>
                    <v-col cols="3" md="1">
                        <v-switch
                            v-model="notificationSettings.dailyAccountingReminderPush"
                            inset
                            color="success"
                            hide-details
                            @update:model-value="notificationSettings.save()"
                        />
                    </v-col>
                </v-row>
                <v-divider />
                <!--Budget exceeded-->
                <v-row align="center" no-gutters>
                    <v-col cols="6" md="4">
                        {{ $t('budget-exceeded') }}
                    </v-col>
                    <v-col cols="3" md="1">
                        <v-switch
                            v-model="notificationSettings.budgetExceededEmail"
                            inset
                            color="success"
                            hide-details
                            @update:model-value="notificationSettings.save()"
                        />
                    </v-col>
                    <v-col cols="3" md="1">
                        <v-switch
                            v-model="notificationSettings.budgetExceededPush"
                            inset
                            color="success"
                            hide-details
                            @update:model-value="notificationSettings.save()"
                        />
                    </v-col>
                </v-row>
                <v-divider />
                <!--Voucher created-->
                <v-row align="center" no-gutters>
                    <v-col cols="6" md="4">
                        {{ $t('voucher-created') }}
                    </v-col>
                    <v-col cols="3" md="1">
                    </v-col>
                    <v-col cols="3" md="1">
                        <v-switch
                            v-model="notificationSettings.voucherCreatedPush"
                            inset
                            color="success"
                            hide-details
                            @update:model-value="notificationSettings.save()"
                        />
                    </v-col>
                </v-row>
                <v-divider />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useAuthStore } from '../../stores/authStore';
import { Ref } from 'vue';
import { ref } from 'vue';
import { useDisplay } from 'vuetify/lib/framework.mjs';


const authStore = useAuthStore();
const currentView: Ref<string> = ref('user-settings');

const userSettings = computed(() => authStore.user.userSettings);
const notificationSettings = computed(() => authStore.user.notificationSettings);

const { mobile } = useDisplay();

const isLoading = computed(() => {
    return userSettings.value.isSaving;
})

authStore.user.getNotificationSettings();

</script>


<template>
    <v-card flat>
        <v-card-title class="d-flex align-center pe-2 pt-5" fixed>
            <v-icon icon="mdi-account-multiple"></v-icon> &nbsp;
            {{ $t('feedbacks') }}
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                density="compact"
                :label="$t('search')"
                single-line
                flat
                hide-details
                variant="solo-filled"
            ></v-text-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
            v-model="selected"
            density="comfortable"
            v-model:items-per-page="itemsPerPage"
            :loading="loading"
            :headers="headers"
            :items="feedbacks"
            @click:row="select"
            hover
            :search="search"
            v-model:sort-by="sortBy"
            :no-data-text="$t('no-feedbacks-matching-the-filter')"
            show-expand
        >
            <template v-slot:item.createdAt="{ item }">
                <p>{{ item.createdAt.toRelative() }}</p>
            </template>

            <template v-if="mobile" v-slot:item.createdAt="{ item }">
                <tr>
                    <td colspan="4">
                        <tr>
                            <td class="text-subtitle-2">
                                {{ $t('sendt') }} {{ item.createdAt.toRelative() }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">
                                {{ item.createdBy.fullName }}
                            </td>
                        </tr>
                    </td>
                </tr>
            </template>
            <template v-slot:expanded-row="{ item }">
                <tr>
                    <td class="font-italic pa-0 ma-0" colspan="4">
                        <v-card class="" color="main" flat>
                            <v-card-text>
                                <p class="font-weight-bold text-subtitle-2">{{ $d(item.createdAt.toJSDate(), 'long') }}:</p>
                                <pre>{{ item.message }}</pre>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn variant="flat" color="primary">{{ $t('mark-as-resolved') }}</v-btn>
                            </v-card-actions>

                        </v-card>
                    </td>
                </tr>
            </template>

        </v-data-table>
    </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { onUnmounted } from 'vue';
import { useFeedbackStore } from '../../stores/feedbackStore';

const { mobile } = useDisplay();

const { t } = useI18n();

const feedbackStore = useFeedbackStore();

const selected = ref([]);
const itemsPerPage = ref(20);
const search = ref("");
const sortBy = ref([{key: 'createdAt', order: 'desc'}]);
const loading = ref(false);
const feedbacks = computed(() => feedbackStore.feedbacks);

const headers = computed(() => {
        if (mobile.value) {
            return [
                {title: t('date'), align: 'start', sortable: true, key: 'createdAt'},
            ]
        }
        return [
            {title: t('date'), align: 'start', sortable: true, key: 'createdAt'},
            {title: t('user'), align: 'start', sortable: true, key: 'createdBy.fullName'},
        ];

});


function select(event, {item})
{
    //userDialog.value.openUser(item);
    //this.$refs.voucherDialog.openVoucher(item);
}


feedbackStore.getFeedbacks();

const timer = setInterval(() => feedbackStore.getFeedbacks(), 10000);

onUnmounted(() => {
    clearInterval(timer);
})


</script>

import axios from "axios";
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';
import { useAuthStore } from '../stores/authStore';
import { User } from "./User";
import { Family } from "./Family";
import { FamilyRole } from "../types/FamilyRole";

/**
 * A model representing the data for FamilyMembership.
 */
export class FamilyMembership implements ApiNotificationObserver
{
    public id: number|null = null;
    public user: User|null = null;
    public family: Family|null = null;
    public role: FamilyRole|null = null;
    public deletedAt: DateTime|null = null;

    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;

    public observeEvents: ApiEvent[] = [
    ];

    /**
     * Creates a new empty FamilyMembership object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new FamilyMembership model from json data
     * @param json An object holding the data to parse
     * @returns FamilyMembership
     */
    static fromJson(json: any, observe: boolean = false): FamilyMembership
    {
        const obj = new FamilyMembership(observe);
        obj.id = json.id;

        if (json.user) {
            obj.user = User.fromJson(json.user);
        }

        if (json.family) {
            obj.family = Family.fromJson(json.family);
        }

        obj.role = json.role as FamilyRole;

        return obj;
    }

    /**
     * Returns an array of FamilyMemberships from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<FamilyMembership>
    {
        const array = new Array<FamilyMembership>();

        json.forEach((item: any) => {
            const obj = FamilyMembership.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(membership: FamilyMembership): string
    {
        let authStore = useAuthStore();
        let userId = authStore.user.id;

        if (membership.id == null) {
            return `/api/users/${userId}/families/${membership.family.id}/memberships`;
        }

        return `/api/users/${userId}/families/${membership.family.id}/memberships/${membership.id}`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<FamilyMembership>
     */
    public save(): Promise<FamilyMembership>
    {
        this._isSaving = true;

        return new Promise<FamilyMembership>((resolve, reject) => {
            if (this.id) {
                axios.put(FamilyMembership.getRoute(this), this.toJson()).then(response => {
                    const obj = FamilyMembership.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(FamilyMembership.getRoute(this), this.toJson()).then(response => {
                    const obj = FamilyMembership.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<FamilyMembership>
     */
    public delete(): Promise<FamilyMembership>
    {
        this._isDeleting = true;
        return new Promise<FamilyMembership>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(FamilyMembership.getRoute(this)).then(response => {
                    const obj = FamilyMembership.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isDeleting = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    /**
     * Copies the data from a FamilyMembership into this instance
     * @param obj Account
     */
    public copyFrom(obj: FamilyMembership)
    {
        this.id = obj.id;
        this.user = obj.user;
        this.family = obj.family;
        this.role = obj.role;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    public toJson() : object
    {
        return {
            'id': this.id,
            'user': this.user.toJson(),
            'family': this.family.toJson(),
            'role': this.role,
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {

    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }
}

import axios from "axios";
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';
import { useAuthStore } from '../stores/authStore';
import { User } from "./User";
import { FamilyMembership } from './FamilyMembership';
import { FamilyPerson } from "./FamilyPerson";
import { FamilyInvitation } from "./FamilyInvitation";
import { FamilyRole } from '../types/FamilyRole';

/**
 * A model representing the data for Family.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accounts/{account}/budgets
 */
export class Family implements ApiNotificationObserver
{
    public id: number|null = null;
    public familyName: string|null = null;
    public country: string = 'NO';
    public currency: string = 'NOK';
    public memberships: Array<FamilyMembership> = new Array<FamilyMembership>;
    public persons: Array<FamilyPerson> = new Array<FamilyPerson>;
    public invitations: Array<FamilyInvitation> = new Array<FamilyInvitation>;
    public createdBy: User|null = null;
    public setupCompletedAt: DateTime|null = null;
    public isAiActivated: boolean|null = null;
    public lastActivityAt: DateTime|null = null;
    public statistics: Array<{}>|null = null;
    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;
    private _isLeaving: boolean = false;
    private _isGettingStatistics: boolean = false;
    private _isChangingAiState: boolean = false;

    public observeEvents: ApiEvent[] = [
        ApiEvent.FamilyUpdated,
    ];

    /**
     * Creates a new empty Family object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new Family model from json data
     * @param json An object holding the data to parse
     * @returns Family
     */
    static fromJson(json: any, observe: boolean = false): Family
    {
        const obj = new Family(observe);
        obj.id = json.id;
        obj.familyName = json.family_name;
        obj.country = json.country;
        obj.currency = json.currency;
        obj.memberships = FamilyMembership.arrayFromJson(json.memberships ?? []);

        if (json.created_by) {
            obj.createdBy = User.fromJson(json.created_by ?? []);
        }
        if (json.last_activity_at) {
            obj.lastActivityAt = DateTime.fromISO(json.last_activity_at);
        }

        if (json.setup_completed_at) {
            obj.setupCompletedAt = DateTime.fromISO(json.setup_completed_at);
        }

        if (json.ai_activated_at && json.ai_activated_at !== null) {
            obj.isAiActivated = true;
        } else {
            obj.isAiActivated = false;
        }

        return obj;
    }

    /**
     * Returns an array of Familys from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<Family>
    {
        const array = new Array<Family>();

        json.forEach((item: any) => {
            const obj = Family.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(family: Family): string
    {
        let authStore = useAuthStore();
        let userId = authStore.user.id;

        if (family.id == null) {
            return `/api/users/${userId}/families?include=memberships.user`;
        }

        return `/api/users/${userId}/families/${family.id}?include=memberships.user`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<Family>
     */
    public save(): Promise<Family>
    {
        this._isSaving = true;

        return new Promise<Family>((resolve, reject) => {
            if (this.id) {
                axios.put(Family.getRoute(this), this.toJson()).then(response => {
                    const obj = Family.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(Family.getRoute(this), this.toJson()).then(response => {
                    const obj = Family.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<Family>
     */
    public delete(): Promise<Family>
    {
        this._isDeleting = true;
        return new Promise<Family>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(Family.getRoute(this)).then(response => {
                    const obj = Family.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isDeleting = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    public leaveFamily(): Promise<boolean>
    {
        this._isLeaving = true;
        return new Promise<boolean>((resolve, reject) => {
            if (this.currentUsersRole == FamilyRole.Owner) {
                this._isLeaving = false;
                resolve(false);
            }

            let authStore = useAuthStore();
                let userId = authStore.user.id;
                axios.put(`/api/users/${userId}/families/${this.id}/leave`).then(response => {
                    this._isLeaving = false;
                    resolve(true);
                }).catch(error => {
                    console.error(error);
                    this._isLeaving = false;
                    reject(error);
                })
        })
    }

    /**
     *
     */
    public getMemberships(): Promise<Array<FamilyMembership>>
    {
        return new Promise<Array<FamilyMembership>>((resolve, reject) => {
            if (this.id == null) {
                throw Error("Can not get memberships for a family that has not been saved yet");
            } else {
                let authStore = useAuthStore();
                let userId = authStore.user.id;
                axios.get(`/api/users/${userId}/families/${this.id}/memberships`).then(response => {
                    let memberships = FamilyMembership.arrayFromJson(response.data);
                    this.memberships = memberships;
                    resolve(memberships);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        })
    }

    public getPersons(): Promise<Array<FamilyPerson>>
    {
        return new Promise<Array<FamilyPerson>>((resolve, reject) => {
            if (this.id == null) {
                throw Error("Can not get persons for a family that has not been saved yet");
            } else {
                axios.get(FamilyPerson.getRoute(this)).then(response => {
                    let persons = FamilyPerson.arrayFromJson(response.data);
                    this.persons = persons;
                    resolve(this.persons);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        })
    }

    public getInvitations(): Promise<Array<FamilyInvitation>>
    {
        return new Promise<Array<FamilyInvitation>>((resolve, reject) => {
            if (this.id == null) {
                throw Error("Can not get invitations for a family that has not been saved yet");
            } else {
                axios.get(FamilyInvitation.getRoute(this)).then(response => {
                    let invitations = FamilyInvitation.arrayFromJson(response.data);
                    this.invitations = invitations;
                    resolve(this.invitations);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        })
    }

    public getStatistics(): Promise<{}>
    {
        this._isGettingStatistics = true;
        return new Promise<{}>((resolve, reject) => {
            let url = `/api/families/${this.id}/statistics`;
            axios.get(url).then(result => {
                this.statistics = result.data;
                this._isGettingStatistics = false;
                resolve(result.data);
            }).catch(error => {
                console.error(error);
                this._isGettingStatistics = false;
                reject(error);
            })
        })
    }

    public activateAi(): Promise<null>
    {
        this._isChangingAiState = true;
        return new Promise<null>((resolve, reject) => {
            let url = `/api/families/${this.id}/settings/ai/activate`;
            axios.patch(url).then((response) => {
                this.isAiActivated = true;
                this._isChangingAiState = false;
                resolve(null);
            }).catch(error => {
                this._isChangingAiState = false;
                reject(error);
            })
        })
    }

    public deactivateAi(): Promise<null>
    {
        this._isChangingAiState = true;
        return new Promise<null>((resolve, reject) => {
            let url = `/api/families/${this.id}/settings/ai/deactivate`;
            axios.patch(url).then((response) => {
                this.isAiActivated = false;
                this._isChangingAiState = false;
                resolve(null);
            }).catch(error => {
                this._isChangingAiState = false;
                reject(error);
            })
        })
    }

    /**
     * Copies the data from a Family into this instance
     * @param obj Account
     */
    public copyFrom(obj: Family)
    {
        this.id = obj.id;
        this.familyName = obj.familyName;
        this.country = obj.country;
        this.currency = obj.currency;
        this.memberships = obj.memberships;
        this.createdBy = obj.createdBy;
        this.setupCompletedAt = obj.setupCompletedAt;
        this.isAiActivated = obj.isAiActivated;
        this.lastActivityAt = obj.lastActivityAt;
    }

        /**
     * Returns a copy of the BudgetAccountCategory
     */
        public copy(observe: boolean = false): Family
        {
            let copy = new Family(observe);
            copy.copyFrom(this);

            return copy;
        }

    /**
     * Returns a json object of the model
     * @returns object
     */
    public toJson() : object
    {
        return {
            'id': this.id,
            'family_name': this.familyName,
            'country': this.country,
            'currency': this.currency,
            'setup_completed_at': this.setupCompletedAt,
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    get isLeaving(): boolean
    {
        return this._isLeaving;
    }

    get isGettingStatistics(): boolean
    {
        return this._isGettingStatistics;
    }

    get isChangingAiState(): boolean
    {
        return this._isChangingAiState;
    }

    get currentUsersRole(): FamilyRole
    {
        if (this.id == null) {
            return FamilyRole.Owner;
        }

        let memberships = this.memberships;
        let authStore = useAuthStore();

        let membership = memberships.find(membership => {
            return membership.user?.id == authStore.user.id;
        });

        return membership?.role;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {

    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }
}

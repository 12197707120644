<template>
    <v-autocomplete
        v-model:model-value="value"
        :items="persons"
        variant="outlined"
        density="compact"
        item-title="fullName"
        auto-select-first
        item-props
        open-on-clear
        :label="label ?? ''"
        clearable
        :rules="rules"
        :no-data-text="$t('no-persons')"
        return-object
    >
        <template v-slot:selection="{ item }">
            {{  item.raw.fullName }}
        </template>
        <template v-slot:item="{ item, props: {onClick} }">
            <v-list-item
                :title="item.raw.fullName"
                @click="onClick"
            >
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script setup lang="ts">
import { Ref } from 'vue';
import { computed } from 'vue';
import { User } from '@models/User';
import { useAuthStore } from '@stores/authStore';
import { FamilyMembership } from '@models/FamilyMembership';

    const props = defineProps([
        'modelValue',
        'label',
        'rules',
    ])
    const authStore = useAuthStore();

    const persons: Ref<Array<User>> = computed(() => {
        let memberships = authStore.currentFamily.memberships;
        let users: Array<User> = [];
        memberships.forEach((membership: FamilyMembership) => {
            users.push(membership.user);
        })

        return users;
    });

    const emits = defineEmits(['update:modelValue'])

    const value = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            emits('update:modelValue', value);
        }
    })

</script>

import axios from "axios";
import { defineStore } from "pinia";
import { Ref, ref } from 'vue';

export const useSuperadminStore = defineStore('SuperadminStore', () => {
    let statistics: Ref<SuperadminStatistics> = ref(new SuperadminStatistics());

    function getStatistics(): Promise<SuperadminStatistics>
    {
        return new Promise<SuperadminStatistics>((resolve, reject) => {
            let url = '/api/superadmin/statistics';

            axios.get(url).then(result => {
                const fetchedStatistics = SuperadminStatistics.fromJson(result.data);
                statistics.value = fetchedStatistics;
                resolve(statistics.value);
            }).catch(error => {
                console.error(error);
                reject(error);
            })

        })
    }
    return {
        statistics,
        getStatistics,
    }
})

export class SuperadminStatistics
{
    public currentActiveUsers: number = 0;
    public userAgentPieChartData: [] = [];
    public newUsersToday: number = 0;
    public activeUsersToday: number = 0;
    public userCount: number = 0;

    static fromJson(json: any): SuperadminStatistics
    {
        let obj = new SuperadminStatistics();
        obj.currentActiveUsers = json.current_active_users;
        obj.userAgentPieChartData = json.user_agent_pie_chart_data;
        obj.newUsersToday = json.new_users_today;
        obj.activeUsersToday = json.active_users_today;
        obj.userCount = json.user_count;

        return obj;
    }
}

import axios from "axios";
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { useAuthStore } from "@stores/authStore";
import NotificationCenter from "notifications/NotificationCenter";
import { ApiEvent } from 'notifications/ApiEvent';
import { Transaction } from "../types/Transaction";
import { AccountBudget } from './AccountBudget';
import { AccountBudgetEvent } from './AccountBudgetEvent';
import { BudgetAccountCategory } from './BudgetAccountCategory';
import { Voucher } from './Voucher';
import { VoucherLine } from './VoucherLine';

/**
 * A model representing the data for BudgetAccount.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accouts
 */
export class BudgetAccount implements ApiNotificationObserver
{

    public id: number;
    public category: BudgetAccountCategory = new BudgetAccountCategory();
    public accountType: BudgetAccountType;
    public accountName: string;
    public description: string;
    public iconName: string;
    public currentBudgetAmount: number;
    public currentAccountedAmount: number;

    public budgets: Array<AccountBudget> = new Array<AccountBudget>();
    public budgetEvents: Array<AccountBudgetEvent> = new Array<AccountBudgetEvent>();
    public transactions: Array<Transaction> = new Array<Transaction>();

    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;
    private _isLoadingTransactions: boolean = false;
    private _isRefreshingNumbers: boolean = false;

    public observeEvents: ApiEvent[] = [
        ApiEvent.BudgetChanged,
        ApiEvent.AccountUpdated,
        ApiEvent.VoucherCreated,
        ApiEvent.VoucherUpdated,
        ApiEvent.VoucherDeleted,
    ];

    /**
     * Creates a new empty BudgetAccount object
     * @param observe boolean Wheter to add observers to the model or not
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new BudgetAccount model from json data
     * @param json An object holding the data to parse
     * @returns BudgetAccount
     */
    static fromJson(json: any, observe: boolean = false): BudgetAccount
    {
        const obj = new BudgetAccount(observe);
        obj.id = json.id;
        obj.accountType = json.account_type;
        obj.accountName = json.account_name;
        obj.description = json.description;
        obj.iconName = json.icon_name;
        obj.currentAccountedAmount = json.current_accounted_amount;
        obj.currentBudgetAmount = json.current_budget_amount;

        if (json.category) {
            let category = BudgetAccountCategory.fromJson(json.category);
            obj.category = category;
        }

        obj.budgets = new Array<AccountBudget>()
        obj.budgetEvents = new Array<AccountBudgetEvent>()

        return obj;
    }

    /**
     * Returns an array of BudgetAccounts from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<BudgetAccount>
    {
        const array = new Array<BudgetAccount>();

        json.forEach((item: any) => {
            const obj = BudgetAccount.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param account BudgetAccount the account to get url from
     * @returns string
     */
    static getRoute(account: BudgetAccount|null): string|null
    {
        const familyId = useAuthStore().currentFamily.id;
        const accountId = account.id;
        const categoryId = account.category.id;

        if (accountId) {
            return `/api/families/${familyId}/account_categories/${categoryId}/accounts/${accountId}`;
        }

        return `/api/families/${familyId}/account_categories/${categoryId}/accounts`;
    }

        /**
     * Returns the relative route to the index route of accounts within a category
     * @param account BudgetAccount the account to get url from
     * @returns string
     */
        static getRouteForCategory(category: BudgetAccountCategory): string
        {
            const familyId = useAuthStore().currentFamily.id;
            const categoryId = category.id;

            return `/api/families/${familyId}/account_categories/${categoryId}/accounts`;
        }

    /**
     * Returns the route for fetching transactions, or null if the account is not yet saved
     * @param accoun
     * @returns string|null
     */
    public getTransactionsRoute(): string|null
    {
        const familyId = useAuthStore().currentFamily.id;

        if (this.id) {
            return `/api/families/${familyId}/money_accounts/${this.id}/transactions`;
        }

        return null;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<BudgetAccount>
     */
    public save(): Promise<BudgetAccount>
    {
        this._isSaving = true;
        return new Promise<BudgetAccount>((resolve, reject) => {
            if (this.id) {
                axios.put(BudgetAccount.getRoute(this), this.toJson()).then(response => {
                    const obj = BudgetAccount.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(BudgetAccount.getRoute(this), this.toJson()).then(response => {
                    const obj = BudgetAccount.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<BudgetAccount>
     */
    public delete(): Promise<BudgetAccount>
    {
        this._isDeleting = true;
        return new Promise<BudgetAccount>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(BudgetAccount.getRoute(this)).then(response => {
                    const obj = BudgetAccount.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isDeleting = false;
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    private toJson() : object
    {
        return {
            'id': this.id,
            'category': {
                'id': this.category.id
            },
            'account_type': this.accountType,
            'account_name': this.accountName,
            'description': this.description,
            'icon_name': this.iconName,
        };
    }

    /**
     * Fetches budgets from the API, returned in a promise.
     * The BudgetAccount object is also holding the values in the budgets-prop.
     * @returns Promise<Array<AccountBudget>>
     */
    public getBudgets(): Promise<Array<AccountBudget>>
    {
        return new Promise((resolve, reject) => {
            let url = AccountBudget.getRoute(this);
            axios.get(url).then(response => {
                let budgets = AccountBudget.arrayFromJson(response.data, true);
                budgets.forEach(budget => {
                    budget.account = this;
                });
                this.budgets = budgets;
                resolve(budgets);
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }

    /**
     * Fetches budget-events from the API, returned in a promise.
     * The BudgetAccount object is also holding the values in the budgetEvents-prop.
     * @returns Promise<Array<AccountBudget>>
     */
    public getBudgetEvents(): Promise<Array<AccountBudgetEvent>>
    {
        return new Promise((resolve, reject) => {
            let url = AccountBudgetEvent.getRoute(this);
            axios.get(url).then(response => {
                let events = AccountBudgetEvent.arrayFromJson(response.data, true);
                events.forEach(event => {
                    event.account = this;
                });
                this.budgetEvents = events;
                resolve(events);
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }

    /**
     * Copies the data from a BudgetAccount into this instance
     * @param obj BudgetAccount
     */
    public copyFrom(obj: BudgetAccount, deep: boolean = true)
    {
        this.id = obj.id;
        this.accountName = obj.accountName;
        this.accountType = obj.accountType;
        this.description = obj.description;
        this.iconName = obj.iconName;
        this.currentBudgetAmount = obj.currentBudgetAmount;
        this.currentAccountedAmount = obj.currentAccountedAmount;

        // Deep (relations, etc)
        if (deep) {
            this.category = obj.category;
        }
    }

    /**
     * Returns a copy of the BudgetAccountCategory
     */
    public copy(observe: boolean = false): BudgetAccount
    {
        let copy = new BudgetAccount(observe);
        copy.copyFrom(this);

        return copy;
    }

    public getTransactions(fromDate: string, toDate: string): Promise<Array<Transaction>>
    {
        this._isLoadingTransactions = true;
        return new Promise<Array<Transaction>>((resolve, reject) => {
            axios.get(this.getTransactionsRoute() + `?from=${fromDate}&to=${toDate}`)
            .then((result: any) => {
                let arr = Transaction.arrayFromJson(result.data);
                this.transactions = arr;
                this._isLoadingTransactions = false;
                resolve(arr);
            })
            .catch(error => {
                console.error(error);
                this._isLoadingTransactions = false;
                reject(error);
            })
        });
    }

    public refreshNumbers(): Promise<any>
    {
        this._isRefreshingNumbers = true;

        return new Promise<number>((resolve, reject) => {
            if (!this.id || !this.category?.id) {
                this._isRefreshingNumbers = false;
                reject();
            }
            let url = BudgetAccount.getRoute(this);

            axios.get(url).then(result => {
                this.currentBudgetAmount = result.data.current_budget_amount;
                this.currentAccountedAmount = result.data.current_accounted_amount;
                this._isRefreshingNumbers = false;
                resolve(result.data.balance);
            }).catch(error => {
                console.error(error);
                this._isRefreshingNumbers = false;
                reject(error);
            })
        })
    }

    handleVoucherEvent(voucher: Voucher)
    {
        let changedSomething: boolean = false;

        let existingLines = this.transactions.filter((obj: Transaction) => obj.voucherId == voucher.id);

        if (existingLines.length > 0) {
            changedSomething = true;
            existingLines.forEach((line: Transaction) => {
                this.transactions.splice(this.transactions.indexOf(line), 1);
            })
        }

        voucher.voucherLines.forEach((line: VoucherLine) => {
            if (line.accountId == this.id) {
                let newTransaction = Transaction.fromVoucher(voucher, line);
                this.transactions.push(newTransaction);
                changedSomething = true;
            }
        })

        if (changedSomething && this.id) {
            this.refreshNumbers();
        }
    }

    /**
     * Returns true if a save-process is running
     */
    get isSaving(): boolean
    {
        return this._isSaving;
    }

    /**
     * Returns true if a delete-process is running
     */
    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    get isLoadingTransactions(): boolean
    {
        return this._isLoadingTransactions;
    }

    get isRefreshingNumbers(): boolean
    {
        return this._isRefreshingNumbers;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        if (notification == ApiEvent.BudgetChanged && data?.account?.id == this.id) {
            let newAccount = BudgetAccount.fromJson(data.account);
            this.copyFrom(newAccount);
        }

        if (notification == ApiEvent.AccountUpdated && data?.account.id == this.id) {
            let newAccount = BudgetAccount.fromJson(data.account);
            this.copyFrom(newAccount);
        }

        if (notification == ApiEvent.VoucherCreated) {
            this.handleVoucherEvent(Voucher.fromJson(data.voucher));
        }

        if (notification == ApiEvent.VoucherUpdated) {
            this.handleVoucherEvent(Voucher.fromJson(data.voucher));
        }

        if (notification == ApiEvent.VoucherDeleted) {
            this.handleVoucherEvent(Voucher.fromJson(data.voucher));
        }
    }

    public registerObservers() {
        NotificationCenter.shared().registerObserver(this);
    }
}

export interface BudgetAccountValidationErrorInterface {
    account_name: string[];
    account_type: string[];
    category_id?: string[];
    category?: string[];
    icon_name: string[];
    description: string[];
}

export enum BudgetAccountType {
    Income = 'INCOME',
    FixedExpence = 'FIXED_EXPENSE',
    VariableExpence = 'VARIABLE_EXPENSE',
}

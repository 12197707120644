import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import nb from './locales/nb.json'
import se from './locales/se.json'

const numberFormats = {
    'en': {
      currency: {
        style: 'currency', currency: 'USD', notation: 'standard'
      },
      currencyNoDecimals: {
        style: 'currency', currency: 'USD', useGrouping: true, notation: 'standard', maximumFractionDigits: 0
      },
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    },

    'nb': {
      currency: {
        style: 'currency', currency: 'NOK', useGrouping: true, currencyDisplay: 'symbol'
      },
      currencyNoDecimals: {
        style: 'currency', currency: 'NOK', useGrouping: true, currencyDisplay: 'symbol', maximumFractionDigits: 0
      },
      decimal: {
        style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    },

    'NOK': {
        currency: {
          style: 'currency', currency: 'NOK', useGrouping: true, currencyDisplay: 'symbol'
        },
        currencyNoDecimals: {
            style: 'currency', currency: 'NOK', useGrouping: true, currencyDisplay: 'symbol', maximumFractionDigits: 0
          },
      },
    'EUR': {
        currency: {
          style: 'currency', currency: 'EUR', useGrouping: true, currencyDisplay: 'symbol'
        },
        currencyNoDecimals: {
            style: 'currency', currency: 'EUR', useGrouping: true, currencyDisplay: 'symbol', maximumFractionDigits: 0
          },
      },
      'USD': {
        currency: {
          style: 'currency', currency: 'USD', useGrouping: true, currencyDisplay: 'narrowSymbol'
        },
        currencyNoDecimals: {
            style: 'currency', currency: 'USD', useGrouping: true, currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0
          },
      },
  }

const datetimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        },
        month: {
            month: 'long'
        },
        monthAndDay: {
            month: 'long', day:'2-digit'
        },
        monthAndYear: {
            month: 'long', year:'numeric'
        },
        shortWithWeekday: {
            year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'long'
        }
    },
    'nb': {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit',
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
        },
        month: {
            month: 'long'
        },
        monthAndDay: {
            month: 'long', day:'2-digit'
        },
        monthAndYear: {
            month: 'long', year:'numeric'
        },
        shortWithWeekday: {
            year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'long'
        }
    }
}

export const i18n = createI18n({
    locale: 'nb',
    fallbackLocale: 'nb',
    globalInjection: true,
    legacy: false,
    numberFormats,
    datetimeFormats,
    messages: {en, nb, se}
});



<template>
    <page-header
        :title="project.projectName == null || project.projectName == '' ? t('new-project') : project.projectName"
        icon="mdi-file-document"
        :routeBack="{name: 'Projects'}"
    />
    <v-tabs
        v-model="activeTab"
        :align-tabs="mobile? 'center' : 'start'"
        :mobile="mobile"
        :fixed-tabs="mobile"
    >
        <v-tab :value="'settings'"><v-icon>mdi-home</v-icon><p v-if="!mobile">{{ t('settings') }}</p></v-tab>
        <v-tab :value="'vouchers'"><v-icon>mdi-file-document</v-icon><p v-if="!mobile">{{ t('vouchers') }}</p></v-tab>
        <v-tab :value="'statistics'"><v-icon>mdi-chart-line</v-icon><p v-if="!mobile">{{ t('statistics') }}</p></v-tab>
    </v-tabs>
        <v-tabs-window
            v-model="activeTab"
        >
            <v-container fluid>
                <v-tabs-window-item value="settings">
                    <v-form ref="projectForm">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    :label="t('name')"
                                    v-model="project.projectName"
                                    :rules="[
                                        (value) => !!value || $t('required'),
                                    ]"
                                />
                            </v-col>
                        </v-row>
                        <v-row class="align-center">
                            <v-col cols="6" md="2" lg="3">
                                <date-text-field
                                    :label="t('start-date')"
                                    v-model="project.startDate"
                                />
                            </v-col>
                            <v-col cols="6" md="2" lg="3">
                                <date-text-field
                                    :label="t('end-date')"
                                    v-model="project.endDate"
                                />
                            </v-col>
                            <v-col cols="6" md="2" lg="3">
                                <v-switch
                                    :label="t('completed')"
                                    v-model="project.isCompleted"
                                    color="success"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    :label="t('description')"
                                    v-model="project.description"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                    color="primary"
                                    :loading="project.isSaving"
                                    @click.prevent="save()"
                                    :block="mobile"
                                >
                                    {{ t('save-and-close') }}
                                </v-btn>
                                <v-btn
                                    variant="text"
                                    @click.prevent="router.back"
                                    :block="mobile"
                                >
                                    {{ t('close') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-tabs-window-item>
                <v-tabs-window-item value="vouchers">
                    <voucher-list
                        :vouchers="project.vouchers"
                        :loading="project.isLoadingVouchers"
                    />
                </v-tabs-window-item>
                <v-tabs-window-item value="statistics">
                    <v-row>
                        <v-col>
                            <v-table>
                                <thead>
                                    <tr>
                                        <th>
                                            {{ $t('description') }}
                                        </th>
                                        <th>
                                            {{ $t('value') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ $t('total-income') }}</td>
                                        <td>{{ $n(project.projectTotalIncome, 'currencyNoDecimals', currency) }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('total-expenses') }}</td>
                                        <td>{{ $n(project.projectTotalExpenses, 'currencyNoDecimals', currency) }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('project-result') }}</td>
                                        <td>{{ $n(project.projectResult, 'currencyNoDecimals', currency) }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p class="text-subtitle-1">{{ t('expenses') }}</p>
                        </v-col>
                        <v-col cols="12" v-if="project.projectExpenses.length > 0">
                            <v-table>
                                <tbody>
                                    <tr
                                        v-for="row in project.projectExpenses"
                                        :key="row.id"
                                    >
                                        <td><v-icon class="mr-2">{{ row.icon_name }}</v-icon>{{ row.account_name }}</td>
                                        <td class="text-right"><span class="text-right">{{ n(Number(row.amount), 'currency', currency) }}</span></td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                        <v-col v-else>
                            <p class="text-body-2">{{ t('no-expenses') }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p class="text-subtitle-1">{{ t('income', 2) }}</p>
                        </v-col>
                        <v-col cols="12" v-if="project.projectIncome.length > 0">
                            <v-table>
                                <tbody>
                                    <tr
                                        v-for="row in project.projectIncome"
                                        :key="row.id"
                                    >
                                        <td><v-icon class="mr-2">{{ row.icon_name }}</v-icon>{{ row.account_name }}</td>
                                        <td class="text-right"><span class="text-right">{{ n(Number(row.amount), 'currency', currency) }}</span></td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                        <v-col v-else>
                            <p class="text-body-2">{{ t('no-income') }}</p>
                        </v-col>
                    </v-row>
                </v-tabs-window-item>
            </v-container>
        </v-tabs-window>
</template>

<script setup lang="ts">
import { useProjectStore } from '../../stores/projectStore';
import { Project } from '../../models/Project';
import PageHeader from '/resources/js/components/PageHeader.vue';
import { ref, Ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';
import voucherList from '/resources/js/pages/accounting/Components/VoucherList.vue';
import { useAuthStore } from '../../stores/authStore';

    const currentFamily = computed(() => authStore.currentFamily);
    const currency = computed(() => currentFamily.value.currency);

    const components = [
        voucherList,
        PageHeader,
    ]

    const props = defineProps({
        id: {
            type: [Number, String],
            required: true
        }
    })

    const projectStore = useProjectStore();
    const project: Ref<Project> = ref(new Project());
    const router = useRouter();
    const authStore = useAuthStore();
    const family = computed(() => authStore.currentFamily);

    if (props.id !== 'new') {
        projectStore.getProjectById(props.id).then((result) => {
            if (result) {
                project.value = result;
                project.value.getVouchers();
            } else {
                router.push({name: 'NotFound'});
            }
        }).catch(error => {
            router.push({name: 'NotFound'});
        })
    }

    const { t, n } = useI18n();
    const { mobile } = useDisplay();

    const activeTab: Ref<String> = ref('settings');
    const projectForm = ref(null);

    function save()
    {
        projectForm.value.validate().then((result) => {
            if (result.valid) {
                project.value.save().then(() => {
                    router.push({name: 'Projects'});
                });
            }
        })
    }

    watch(family, (newVal, oldVal) => {
        router.push({name: 'Projects'});
    });
</script>

<template>
    <div v-if="!mobile">
        <v-form>
        <v-row dense v-if="!mobile">
            <v-col cols="1"></v-col>
            <v-col>
                {{ $t('account') }}
            </v-col>
            <v-col cols="2">
                {{ userSettings.useSimpleLanguage ? $t('from-account') : $t('credit') }}
            </v-col>
            <v-col cols="2">
                {{ userSettings.useSimpleLanguage ? $t('to-account') : $t('debit') }}
            </v-col>
        </v-row>
        <v-row
            v-for="(line, index) in activeLines"
            :key="index"
            dense
            class=""
        >
            <v-col cols="12" md="1">
                <v-btn
                    flat
                    :icon="!mobile"
                    @click.prevent="deleteLine(line)"
                    :variant="mobile ? 'outlined' : null"
                    :block="mobile"
                    color="error"
                    tabindex="-1"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" md="7">
                <account-selector
                    v-model="line.accountId"
                    :rules="[value => !!value || $t('required')]"
                    :error-messages="errorsFor('account_id', index)"
                />
            </v-col>
            <v-col cols="6" md="2">
                <currency-text-field
                    variant="outlined"
                    density="compact"
                    v-model="line.credit"
                    inputmode="decimal"
                    :error-messages="errorsFor('credit', index)"
                    :label="mobile ? userSettings.useSimpleLanguage ? $t('from-account') : $t('credit') : null"

                />
            </v-col>
            <v-col cols="6" md="2">
                <currency-text-field
                    variant="outlined"
                    density="compact"
                    v-model="line.debit"
                    inputmode="decimal"
                    :error-messages="errorsFor('debit', index)"
                    :label="mobile ? userSettings.useSimpleLanguage ? $t('to-account') : $t('debit') : null"
                />
            </v-col>
        </v-row>
    </v-form>
    </div>
    <v-container v-else fluid class="pa-0 my-6">
        <v-row
            v-for="(line, index) in activeLines"
            :key="index"
            v-touch="{
                left: () => toggleLineTouch(index),
                right: () => toggleLineTouch(index),
            }"
            class="d-flex flex-nowrap slide"
            :style="[
                isTouched(index) == true ? { right: twoColumnsWidth } : { right: '0px' },
                {position: 'relative'},
            ]"
        >
            <v-col cols="12" class="pa-0 pb-2">
                <v-card
                    color=""
                    class="pl-0 ml-0"
                    rounded
                >
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <account-selector
                                    v-model="line.accountId"
                                    :label="$t('account')"
                                    :error-messages="errorsFor('account_id', index)"
                                    :hide-details="line.formErrors?.account_id?.length > 0 ? false : true"
                                />
                            </v-col>
                            <v-col cols="6">
                                <currency-text-field
                                    variant="outlined"
                                    density="compact"
                                    v-model="line.credit"
                                    inputmode="decimal"
                                    :hide-details="line.formErrors?.credit?.length > 0 ? false : true"
                                    :error-messages="errorsFor('credit', index)"
                                    :label="mobile ? userSettings.useSimpleLanguage ? $t('from-account') : $t('credit') : null"

                                />
                            </v-col>
                            <v-col cols="6">
                                <currency-text-field
                                    variant="outlined"
                                    density="compact"
                                    v-model="line.debit"
                                    inputmode="decimal"
                                    :hide-details="line.formErrors?.debit?.length > 0 ? false : true"
                                    :error-messages="errorsFor('debit', index)"
                                    :label="mobile ? userSettings.useSimpleLanguage ? $t('to-account') : $t('debit') : null"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="2"
                class="justify-center  pa-0 pb-2"
            >
                <v-btn
                    flat
                    :icon="!mobile"
                    @click.prevent="deleteLine(line)"
                    :variant="mobile ? 'tonal' : null"
                    :block="mobile"
                    color="error"
                    tabindex="-1"
                    class="mt-auto mb-auto"
                    :width="mobile ? '100%' : null"
                    :height="mobile ? '100%' : null"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <v-col cols="12" class="pa-0 pb-2">
                <v-btn
                    icon="mdi-plus"
                    variant="plain"
                    color="primary"
                    @click="addNew()"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss" scoped>
@import 'resources/sass/variables';

</style>

<script setup lang="ts">
import { Ref, computed, ref } from 'vue';
import { useDisplay, width } from 'vuetify';
import { useI18n } from 'vue-i18n';
import AccountSelector from '/resources/js/components/account-selector/AccountSelector.vue';
import { VoucherLine } from '@models/VoucherLine';
import { useAuthStore } from '@stores/authStore';

const { t } = useI18n();

defineExpose({
    setLines,
    addNew,
});

const props = defineProps({
    lines: Array,
    formErrors: Object,
})

const { mobile } = useDisplay();


const authStore = useAuthStore();
const userSettings = computed(() => authStore.user.userSettings);

const { width } = useDisplay();
const activeLines = computed(() => {
    return props.lines.filter(line => line.deleted_at == null);
})

function deleteLine(line) {
    line.deleted_at = moment();
    touchedLines.value = [];
}

function setLines(lines) {
    this.lines = lines;
}

function addNew() {
    props.lines.push(new VoucherLine());
}

function errorsFor(field, index) {
    let name = "lines." + index + "." + field;
    let errors = props.formErrors[name]

    return errors
}

const twoColumnsWidth = computed(() => {
      return String((width.value / 12) * 2) + "px";
})

function toggleLineTouch(index)
{
    let foundIndex = touchedLines.value.findIndex((obj) => obj === index);

    if (foundIndex == -1) {
        touchedLines.value.push(index);
    } else {
        touchedLines.value.splice(foundIndex, 1);
    }
}

function isTouched(index): boolean
{
    let foundIndex = touchedLines.value.findIndex((obj) => obj === index);

    if (foundIndex == -1) {
        return false;
    } else {
        return true;
    }
}

const touchedLines = ref([]);
</script>

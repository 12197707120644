{
  "budget": "Budget | Budgetar",
  "income": "Inkomst | Inkomster",
  "fixed-expense": "Fast kostnad | Fasta kostnader",
  "variable-expense": "Variabel kostnad | Variabla kostnader",
  "result": "Resultat | Resultat",
  "accounted-result": "Bokfört resultat",
  "balance": "Saldo",
  "expenses": "Kostnader",
  "budget-result": "Budgetresultat",
  "choose-your-language": "Välj ditt språk",
  "choose-the-family-you-want-to-work-with": "Välj den familj du vill arbeta med",
  "new-family": "Ny familj",
  "accounts-in": "Konton i",
  "new": "Ny",
  "delete": "Radera",
  "accounted": "Bokfört",
  "budget-categories": "Budgetkategorier",
  "edit": "Redigera",
  "new-category": "Ny kategori",
  "edit-category": "Redigera kategori",
  "create": "Skapa",
  "save": "Spara",
  "category-name": "Kategorinamn",
  "description": "Beskrivning",
  "name-is-required": "Namn krävs",
  "name-must-be-less-than-255-characters": "Namnet måste vara kortare än 255 tecken",
  "edit-account": "Redigera konto",
  "account-number": "Kontonummer",
  "name": "Namn",
  "statistics": "Statistik",
  "welcome-to-factum": "Välkommen till Factum",
  "sign-in": "Logga in",
  "oops-nothing-here": "Oops. Ingenting här...",
  "go-home": "Gå hem",
  "home": "Hem",
  "login-to-use-factum": "Logga in för att använda Factum",
  "email": "E-post",
  "password": "Lösenord",
  "keep-me-logged-in": "Håll mig inloggad",
  "forgot-password": "Glömt lösenord",
  "cancel": "Avbryt",
  "close-window": "Stäng fönster",
  "required": "Krävs",
  "enter-your-e-mail-to-reset-your-password": "Ange din e-postadress för att återställa ditt lösenord",
  "invalid-username-or-password": "Ogiltigt användarnamn eller lösenord",
  "your-user-account-was-created-successfully": "Ditt användarkonto skapades framgångsrikt",
  "account-type": "Kontotyp",
  "email-verified": "E-post verifierad",
  "account-created": "Konto skapat",
  "create-your-factum-account": "Skapa ditt Factum-konto",
  "this-email-is-already-registered": "Denna e-postadress är redan registrerad",
  "you-can-now-log-in": "Du kan nu logga in",
  "check-your-inbox-to-continue!": "Kolla din inkorg för att fortsätta!",
  "create-account": "Skapa konto",
  "sign-up-for-your-free-factum-account-now": "Registrera dig gratis nu",
  "first-name": "Förnamn",
  "last-name": "Efternamn",
  "birthdate": "Födelsedatum",
  "repeat-password": "Upprepa lösenord",
  "register": "Registrera",
  "the-passwords-does-not-match": "Lösenorden stämmer inte överens",
  "change-password": "Byt lösenord",
  "reset-your-password": "Återställ ditt lösenord",
  "an-error-occured-when-changing-the-password": "Ett fel uppstod när lösenordet ändrades",
  "sent": "Skickat",
  "you-must-verify-your-email": "Du måste verifiera din e-postadress",
  "resend-email": "Skicka e-post igen",
  "logout": "Logga ut",
  "invitations-to-families": "Inbjudningar till familjer",
  "you-got-invited-by": "Du blev inbjuden av",
  "role": "Roll",
  "accept": "Acceptera",
  "decline": "Avböj",
  "families": "Familjer",
  "inventory": "Inventarier",
  "calendar": "Kalender",
  "more": "Mer",
  "my-profile": "Min profil",
  "add-profile-picture": "Lägg till profilbild",
  "language": "Språk",
  "norwegian": "Norska",
  "english": "Engelska",
  "profile-image": "Profilbild",
  "personal-information": "Personlig information",
  "my-families": "Mina familjer",
  "saving": "Sparkonto",
  "add": "Lägg till",
  "from-date": "Från datum",
  "to-date": "Till datum",
  "recurrency": "Återkommande",
  "amount": "Belopp",
  "actions": "Åtgärder",
  "repeat-every": "Upprepa varje",
  "months": "Månad | Månader",
  "years": "År",
  "new-account": "Nytt konto",
  "save-and-close": "Spara och stäng",
  "category": "Kategori",
  "difference": "Skillnad",
  "forecast": "Prognos",
  "fill": "Fyll",
  "date": "Datum",
  "action": "Åtgärd",
  "subtract": "Subtrahera",
  "set-to": "Ställ in till",
  "events": "Händelser",
  "top-expenses": "Toppkostnader",
  "others": "Andra",
  "expense": "Kostnad",
  "accounting": "Bokföring",
  "vouchers": "Kvittningar",
  "search": "Sök",
  "vouchers-per-page": "Kvittningar per sida",
  "new-voucher": "Nytt kvitto",
  "edit-voucher": "Redigera kvitto",
  "drop-files-here": "Släpp filer här",
  "drag-n-drop": "Dra & släpp",
  "or-select-files": "eller välj filer",
  "no-files": "Inga filer",
  "account": "Konto",
  "note": "Anteckning",
  "credit": "Kredit",
  "debit": "Debet",
  "invalid-account-number": "Ogiltigt kontonummer",
  "money": "Pengar",
  "bank-account": "Bankkonto",
  "cash": "Kontanter",
  "savings-account": "Sparkonto",
  "initial-balance": "Ingående saldo",
  "liquidity-forecast": "Likviditetsprognos",
  "money-accounts": "Pengekonton",
  "no-money-accounts": "Inga pengekonton",
  "create-one-now": "Skapa en nu",
  "transactions": "Transaktioner",
  "no-transactions-matches-the-filter": "Inga transaktioner matchar filtret",
  "every": "varje",
  "family-name": "Familjenamn",
  "create-a-family": "Skapa en familie",
  "create-a-new-family-and-start-from-scratch": "Skapa en ny familj och börja från början",
  "you-are-not-member-of-any-families": "Du är inte medlem i någon familj",
  "you-need-to-be-member-of-a-family-in-order-to-use-factum": "Du måste vara medlem i en familj för att använda Factum",
  "you-have-two-options": "Du har två alternativ",
  "wait-for-an-invitation": "Vänta på en inbjudan",
  "have-someone-else-invite-you-to-their-family": "Låt någon annan bjuda in dig till deras familj",
  "others-can-invite-you-by-your-email": "Andra kan bjuda in dig via din e-postadress",
  "when-someone-invites you-the-invitation-will-show-up-here": "När någon bjuder in dig kommer inbjudan att visas här",
  "you-are-logged-in-as": "Du är inloggad som",
  "you-need-to-wait-a-bit-before-you-can-do-this-again": "Du måste vänta lite innan du kan göra detta igen",
  "user": "Användare",
  "reader": "Läsare",
  "owner": "Ägare",
  "edit-family": "Redigera familj",
  "members": "Medlemmar",
  "remove": "Ta bort",
  "invite-someone": "Bjud in någon",
  "send-invitation": "Skicka inbjudan",
  "the-invitation-was-sendt": "Inbjudan skickades",
  "invited-by": "Inbjuden av",
  "simple-registration": "Enkel registrering",
  "current-money-balance": "Nuvarande pengasaldo",
  "upcoming-events": "Kommande händelser",
  "in": "I",
  "budget-increased-by": "Budgeten ökade med",
  "budget-decreased-by": "Budgeten minskade med",
  "budget-changed-to": "Budgeten ändrades till",
  "ok": "Ok",
  "delete-voucher": "Radera kvitto",
  "do-you-really-want-to-delete-this-voucher": "Är du säker på att du vill radera detta kvitto?",
  "delete-account": "Radera konto",
  "do-you-really-want-to-delete-this-account": "Är du säker på att du vill radera detta konto?",
  "january": "Januari",
  "february": "Februari",
  "march": "Mars",
  "april": "April",
  "may": "Maj",
  "june": "Juni",
  "july": "Juli",
  "august": "Augusti",
  "september": "September",
  "october": "Oktober",
  "november": "November",
  "december": "December",
  "select-period": "Välj period",
  "this-affects-many-parts-of-the-system": "Detta påverkar många delar av systemet",
  "dark": "Mörk",
  "no-category-selected": "Ingen kategori vald",
  "no-vouchers-matches-the-filter": "Inga kvitton matchar filtret",
  "filter": "Filter",
  "each": "varje",
  "MONTH": "Månad",
  "YEAR": "År",
  "from": "Från",
  "budgets": "Budgetar",
  "remaining": "Kvarstående",
  "budget-account": "Budgetkonto",
  "event": "Händelse",
  "back": "Tillbaka",
  "to-be-placed": "Att placeras",
  "recurring-budgets": "Återkommande budgetar",
  "recurring-budgets-set-a-fixed-amount-for-the-account-within-a-specific-interval": "Återkommande budgetar fastställer ett fast belopp för kontot inom ett specifikt intervall",
  "this-can-be-overridden-by-using-events": "Detta kan åsidosättas genom att använda händelser",
  "month": "månad",
  "current": "Nuvarande",
  "edit-budget": "Redigera budget",
  "budget-events": "Budgethändelser",
  "budget-events-can-modify-a-recurring-budget-when-something-occurs": "Budgethändelser kan ändra en återkommande budget när något inträffar",
  "edit-budget-event": "Redigera budgethändelse",
  "new-budget": "Ny budget",
  "no-budgets-for-this-account": "Inga budgetar för detta konto",
  "no-budget-events-for-this-account": "Inga budgethändelser för detta konto",
  "shows-a-list-of-vouchers-within-a-period-of-time": "Visar en lista över kvitton inom en viss tidsperiod",
  "no-accounts": "Inga konton",
  "shows-the-usage-in-this-account-over-time": "Visar användningen i detta konto över tiden",
  "no-data-to-show": "Inga data att visa",
  "created-by": "Skapad av",
  "updated-by": "Uppdaterad av",
  "error-during-save": "Fel under sparande",
  "an-error-occured-during-saving": "Ett fel uppstod under sparandet",
  "no-budget-events-this-month": "Inga budgethändelser denna månad",
  "no-persons": "Inga personer",
  "select-icon": "Välj ikon",
  "apple": "Apple",
  "software": "Programvara",
  "select-an-icon": "Välj en ikon",
  "remove-icon": "Ta bort ikon",
  "settings": "Inställningar",
  "notifications": "Notiser",
  "use-simple-language": "Använd enkelt språk",
  "from-account": "Från konto",
  "to-account": "Till konto",
  "push": "Push",
  "daily-accounting-reminder": "Daglig påminnelse om bokföring",
  "budget-exceeded": "Budget överskriden",
  "voucher-created": "Kvitto skapat",
  "users": "Användare",
  "invitations": "Inbjudningar",
  "persons": "Personer",
  "make-changes-to-your-family": "Gör ändringar i din familj",
  "family": "Familj",
  "manage-the-users-that-can-access-this-family": "Hantera användarna som har tillgång till denna familj",
  "guest": "Gäst",
  "remove-user": "Ta bort användare",
  "saved-changes": "Sparade ändringar",
  "admin": "Admin",
  "do-you-really-want-to-remove-this-user": "Är du säker på att du vill ta bort denna användare från familjen? Användaren kommer att förlora tillgången omedelbart.",
  "the-user-was-successfully-removed": "Användaren togs bort från familjen",
  "invite-someone-to-this-family-or-edit-already-sent-invitations": "Bjud in någon till denna familj eller redigera redan skickade inbjudningar",
  "invite-someone-using-their-email-address": "Bjud in personer genom att använda deras e-postadress",
  "delete-invitation": "Ta bort inbjudan",
  "the-invitation-was-deleted": "Inbjudan togs bort",
  "do-you-really-want-to-delete-this-invitation": "Är du säker på att du vill ta bort denna inbjudan",
  "the-invitation-was-sent": "Inbjudan skickades",
  "send": "Skicka",
  "invalid-e-mail": "Ogiltig e-postadress",
  "a-list-of-persons-in-the-family": "En lista över personer som ingår i familjen",
  "this-information-is-used-to-calculate-budgets-and-show-statistics-of-income-and-expenses": "Denna information används för att beräkna budgetar och visa statistik över inkomster och kostnader",
  "add-person": "Lägg till person",
  "birth-year": "Födelseår",
  "gender": "Kön",
  "male": "Man",
  "female": "Kvinna",
  "create-a-new-family-now": "Skapa en ny familj nu",
  "add-family": "Ny familj",
  "reading-mode": "Läsläge",
  "you-dont-have-access-to-perform-any-changes-to-this-family": "Du har inte tillgång att göra några ändringar på denna familj",
  "server-error": "Serverfel",
  "missing-anything": "Saknar du något?",
  "developing-a-great-app-is-a-continous-job": "Att utveckla en bra app är ett kontinuerligt arbete",
  "we-would-love-to-get-your-ideas": "Vi skulle älska att höra dina idéer",
  "write-us-something": "Skriv något till oss",
  "missing-an-icon-or-maybe-you-just-want-to-say-hi": "Saknar du en ikon? Eller kanske vill du bara säga hej?",
  "we-appreciate-every-contact-we-get": "Vi uppskattar varje kontakt vi får",
  "thank-you": "Tack så mycket",
  "your-message-was-sent": "Ditt meddelande skickades",
  "we-will-reply-to-your-registered-email-as-soon-as-we-can": "Vi svarar dig på din registrerade e-postadress så snart som möjligt",
  "welcome": "Välkommen",
  "norway": "Norge",
  "sweden": "Sverige",
  "norwegian-krone": "Norska kronor",
  "swedish-krone": "Svenska kronor",
  "country": "Land",
  "currency": "Valuta",
  "next": "Nästa",
  "car-expenses": "Bilutgifter",
  "kindergarden": "Förskola",
  "tobacco": "Tobak",
  "car-loan": "Billån",
  "rent": "Hyra",
  "mortgage": "Bolån",
  "kids-expenses": "Barnutgifter",
  "pets-expenses": "Kostnader för husdjur",
  "super-admin": "Superadmin",
  "tools-for-managing-factum": "Verktyg för att hantera Factum",
  "feedbacks": "Återkopplingar",
  "activated": "Aktiverad",
  "no-users-matching-the-filter": "Inga användare matchar filtret",
  "created": "Skapad",
  "updated": "Uppdaterad",
  "family-count": "Antal familjer",
  "last-seen": "Senast sedd",
  "never": "Aldrig",
  "last-activity": "Senaste aktivitet",
  "no-families": "Inga familjer",
  "budget-accounts": "Budgetkonton",
  "this-family-has-not-completed-setup": "Denna familj har inte slutfört installationen",
  "no-feedbacks-matching-the-filter": "Inga återkopplingar matchar filtret",
  "message": "Meddelande",
  "mark-as-resolved": "Markera som löst",
  "write-to-us": "Skriv till oss",
  "email-was-sent": "E-post skickades",
  "resend-verification-email": "Skicka verifierings-e-post igen",
  "disable-user": "Inaktivera användare",
  "this-user-is-deactivated": "Denna användare är inaktiverad",
  "disabled-by": "Inaktiverad av",
  "enable": "Aktivera",
  "the-user-was-disabled": "Användaren blev inaktiverad",
  "the-user-was-enabled": "Användaren blev aktiverad",
  "your-account-is-disabled": "Ditt konto är inaktiverat",
  "push-notifications": "Push-notiser",
  "delete-family": "Radera familj",
  "deleting-this-family-can-not-be-undone": "Att radera denna familj kan inte ångras",
  "all-data-will-be-permanently-destroyed": "All data kommer att förstöras permanent",
  "type-the-excact-family-name-to-confirm-that-you-really-want-to-delete-this-family-forever": "Skriv in det exakta familjenamnet för att bekräfta att du verkligen vill radera denna familj för alltid",
  "permanently-delete-family": "Radera familj permanent",
  "switched-to": "Bytt till",
  "leave-family": "Lämna familjen",
  "do-you-really-want-to-leave-this-family": "Är du säker på att du vill lämna denna familj?",
  "leave": "Lämna",
  "invalid-first-name": "Ogiltigt förnamn",
  "invalid-last-name": "Ogiltigt efternamn",
  "invalid-password": "Ogiltigt lösenord",
  "creating-an-account-is-free-and-without-any-strings-attached": "Att skapa ett konto är gratis och utan några krav",
  "you-can-delete-the-account-at-any-point": "Du kan ta bort kontot när som helst",
  "creating-account": "Skapar konto",
  "password-requirements": "Krav på lösenord",
  "min-8-chars-one-number-and-one-special-char": "Minst 8 tecken, inklusive minst 1 siffra och 1 specialtecken",
  "related": "Relaterat",
  "relations": "Relationer",
  "danger-zone": "Farozon",
  "this-user-has-not-confirmed-its-email-address-and-is-not-yet-activated": "Denna användare har inte bekräftat sin e-postadress och är inte aktiverad ännu",
  "logging-in": "Loggar in...",
  "notes": "Anteckningar",
  "title": "Titel",
  "author": "Författare",
  "no-notes": "Inga anteckningar",
  "new-note": "Ny anteckning",
  "delete-note": "Radera anteckning",
  "do-you-really-want-to-delete-this-note": "Är du säker på att du vill radera denna anteckning?",
  "os": "OS",
  "agent": "Agent",
  "mobile": "Mobil",
  "device": "Enhet",
  "login-date": "Inloggningsdatum",
  "no-devices": "Inga enheter",
  "devices": "Enheter",
  "hello": "Hej",
  "active-users-right-now": "Aktiva användare just nu",
  "count": "Antal",
  "platforms": "Plattformar",
  "new-users": "Nya användare",
  "last-24-hours": "Senaste 24 timmarna",
  "active-users": "Aktiva användare",
  "right-now": "Just nu",
  "users-count": "Antal användare",
  "total": "Totalt",
  "result-for": "Resultat för",
  "shows-how-your-money-balance-evolves": "Visar hur dina pengar utvecklas över tiden",
  "shows-how-your-expenses-are-distributed": "Visar hur dina utgifter fördelas",
  "showing": "Visar",
  "we-could-not-find-the-page-you-are-looking-for": "Vi kunde inte hitta sidan du letar efter",
  "go-back": "Gå tillbaka",
  "shows-a-list-of-your-planned-events-for-the-selected-period": "Visar en lista över dina planerade händelser för den valda perioden",
  "no-accounts-in-this-category": "Inga konton i denna kategori",
  "categories": "Kategorier",
  "this-budget-is-empty": "Denna budget är tom",
  "start-by-adding-some-categories": "Börja med att lägga till några kategorier",
  "this-category-does-not-have-any-accounts": "Denna kategori har inga konton",
  "add-accounts-to-set-up-a-budget-with-numbers": "Lägg till konton för att upprätta en budget med siffror",
  "no-data-to-display": "Inga data att visa",
  "setup-a-budget-to-show-this-chart": "Ställ in en budget för att visa detta diagram",
  "other-accounts": "Andra konton",
  "my-accounts": "Mina konton",
  "budget-gap": "Budgetglapp",
  "overconsumption": "Överkonsumtion",
  "no-overconsumption-this-period": "Ingen överkonsumtion denna period",
  "gap": "Glapp",
  "liquidity": "Likviditet",
  "how-long-will-your-money-last": "Hur länge kommer dina pengar att räcka",
  "liquidity-is-a-measure-of-how-solid-your-economy-is": "Likviditet är ett mått på hur solid din ekonomi är",
  "the-higher-the-score-the-longer-your-money-will-last": "Ju högre poäng, desto längre kommer dina pengar att räcka",
  "a-score-of-2-is-considered-good-while-a-score-above-1-is-considered-okay": "Ett poäng på 2 anses vara bra, medan ett poäng över 1 anses vara okej",
  "the-score-is-based-on-fixed-expenses-and-your-total-money-balance": "Poängen baseras på fasta utgifter och din totala pengabalans",
  "how-is-the-score-calculated": "Hur beräknas poängen?",
  "the-math-behind-is-easy": "Matematiken bakom är enkel",
  "your-money-divided-by-your-fixed-expenses": "Dina pengar delat med dina fasta utgifter",
  "score": "Poäng",
  "what-can-this-tell-you": "Vad kan detta berätta för dig",
  "how-long-your-money-will-last-if-you-lost-all-your-income": "Hur länge dina pengar kommer att räcka om du förlorar alla dina inkomster",
  "how-able-you-are-to-pay-your-expenses": "Hur förmögen du är att betala dina utgifter",
  "banks-use-this-score-to-determine-if-they-will-grant-loans": "Banker använder detta poäng för att avgöra om de kommer att bevilja lån",
  "liquidity-is-a-measure-of-how-able-you-are-to-pay-your-expenses": "Likviditet är ett mått på hur väl du kan betala dina utgifter",
  "overconsumption-is-the-sum-of-accounted-amounts-for-accounts-where-you-have-spent-more-money-than-the-budget-allows": "Överkonsumtion är summan av bokförda belopp för konton där du har spenderat mer pengar än vad budgeten tillåter",
  "when-your-fiexed-expenses-are-0-the-score-is-set-to-2-as-default": "När dina fasta utgifter är 0, sätts poängen som standard till 2",
  "exclude-from-balance": "Exkludera från saldo",
  "when-an-account-is-excluded-from-balance-its-balance-will-not-be-included-in-the-total-balance-in-charts-or-in-liquidity": "När ett konto är exkluderat från saldot, kommer dess saldo inte att inkluderas i det totala saldot i diagram eller i likviditet",
  "average": "Genomsnitt",
  "average-accounted": "Genomsnitt bokfört",
  "value": "Värde",
  "months-with-overconsumption": "Månader med överkonsumtion",
  "percent": "Procent",
  "average-overconsumption": "Genomsnittlig överkonsumtion",
  "new-budget-item": "Ny budgetpost",
  "loading": "Laddar",
  "projects": "Projekt",
  "add-project": "Nytt projekt",
  "no-projects": "Inga projekt",
  "create-your-first-project": "Skapa ditt första projekt",
  "project": "Projekt",
  "start-date": "Startdatum",
  "end-date": "Slutdatum",
  "close": "Stäng",
  "new-project": "Nytt projekt",
  "active": "Aktiva",
  "finished": "Avslutade",
  "completed": "Fullbordade",
  "no-vouchers": "Inga kvitton",
  "add-voucher": "Lägg till kvitto",
  "total-income": "Total inkomst",
  "total-expenses": "Totala utgifter",
  "project-result": "Projektresultat",
  "add-line": "Lägg till rad",
  "voucher": "Kvitto",
  "creating-an-account-is-100-percent-free": "Att skapa ett konto är 100 procent gratis",
  "vouchers-are-transactions-in-your-accounting": "Kvitton är transaktioner i din bokföring",
  "a-transaction-moves-money-from-one-account-to-another": "En transaktion flyttar pengar från ett konto till ett annat",
  "add-account": "Lägg till konto",
  "a-money-account-is-where-you-keep-your-money": "Ett pengekonto är där du förvarar dina pengar",
  "my-account": "Mitt konto",
  "no-income": "Ingen inkomst",
  "no-expenses": "Inga utgifter",
  "we-have-sent-you-an-email-with-instructions-on-how-to-confirm-your-email-address": "Vi har skickat dig ett e-postmeddelande med instruktioner om hur du bekräftar din e-postadress",
  "if-you-did-not-receive-it-you-can-resend-it-using-the-button-below": "Om du inte har fått det kan du skicka det igen med knappen nedan",
  "get-started": "Kom igång",
  "spend-some-minutes-getting-your-new-factum-family-ready": "Tillbringa några minuter med att förbereda din nya Factum-familj",
  "general-settings": "Allmänna inställningar",
  "tell-us-where-your-family-is-located-and-which-currency-to-use": "Berätta var din familj är belägen och vilken valuta som ska användas",
  "family-members": "Familjemedlemmar",
  "to-be-able-to-compare-you-to-other-families-we-need-to-know-a-little-about-who-your-family-is": "För att kunna jämföra dig med andra familjer behöver vi veta lite om vem din familj är",
  "right-now-your-budget-is-blank": "Just nu är din budget tom",
  "do-you-want-us-to-setup-a-default-budget-for-you": "Vill du att vi ska ställa in en standardbudget för dig?",
  "you-can-edit-this-later": "Du kan redigera detta senare",
  "ordinary-budget": "Ordinarie budget",
  "includes-groceries-but-does-not-split-between-food-and-other-household-articles": "Inkluderar matvaror men delar inte upp mellan mat och andra hushållsartiklar",
  "detailed-budget": "Detaljerad budget",
  "empty": "Tom",
  "start-fresh-and-build-your-own-budget": "Börja med ett rent blad och bygg din egen budget",
  "other-expenses": "Andra utgifter",
  "check-the-boxes-corresponding-to-the-expenses-your-family-has": "Markera rutorna som motsvarar de utgifter din familj har",
  "splits-between-food-and-cleaning-items": "Delar upp mellan mat och rengöringsartiklar",
  "add-your-money-accounts-with-todays-balance-to-let-us-show-you-how-your-money-develops-with-time": "Lägg till dina pengekonton med dagens saldo för att låta oss visa hur dina pengar utvecklas över tid",
  "complete": "Komplett",
  "press-complete-to-start-the-installation": "Tryck på 'komplett' för att starta installationen",
  "we-have-all-the-information-we-need": "Vi har all information vi behöver",
  "this-wizard-will-close-automatically-when-the-configuration-is-completed": "Denna guide kommer att stängas automatiskt när konfigurationen är slutförd",
  "this-might-take-a-while": "Detta kan ta en stund...",
  "creating-persons": "Skapar personer",
  "creating-money-accounts": "Skapar pengekonton",
  "creating-budget": "Skapar budget",
  "you-are": "Du är",
  "euro": "Euro",
  "united-states-dollar": "Amerikanska dollar",
  "too-many-attempts-try-again-later": "För många försök. Försök igen senare",
  "verify-your-email": "Verifiera din e-post",
  "become-a-family-member": "Bli familjemedlem",
  "we-would-love-to-hear-from-you": "Vi skulle älska att höra från dig",
  "projects-are-a-great-way-to-get-an-overview-of-income-or-expenses-related-to-an-event-or-a-project": "Projekt är ett utmärkt sätt att få en översikt över inkomster eller utgifter som är relaterade till en händelse eller ett projekt",
  "you-can-set-goals-for-how-much-you-want-to-earn-spend-or-save": "Du kan sätta mål för hur mycket du vill tjäna, spendera eller spara",
  "swedish": "Svenska"
}

import {
    createRouter,
    createWebHistory,
  } from "vue-router";
import { useAuthStore } from "@stores/authStore";
import { useFamilyStore } from "@stores/familyStore";
import MainLayout from "./layouts/MainLayout.vue";
import EmptyLayout from "./layouts/EmptyLayout.vue";
import Login from "./pages/login/Login.vue";
import Projects from "./pages/projects/Projects.vue";
import Project from "./pages/projects/Project.vue";
import Superadmin from "./pages/superadmin/SuperAdmin.vue";
import Users from "./pages/superadmin/Users.vue";
import Feedbacks from "./pages/superadmin/Feedbacks.vue";
import Settings from "./pages/profile/Settings.vue";
import ErrorNotFound from "./pages/error-pages/ErrorNotFound.vue";
import NoFamilies from "./pages/login/NoFamilies.vue";
import Assistant from "./pages/assistant/Assistant.vue";

  const routes = [
    {
        path:"/",
        name: "Home",
        component: MainLayout,
        children: [{path: '', name: 'Home', component: () => import('./pages/home/Home.vue')}],
        meta: {transition: 'slide-left'}
    },
    {
        path:"/login-form",
        name: "Login",
        component: EmptyLayout,
        children: [{path: '', name: 'LoginForm', component: Login}]
    },
    {
        path:"/no-families",
        name: "NoFamilies",
        component: EmptyLayout,
        children: [{path: '', name: 'NoFamiliesForm', component: NoFamilies}]
    },
    {
        path:"/must-verify-email",
        name: "Must verify email",
        component: EmptyLayout,
        children: [{path: '', name: 'MustVerifyEmailForm', component: () => import('./pages/login/MustVerifyEmail.vue')}]
    },
    {
        path:"/reset-password",
        name: "Reset password",
        component: EmptyLayout,
        children: [{path: '', name: 'ResetPasswordForm', component: () => import('./pages/login/ResetPassword.vue')}]
    },
    {
        path:"/families",
        name: "Families",
        component: MainLayout,
        children: [{path: '', name: 'Families', component: () => import('./pages/families/Families.vue')}]
    },
    {
        path:"/profile",
        name: "Profile",
        component: MainLayout,
        children: [{path: '', name: 'Profile', component: () => import('./pages/profile/Profile.vue')}]
    },
    {
        path:"/user-settings",
        name: "Settings",
        component: MainLayout,
        children: [{path: '', name: 'Settings', component: Settings}]
    },
    {
        path:"/budget",
        name: "Budget",
        component: MainLayout,
        children: [{path: '', name: 'Budget', component: () => import('./pages/budget/Budget.vue')}]
    },
    {
        path:"/accounting",
        name: "Accounting",
        component: MainLayout,
        children: [{path: '', name: 'Accounting', component: () => import('./pages/accounting/Accounting.vue')}]
    },
    {
        path:"/money",
        name: "Money",
        component: MainLayout,
        children: [{path: '', name: 'Money', component: () => import('./pages/money/Money.vue')}]
    },
    {
        path:"/superadmin",
        component: MainLayout,
        children: [
            {path: 'superadmin', name: 'Superadmin', component: Superadmin},
            {path: 'users', name: 'Users', component: Users},
            {path: 'feedbacks', name: 'Feedbacks', component: Feedbacks}
        ]
    },
    {
        path:"/projects",
        component: MainLayout,
        children: [
            {path: '', name: 'Projects', component: Projects},
            {path: ':id', name: 'Project', component: Project, props: true},
        ]
    },
    {
        path:"/assistant",
        component: MainLayout,
        children: [
            {path: '', name: 'Assistant', component: Assistant},
        ]
    },

    // Always leave this as last one,
    // but you can also remove it
    {
        path: '/:catchAll(.*)*',
        name: 'NotFound',
        component: ErrorNotFound,
    },

  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0, left: 0 }
    },
  });

  router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
      window.location = to.fullPath
    }
  })

  router.beforeEach(async (to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login-form', '/reset-password'];
    const authRequired = !publicPages.includes(to.path);
    const store = useAuthStore();
    let auth = await store.isLoggedIn().catch(err => {
        console.error(err)
        //Probably means we are not logged in
    })
    let verified = await store.isVerified().catch(err => {
        console.error(err)
        //Probably means we are not logged in
    })

    if (authRequired == true && auth == false) {
        next({path: '/login-form'});
        return;
    }

    if (authRequired == false && auth == true) {
        next({path: '/'});
        return;
    }

    if (auth == true && verified == false) {
        if (to.fullPath != '/must-verify-email') {
            next({path: '/must-verify-email'});
            return;
        } else {
            next();
            return;
        }
    }

    let familyStore = useFamilyStore();
    const familyCount = store.user?.families.length;

    if (to.fullPath == '/no-families' && familyCount > 0 && auth) {
        next('/');
        return;
    }

    if (familyCount == 0 && auth && to.fullPath !== '/no-families') {
        next({path: '/no-families'});
        return;
    }

    if (to.fullPath.includes('/superadmin')) {
        if (store.user?.isSuperAdmin == false) {
            next(from);
            return;
        }
    }

    next();
});

  export default router;

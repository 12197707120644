
<template v-slot:activator="{ on, attrs }">
    <v-menu v-if="shouldShow">
        <template v-slot:activator="{ props }">
            <v-btn
                v-bind="props"
            >
                <v-badge :model-value="invitations.length > 0" :content="invitations.length" color="error" >
                    <v-icon size="24" icon="mdi-account-group" />
                </v-badge>
                <span v-if="!mobile" class="ml-3">{{ currentFamily.familyName }}</span>
            </v-btn>
        </template>
        <v-list>
            <v-list-subheader>{{ $t('choose-the-family-you-want-to-work-with') }}</v-list-subheader>
            <v-list-item
                v-for="(item, index) in families"
                :key="index"
                @click.prevent="setCurrentFamily(item)"
                :append-icon="currentFamily.id == item.id ? 'mdi-check' : null"
            >
                <v-list-item-title :class="currentFamily.id == item.id ? 'text-accent' : null">
                    <v-icon icon="mdi-account-group" class="mr-5"/>
                    {{ item.familyName }}
                </v-list-item-title>
            </v-list-item>
            <v-divider v-if="hasInvitations" />
            <v-list-subheader v-if="hasInvitations">{{ $t('invitations-to-families') }}</v-list-subheader>
            <v-list-item
                v-for="(item, index) in invitations"
                :key="item.id + '-' + index"
            >
                <template v-slot:subtitle>
                    <v-list-item-title>
                        <v-icon class="">mdi-account-multiple-plus</v-icon>
                        {{ item.family.familyName }}
                    </v-list-item-title>
                </template>
                    <div class="text-body-2 mb-2" style="white-space: break-spaces;">
                        <span>{{ $t('you-got-invited-by') }} {{ item.createdBy.firstName + ' ' + item.createdBy.lastName }}.</span>
                        <br />
                        <span>{{ $t('role') }}: {{ item.role }}</span>
                    </div>
                    <v-row class="mb-2">
                        <v-col>
                            <v-btn density="compact" variant="outlined" @click.stop="acceptInvitation(item)">{{ $t('accept') }}</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn density="compact" variant="outlined" color="error" @click.stop="rejectInvitation(item)">{{ $t('decline') }}</v-btn>
                        </v-col>
                    </v-row>
            </v-list-item>
            <v-divider />
            <v-list-item @click="newFamily()">
                <v-list-item-title><v-icon icon="mdi-plus" class="mr-5" />
                    {{ $t('new-family') }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
    <FamilyDialog ref="familyDialog" @did-save-new-family="selectNewFamily"/>
</template>


<script setup lang="ts">
import { useDisplay } from 'vuetify';
import { useAuthStore } from '@stores/authStore';
import { useFamilyStore } from '@stores/familyStore';
import { Ref, computed, ref } from 'vue';
import { Family } from '@models/Family';
import { FamilyInvitation } from '@models/FamilyInvitation';
import FamilyDialog from '../../pages/families/FamilyDialog.vue';

const authStore = useAuthStore();
const familyStore = useFamilyStore();

const currentFamily: Ref<Family> = computed(() => authStore.currentFamily);
const invitations: Ref<Array<FamilyInvitation>> = computed(() => familyStore.invitations);
const families: Ref<Array<Family>> = computed(() => familyStore.families);

const { mobile } = useDisplay()
const hasInvitations: Ref<boolean> = computed(() => invitations.value.length > 0);
const shouldShow: Ref<boolean> = computed(() => families.value.length > 1 || hasInvitations.value);

function setCurrentFamily(family: Family): void
{
    authStore.setCurrentFamily(family);
}

function acceptInvitation(invitation: FamilyInvitation): void
{
    invitation.accept().then((newFamily: Family) => {
        familyStore.removeInvitation(invitation);
        familyStore.addFamily(newFamily);
    })
}

function rejectInvitation(invitation: FamilyInvitation): void
{
    invitation.reject().then(() => {
        familyStore.removeInvitation(invitation);
    })
}

const familyDialog = ref(null);
function newFamily()
{
    familyDialog.value.openNewFamily();
}

function selectNewFamily(newFamily: Family)
{
    familyDialog.value.close()
    setCurrentFamily(newFamily);
}

//Getting invitations and families after initialization
familyStore.getInvitations();
familyStore.getFamilies();

</script>

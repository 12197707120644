<template>
        <v-dialog v-model="props.open" max-width="400px">
        <v-card>
            <v-toolbar color="main">
                <v-btn icon dark @click="$emit('cancel')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('forgot-password') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col>
                        <div class="text-subtitle-1 pb-4">{{ $t('enter-your-e-mail-to-reset-your-password') }}</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-if="!resetSent"
                            v-model="props.data.email"
                            type="email"
                            label="E-mail"
                            prepend-inner-icon="mdi-email"
                            :error-messages="errors"
                        />
                        <v-label v-if="props.hasError" color="red" style="color:red;">{{ props.errorMessage }}</v-label>

                    </v-col>
                </v-row>
                <div v-if="resetSent" class="px-6 justify-space-between">
                    <v-btn
                        color="primary"
                        @click="$emit('cancel')"
                    >
                        {{ $t('close-window') }}
                    </v-btn>
                </div>
                <div v-else class="justify-space-between">
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary"
                                :disabled="!props.data.email"
                                :loading="loading"
                                @click="$emit('reset-password')"
                                block
                            >
                                {{ $t('forgot-password') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    setup(props) {
        return {
            props,
        }
    },
    props: {
        open: {
            default: false,
            type: Boolean,
        },
        resetSent: {
            default: false,
            type: Boolean,
        },
        email: {
            default: '',
            type: String,
        },
        data: {
            default: {
                "email": ""
            },
            type: Object
        },
        loading: {
            default: false,
            type: Boolean,
        },
        errors: {
            default: () => [],
            type: Array,
        },
        hasError: {
            default: false,
            type: Boolean
        },
        errorMessage: {
            default: '',
            type: String
        }
    },
}
</script>

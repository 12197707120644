import axios from "axios";
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';
import { User } from './User';

/**
 * A model representing the data for UserFeedback.
 * The model-data can be retrieved from /api/feedback
 */
export class UserFeedback implements ApiNotificationObserver
{
    public id: number|null = null;
    public message: string = "";
    public resolvedAt: DateTime|null = null;
    public createdAt: DateTime|null = null;
    public createdBy: User|null = null;

    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;

    public observeEvents: ApiEvent[] = [

    ];

    /**
     * Creates a new empty UserFeedback object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new UserFeedback model from json data
     * @param json An object holding the data to parse
     * @returns UserFeedback
     */
    static fromJson(json: any, observe: boolean = false): UserFeedback
    {
        const obj = new UserFeedback(observe);
        obj.id = json.id;
        obj.message = json.message;

        if (json.resolved_at) {
            obj.resolvedAt = DateTime.fromISO(json.resolved_at);
        }

        if (json.created_at) {
            obj.createdAt = DateTime.fromISO(json.created_at);
        }

        if (json.created_by) {
            obj.createdBy = User.fromJson(json.created_by);
        }

        return obj;
    }

    /**
     * Returns an array of Familys from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<UserFeedback>
    {
        const array = new Array<UserFeedback>();

        json.forEach((item: any) => {
            const obj = UserFeedback.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(feedback: UserFeedback|null = null): string
    {
        if (feedback?.id == null) {
            return `/api/feedback`;
        } else {
            return `/api/feedback/${feedback.id}`;
        }

    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<UserFeedback>
     */
    public save(): Promise<UserFeedback>
    {
        this._isSaving = true;

        return new Promise<UserFeedback>((resolve, reject) => {
            if (this.id) {
                axios.put(UserFeedback.getRoute(this), this.toJson()).then(response => {
                    const obj = UserFeedback.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(UserFeedback.getRoute(), this.toJson()).then(response => {
                    const obj = UserFeedback.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<UserFeedback>
     */
    public delete(): Promise<UserFeedback>
    {
        this._isDeleting = true;
        return new Promise<UserFeedback>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(UserFeedback.getRoute(this)).then(response => {
                    const obj = UserFeedback.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isDeleting = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    /**
     * Copies the data from a UserFeedback into this instance
     * @param obj Account
     */
    public copyFrom(obj: UserFeedback)
    {
        this.id = obj.id;
        this.message = obj.message;
        this.resolvedAt = obj.resolvedAt;
        this.createdAt = obj.createdAt;
        this.createdBy = obj.createdBy;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    public toJson() : object
    {
        return {
            'id': this.id,
            'message': this.message,
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {

    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }
}

import { useAuthStore } from "@stores/authStore";
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from 'notifications/ApiEvent';
import NotificationCenter from 'notifications/NotificationCenter';
import { MoneyAccount, MoneyAccountType } from "./MoneyAccount";
import axios from "axios";

/**
 * A model representing the data for Account.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accouts
 */
export class Account implements ApiNotificationObserver
{
    public id: number;
    public accountName: string;
    public description: string;
    public accountType: string;
    public iconName: string;
    public currentBudgetAmount: Number;
    public currentAccountedAmount: Number;
    public balance: Number;

    /**
     * Creates a new empty model
     * @param observe boolean Wheter to add observers to the model or not
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    public observeEvents: ApiEvent[] = [
        ApiEvent.AccountUpdated,
        ApiEvent.AccountedResultChanged,
    ];

    /**
     * Creates a new Account model from json data
     * @param json An object holding the data to parse
     * @returns Account
     */
    static fromJson(json: any, observe: boolean = false): Account
    {
        const obj = new Account(observe);
        obj.id = json.id;
        obj.accountName = json.account_name;
        obj.description = json.description;
        obj.iconName = json.icon_name;
        obj.accountType = json.account_type;
        obj.currentBudgetAmount = Number(json.current_budget_amount);
        obj.currentAccountedAmount = Number(json.current_accounted_amount);
        obj.balance = Number(json.balance);

        return obj;
    }

    /**
     * Returns an array of Accounts from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<Account>
    {
        const array = new Array<Account>();

        json.forEach((item: any) => {
            const obj = Account.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param account Account the account to get url from
     * @returns string
     */
    static getRoute(): string
    {
        const familyId = useAuthStore().currentFamily.id;

        return `/api/families/${familyId}/accounts`;
    }

    /**
     * Copies the data from a Account into this instance
     * @param obj Account
     */
    public copyFrom(obj: Account)
    {
        this.id = obj.id;
        this.accountName = obj.accountName;
        this.description = obj.description;
        this.iconName = obj.iconName;
    }

    /**
     * Returns a copy of the AccountCategory
     */
    public copy(observe: boolean = false): Account
    {
        let copy = new Account(observe);
        copy.copyFrom(this);

        return copy;
    }

    public refreshBalance(): Promise<number>
    {
        return new Promise<number>((resolve, reject) => {
            if (this.id == null) {
                reject();
            }
            let account = new MoneyAccount();
            account.id = this.id;
            let url = MoneyAccount.getRoute(account);
            axios.get(url).then(result => {
                this.balance = Number(result.data.balance);
                resolve(result.data.balance);
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        const authStore = useAuthStore();
        const currentPeriod = authStore.currentPeriod;

        if (notification == ApiEvent.AccountUpdated && data?.account.id == this.id) {
            let newAccount = Account.fromJson(data.account);
            this.copyFrom(newAccount);
        }

        if (notification == ApiEvent.AccountedResultChanged && currentPeriod.month == data.month && currentPeriod.year == data.year) {
            let account = data.accounts.find((account) => account.id == this.id);
            if (account) {
                this.balance = account.balance;
                this.currentAccountedAmount = account.current_accounted_amount;
                this.currentBudgetAmount = account.current_budget_amount;
            }
        }
    }

    public registerObservers() {
        NotificationCenter.shared().registerObserver(this);
    }
}


<template>
    <v-skeleton-loader
        v-for="index in 3"
        :key="index"
        class=""
        type="list-item-avatar-three-line, divider"
        :loading="props.loading"
    >
        <template></template>
    </v-skeleton-loader>
    <empty-state
        v-if="!props.loading && props.vouchers.length == 0"
        icon="mdi-file-document-plus"
        :title="t('no-vouchers')"
        :action-button-text="t('add-voucher')"
        @actionButtonClick="voucherDialog.openNewVoucher()"
    >
    </empty-state>
    <v-list v-if="!props.loading && props.vouchers.length > 0">
        <v-list-item
            v-for="voucher in props.vouchers"
            :key="voucher.id"
            :title="voucher.description"
            :subtitle="voucher.createdBy?.firstName + ' - ' + d(voucher.date.toJSDate(), 'shortWithWeekday')"
            :class="mobile ? 'pa-0' : ''"
            @click.prevent="openVoucher(voucher)"
        >
        <template v-slot:prepend>
            <div class="text-center pr-2" :style="mobile ? 'width: 90px;' : 'width: 120px'">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-icon :size="mobile ? 'large' : 'x-large'">{{voucher.iconName}}</v-icon>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-chip
                            class="mt-auto mb-auto"
                            :color="voucher.voucherType == 'INCOME' ? 'success' : voucher.voucherType == 'EXPENSE' ? 'error' : 'accent'"
                            variant="outlined"
                            :size="mobile ? 'small' : 'default'"
                            density="compact"
                        >
                            {{ $n(voucher.amount, 'currency', currency) }}
                        </v-chip>

                    </v-col>
                </v-row>

            </div>
        </template>
        <template v-slot:append>
            <v-icon size="x-large">mdi-chevron-right</v-icon>
        </template>
        </v-list-item>
    </v-list>
    <voucher-dialog ref="voucherDialog" />
</template>

<script setup lang="ts">
    import { Voucher } from '../../../models/Voucher';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '../../../stores/authStore';
    import { computed, ref } from 'vue';
    import { useDisplay } from 'vuetify';
    import VoucherDialog from '/resources/js/pages/accounting/dialogs/VoucherDialog.vue';

    const authStore = useAuthStore();
    const currency = computed(() => authStore.currentFamily?.currency);
    const { t, n, d } = useI18n();
    const { mobile } = useDisplay();

    const props = defineProps({
        vouchers: {
            type: [Array<Voucher>],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
            default: false,
        }
    });

    const components = [
        VoucherDialog,
    ];

    const voucherDialog = ref(null);

    function openVoucher(voucher: Voucher)
    {
        voucherDialog.value.openVoucher(voucher);
    }

</script>

import axios from "axios";
import { defineStore } from "pinia";
import router from '../router';
import { useFamilyStore } from "./familyStore";
import { getActivePinia } from "pinia";
import { User } from '../models/User';
import { Ref, ref } from 'vue';
import { Family } from '../models/Family';
import { DateTime } from 'luxon';
import NotificationCenter from '../notifications/NotificationCenter';
import * as Sentry from "@sentry/vue";
import { FamilyRole } from "../types/FamilyRole";
import { useCalculationStore } from './calculationStore';
import { useBudgetStore } from './budgetStore';
import { useMoneyStore } from './moneyStore';

export const useAuthStore = defineStore('AuthStore', () => {
    let user: Ref<User|null> = ref(null);
    let currentFamily: Ref<Family|null> = ref(null);
    let currentPeriod: Ref<{month: number|null, year: number|null}> = ref({month: null, year: null});
    let darkMode: Ref<boolean|null> = ref(null);
    let _isLoggedIn: boolean = false;
    let _isVerified: boolean = false;

    const familyStore = useFamilyStore();

    function getBearerToken(): string|null
    {
        let token = localStorage.getItem('token');

        if (token) {
            return token;
        }

        return null;
    }

    function getMe(): Promise<User>
    {
        return new Promise<User>((resolve, reject) => {
            isLoggedIn().then((loginStatus: boolean) => {
                if (loginStatus) {
                    User.getMe().then((me: User) => {
                        user.value = me;
                        Sentry.setUser({
                            id: user.value.id,
                            username: user.value.fullName,
                            email: user.value.email
                        });
                        familyStore.families = user.value.families;
                        user.value.getUserSettings().then(() => {
                            user.value.getNotificationSettings().then(() => {
                                resolve(user.value);
                            })
                        })
                    }).catch(error => {
                        reject(error);
                    })
                } else {
                    reject(null)
                }
            })
        })
    }

    function collectDeviceInfo()
    {
        const getBrowserName = () => {
            let browserInfo = navigator.userAgent;
            let browser;
            if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
              browser = 'Opera';
            } else if (browserInfo.includes('Edg')) {
              browser = 'Edge';
            } else if (browserInfo.includes('Chrome')) {
              browser = 'Chrome';
            } else if (browserInfo.includes('Safari')) {
              browser = 'Safari';
            } else if (browserInfo.includes('Firefox')) {
              browser = 'Firefox'
            } else if (browserInfo.includes('WKWebView') || browserInfo.includes('AppleWebKit')) {
                browser = 'Factum ios app'
            } else {
              browser = browserInfo;
            }
              return browser;
          }

          const getOSName = () => {
            let info = navigator.platform;
            let os;
            if (info.includes('Mac')) {
              os = 'MacOS';
            } else if (info.includes('Win')) {
              os = 'Windows';
            } else if (info.includes('iPhone')) {
              os = 'iPhone';
            } else if (info.includes('iPad')) {
                os = 'iPad';
              }else {
              os = info;
            }
              return os;
          }

          const getIsMobile = () => {
            if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
                return true;
            } else {
                return false;
            }
          }

        return {
            'device_os': getOSName(),
            'is_mobile': getIsMobile(),
            'user_agent': getBrowserName(),
        }
    }

    function postDeviceInfo()
    {
        if (user.value == null) {
            return;
        }
        axios.post(`/api/users/${user.value.id}/devices`, collectDeviceInfo()).then(() => {
        }).catch(error => {
            console.error(error);
        })
    }

    function login(formData): Promise<any>
    {
        return new Promise (async (resolve, reject) => {
            const config = {
                headers: {
                    accept: '*/*',
                    Authorization: null,
                }
            };
            localStorage.removeItem('token');
            let payload = {
                ...formData,
                device: collectDeviceInfo(),
            }
            axios.post('/login', payload, config).then((response: any) => {
                user.value = User.fromJson(response.data.user, true);

                const token = response.data.access_token;
                localStorage.setItem('token', token);
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;

                this.isVerified().then(verified => {
                    if (verified == false) {
                        router.push('/must-verify-email');
                        resolve({
                            success: true,
                            error_code: "",
                        })
                    } else {
                        familyStore.getFamilies().then(() => {
                            initCurrentFamily();
                            resolve({
                                success: true,
                                error_code: ""
                            });
                        }).catch(error => {
                            console.error(error);
                            reject(error);
                        })
                    }
                })

            })
            .catch(error => {
                let code = "error";
                if (error.response.data?.disabled_at) {
                    code = "disabled";
                }
                if (error.response.status == 422) {
                    code = "credentials"
                }
                resolve({
                    success: false,
                    error_code: code,
                })
            });
        })
    }

    function logout(): Promise<boolean>
    {
        return new Promise<any>((resolve, reject) => {
            axios.post('/logout').then((response: any) => {
                resetAllStores();
                axios.defaults.headers['Authorization'] = null;
                localStorage.removeItem('token');
                router.push('/login-form')
                _isLoggedIn = false;
                _isVerified = false;
                resolve(true)
            }).catch(error => {
                axios.defaults.headers['Authorization'] = null;
                localStorage.removeItem('token');
                console.error(error);
                router.push('/login-form')
                resolve(false)
            })
        })
    }

    function forgotPassword(data: any): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            axios.post('/forgot-password', data).then((response: any) => {
                resolve({success: true})
            })
            .catch(err => {
                console.error(err);
                resolve({
                    success: false,
                    error: err
                })
            })
        });
    }

    function submitPassword(data): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            axios.post('/reset-password', data).then((response: any) => {
                resolve({success: true})
            })
            .catch(err => {
                console.error(err);
                resolve({success: false, error_code: err.code})
            })
        });
    }

    function resendEmailConfirmation(): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            axios.post('/email/verification-notification').then(response => {
                resolve({success: true})
            })
            .catch(err => {
                reject(err);
            })
        });
    }

    function setCurrentFamily(family: Family)
    {
        currentFamily.value = family;
        family.getMemberships();
        fetchRequiredData();
        NotificationCenter.shared().reconnect();
        const budgetStore = useBudgetStore();
        budgetStore.selectedCategory = null;
    }

    function fetchRequiredData() {

        if (hasUser() == false || hasFamily() == false) {
            return;
        }
        //Fetching nescesarry data
        const calculationStore = useCalculationStore();
        const budgetStore = useBudgetStore();
        const moneyStore = useMoneyStore();

        calculationStore.getTotals();
        calculationStore.getLiquidityRatio();
        calculationStore.getOverconsumptionChartData();
        budgetStore.getCategories();
        budgetStore.getAllAccounts();
        budgetStore.getAccountedResultChartData();
        budgetStore.getBudgetForecastChartData();
        budgetStore.getBudgetExpensesPieChartData();
        budgetStore.getUpcomingEvents();
        moneyStore.getAccounts();
    }

    function initCurrentFamily() {
        if (user.value == null) {
            return;
        }
        const localStorageId = Number(localStorage.getItem(user.value.id + '_current_family_id'));
        const familyStore = useFamilyStore();

        if (familyStore.families.length < 1) {
            router.push('/no-families')
        }

        if (localStorageId) {
            const family = familyStore.families.find((obj: Family) => obj.id == localStorageId);
            if (family) {
                setCurrentFamily(family);
            }
        }

        if (currentFamily.value == undefined || currentFamily.value == null) {
            if (familyStore.families[0]) {
                setCurrentFamily(familyStore.families[0]);
            }
        }

        if (currentFamily.value == undefined) {
            return;
        }
    }

    function setCurrentPeriod(month: number, year: number) {
        localStorage.setItem(user.value.id + '_current_period_month', month.toString());
        localStorage.setItem(user.value.id + '_current_period_year', year.toString());

        currentPeriod.value.month = month;
        currentPeriod.value.year = year;

        fetchRequiredData();
    }

    function initCurrentPeriod() {
        if (user.value == null) {
            return;
        }
        let month = Number(localStorage.getItem(user.value.id + '_current_period_month'));
        let year = Number(localStorage.getItem(user.value.id + '_current_period_year'));

        if (!month || !year) {
            month = Number(DateTime.now().toFormat('MM'));
            year = Number(DateTime.now().toFormat('yyyy'));
        }

        currentPeriod.value.month = month;
        currentPeriod.value.year = year;
    }

    function resetAllStores() {
        router.go(0);
        //getActivePinia()._s.forEach(store => store.$reset());
    }

    function isLoggedIn(force: boolean = false): Promise<boolean>
    {
        return new Promise<boolean>((resolve, reject) => {
            if (_isLoggedIn == false || force) {
                axios.get('/auth/check').then((response: any) => {
                    let status = response.data.auth as boolean;
                    _isLoggedIn = status;
                    resolve(status);
                })
                .catch((error: any) => {
                    console.error(error);
                    logout();
                    reject(error)
                })
            } else {
                resolve(_isLoggedIn);
            }
        })
    }

    function isVerified(): Promise<boolean>
    {
        return new Promise<boolean>((resolve, reject) => {
            if (_isVerified == false) {
                axios.get('/auth/verified').then((response: any) => {
                    let verified = response.data.verified as boolean;
                    _isVerified = verified;
                    resolve(verified);
                })
                .catch((error: any) => {
                    console.error(error);
                    reject(error);
                })
            } else {
                resolve(_isVerified);
            }
        })
    }

    function getQueryParams(): {params: {month: number, year: number}}
    {
        if (currentPeriod.value.month == null || currentPeriod.value.year == null) {
            initCurrentPeriod();
        }
        return {
            params: {
                ...currentPeriod.value,
            }
        }
    }
    function getFirstOfMonthInCurrentPeriod(): DateTime
    {
        if (currentPeriod.value.month < 10) {
            return DateTime.fromISO(currentPeriod.value.year + '-0' + currentPeriod.value.month + '-01');
        }

        return DateTime.fromISO(currentPeriod.value.year + '-' + currentPeriod.value.month + '-01');
    }

    function currentRole(): FamilyRole|null
    {
        let family = currentFamily.value;

        if (family == null || family == undefined) {
            return null;
        }

        let memberships = family.memberships;

        let membership = memberships.find(membership => {
            return membership.user.id == user.value.id;
        });

        return membership.role;
    }

    function shouldShowWizard() {
        if (currentFamily.value == null || currentFamily.value == undefined) {
            return false;
        }
        return currentFamily.value?.setupCompletedAt == null;
    }

    function hasUser() : boolean {
        return user.value?.id !== undefined && user.value?.id !== null;
    }

    function hasFamily() : boolean {
        return currentFamily.value?.id !== undefined && currentFamily.value?.id !== null;
    }


    return {
        user,
        currentFamily,
        currentPeriod,
        currentRole,
        darkMode,

        getBearerToken,
        getMe,
        login,
        logout,
        forgotPassword,
        submitPassword,
        resendEmailConfirmation,
        setCurrentFamily,
        initCurrentFamily,
        setCurrentPeriod,
        initCurrentPeriod,
        resetAllStores,
        isLoggedIn,
        isVerified,
        getQueryParams,
        getFirstOfMonthInCurrentPeriod,
        shouldShowWizard,
        postDeviceInfo,
        fetchRequiredData,
        hasUser,
        hasFamily,
    }
})

import axios from "axios";
import { defineStore
 } from "pinia";
import { useAuthStore } from "./authStore";
import { MoneyAccount } from "../models/MoneyAccount";
export const useMoneyStore = defineStore('MoneyStore', {
    state: () => {
        return {
            accounts: [],
            isLoadingAccounts: false,
        }
    },
    actions: {
        async getAccounts(): Promise<Array<MoneyAccount>> {
            this.isLoadingAccounts = true;

            return new Promise((resolve, reject) => {
                this.isLoadingAccounts = true;
                let authStore = useAuthStore();
                let familyId = authStore.currentFamily?.id;

                if (familyId === null || familyId == undefined) {
                    this.isLoadingAccounts = false;
                    reject();
                }

                axios.get(MoneyAccount.getRoute())
                    .then(result => {
                        let accounts = MoneyAccount.arrayFromJson(result.data, true);
                        this.accounts = accounts;
                        this.isLoadingAccounts = false;
                        resolve(accounts);
                    })
                    .catch(err => {
                        console.error(err);
                        this.isLoadingAccounts = false;
                        reject(err);
                    })
            });
        },

        addAccount(account: MoneyAccount) {
            this.isLoadingAccounts = true;
            const index = this.accounts.findIndex((obj) => obj.id === account.id);

            if (index == -1) {
                this.accounts.push(account);
            } else {
                this.accounts[index].copyFrom(account);
            }

            this.isLoadingAccounts = false;
        },

        removeAccount(account: MoneyAccount) {
            this.isLoadingAccounts = true;

            const index = this.accounts.findIndex((obj) => obj.id === account.id);

            if (index > -1) {
                this.accounts.splice(index, 1);
            }

            this.isLoadingAccounts = false;
        },
    },
});

<template>
    <v-snackbar
        v-model="isEmailVerified"
        color="success"
    >
        {{ $t("email-verified") }}. {{ $t("you-can-now-log-in") }}
    </v-snackbar>
    <v-snackbar
        v-model="createUserDialogData.showConfirmation"
        color="success"
        timeout="-1"
    >
        {{ $t("account-created") }}. {{ $t("check-your-inbox-to-continue!") }}
    </v-snackbar>
    <v-snackbar
        v-model="forgotPasswordDialogData.isSendt"
        color="success"
    >
        {{ $t("sendt") }}. {{ $t("check-your-inbox-to-continue!") }}
    </v-snackbar>

    <v-row no-gutters class="flex-nowrap" style="height: 100%">
        <v-card
            elevation="20"
            class="form-container pa-12 flex-grow-1 flex-md-grow-0 rounded-0 justify-space-between flex-column"
        >
            <v-row justify="center">
                <v-img src="./images/icon_full.png" class="app-icon" />
            </v-row>
            <h1 class="text-h4 text-center pt-12 mb-10">
                {{ $t("sign-in") }}
            </h1>

            <v-form ref="form" validate-on="blur" @submit.prevent="attemptLogin">
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="username"
                            :label="$t('email')"
                            prepend-inner-icon="mdi-account"
                            outlined
                            single-line
                            inputmode="email"
                            type="email"
                            :rules="[rules.required, rules.email]"
                            @keydown.enter="attemptLogin"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="password"
                            type="password"
                            :label="$t('password')"
                            prepend-inner-icon="mdi-key"
                            outlined
                            single-line
                            :rules="[rules.required]"
                            @keydown.enter="attemptLogin"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-alert
                            v-if="error"
                            type="error"
                            :text="loginErrorMessage"
                            density="compact"
                        />
                    </v-col>
                </v-row>
                <v-checkbox
                    class="mt-0 pt-0"
                    v-model="remember"
                    :label="$t('keep-me-logged-in')"
                />
                <v-row>
                    <v-btn
                        type="submit"
                        color="primary"
                        :block="mobile"
                        class="ma-auto w-100"
                    >
                        {{ $t("sign-in") }}
                    </v-btn>
                </v-row>
                <v-row>
                    <v-btn
                        class="text-none ma-auto mt-5 w-100"
                        variant="text"
                        :block="mobile"
                        @click="forgotPasswordDialogData.open = true"
                    >
                        {{ $t("forgot-password") }}
                    </v-btn>
                </v-row>
                <v-row>
                    <v-btn
                        variant="text"
                        class="text-none ma-auto"
                        block
                        @click="createUserDialogData.open = true"
                    >
                        {{ $t("create-your-factum-account") }}
                    </v-btn>
                </v-row>
            </v-form>
        </v-card>
        <v-card
            id="right"
            dark
            class="d-none d-md-flex flex-grow-1 flex-column justify-center align-center rounded-0 primary background-image"
        >
            <slot name="side"> </slot>
        </v-card>
    </v-row>
    <forgot-password-dialog
        :open="forgotPasswordDialogData.open"
        :email="forgotPasswordDialogData.email"
        :hasError="forgotPasswordDialogData.hasError"
        :errorMessage="forgotPasswordDialogData.errorMessage"
        :data="forgotPasswordDialogData.data"
        @cancel="closeForgotPasswordDialog"
        @reset-password="doResetPassword"
    />
    <create-user-dialog
        :open="createUserDialogData.open"
        :data="createUserDialogData.user"
        :errors="createUserDialogData.errors"
        @cancel="closeCreateUserDialog"
        @register="doRegister"
    />
    <loader v-if="showLoader" :text="loaderText" />
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import forgotPasswordDialog from "./components/ResetPasswordDialog.vue";
import createUserDialog from "./components/CreateUserDialog.vue";
import loader from "../components/Loader.vue";
import { useAuthStore } from "@stores/authStore";
import { User } from '../../models/User';
import { Ref } from 'vue';
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed } from "vue";

const { t } = useI18n();
const authStore = useAuthStore();
const router = useRouter();

const username: Ref<string> = ref("");
const password: Ref<string> = ref("");
const remember: Ref<boolean> = ref(true);
const error: Ref<string> = ref("");
const showLoader: Ref<boolean> = ref(false);
const loaderText: Ref<string> = ref("");
const forgotPasswordDialogData = ref({
    open: false,
    data: {
        email: "",
    },
    email: "",
    isSendt: false,
    loading: false,
    hasError: false,
    errorMessage: "",
})

const createUserDialogData = ref({
    open: false,
    showConfirmation: false,
    errors: [],
    user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        country: "no",
        currency: "NOK",
        language: getBrowserLanguage(),
    },
})

const rules = ref({
    required: (value) => !!value || t("required"),
    counter: (value) => value?.length <= 20 || "Max 20 characters",
    email: (value) => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || t("invalid-e-mail");
    },
})

function getBrowserLanguage()
{
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (timezone === "Europe/Oslo") {
            return "nb";
        } else {
            return "en";
        }
}

const form = ref(null);

function attemptLogin() {
    form.value.validate().then((validForm) => {
        if (validForm.valid == false) {
            return;
        }

        loaderText.value = t("logging-in");
        showLoader.value = true;

        error.value = "";
        const loginParams = {
            email: username.value,
            password: password.value,
            remember: remember.value,
        };

        authStore.login(loginParams)
            .then((result) => {
                showLoader.value = false;
                if (result.success === true) {
                    router.push({ path: "/" });
                } else {
                    error.value = result.error_code;
                }
            }).catch((error) => {
                console.error(error);
                error.value = error.error_code;
                showLoader.value = false;
            });
    })
}

function doRegister() {
    loaderText.value = t('creating-account') + "...";
    showLoader.value = true;
    let user = User.fromJson(createUserDialogData.value.user);

    user.save().then((result) => {
            showLoader.value = false;
            createUserDialogData.value.showConfirmation = true;
            closeCreateUserDialog();
        })
        .catch((error) => {
            console.error(error);
            showLoader.value = false;
            const errors = error.response.data.errors;
            createUserDialogData.value.errors = errors;
        });
}

function doResetPassword() {
    authStore.forgotPassword({
        email: forgotPasswordDialogData.value.data.email,
    }).then((result) => {
        if (result.success == true) {
            forgotPasswordDialogData.value.isSendt = true;
            forgotPasswordDialogData.value.open = false;
        } else {
            let err = result.error;
            let code = err.code;
            let data = err.response.data;
            const needToWait =
                data.message == "Please wait before retrying.";
            if (code == "ERR_BAD_REQUEST" && needToWait) {
                forgotPasswordDialogData.value.hasError = true;
                forgotPasswordDialogData.value.errorMessage = t(
                    "you-need-to-wait-a-bit-before-you-can-do-this-again"
                );
            }
        }
    });
}

function closeForgotPasswordDialog() {
    forgotPasswordDialogData.value.open = false;
}

function closeCreateUserDialog() {
    createUserDialogData.value.open = false;
}

const { mobile } = useDisplay();

const loginErrorMessage = computed(() => {
    switch (error.value) {
        case "disabled":
            return t('your-account-is-disabled');
        case "credentials":
            return t("invalid-username-or-password");
        default:
            t('server-error');
    }
});

const isEmailVerified = computed(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailVerified = Boolean(urlParams.get("email-verified"));

    return emailVerified == true;
});

</script>

<style lang="scss" scoped>
.form-container {
    width: 437px;
}
.background-image {
    background: url("/images/background.jpg");
    background-size: cover;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom right, #d9ebee, #002f4b);
        opacity: 0.6;
    }
}

.app-icon {
    height: 150px;
    width: 150px;
}
</style>

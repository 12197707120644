
<template>
    <v-container fluid class="bg-main pa-0 pl-0 pr-0">
        <v-row no-gutters class="align-content-center">
            <v-col v-if="props.displayBack" cols="auto" class="align-content-center">
                <v-btn
                    color="main"
                    variant="flat"
                    @click.prevent="goBack()"
                    class="h-100 rounded-0"
                >
                    <v-icon size="large">mdi-chevron-left</v-icon>
                </v-btn>
            </v-col>
            <v-col class="align-content-center text-h6 pt-3 pb-3">
                <v-icon class="mr-2 ml-2" v-if="props.icon">{{ props.icon }}</v-icon>
                {{ props.title }}

            </v-col>
        </v-row>
        </v-container>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify/lib/framework.mjs';

const router = useRouter();
const { t } = useI18n();
const  theme  = useTheme();

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: "",
    },
    icon: {
        type: String,
        required: false,
        default: "",
    },
    displayBack: {
        type: Boolean,
        required: false,
        default: true,
    },
    routeBack: {
        type: [String, Object, null],
        required: false,
        default: null,
    }
});

function goBack()
{
    if (props.routeBack == null) {
        router.back();
    } else if (typeof(props.routeBack) == 'string') {
        router.push({name: props.routeBack});
    } else {
        router.push(props.routeBack);
    }
}

</script>

import { i18n } from '../../js/i18n.js'


export const Rules = {
    Required: v => !!v || i18n.global.t('required'),

    Email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) ||  i18n.global.t('invalid-e-mail')
    },

    FirstName: value => value.length >= 2 || i18n.global.t('invalid-first-name'),
    LastName: value => value.length >= 2 || i18n.global.t('invalid-last-name'),

    Password: value => {
        const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9]).{8,}$/;
        return pattern.test(value) || i18n.global.t('invalid-password');
    }
}



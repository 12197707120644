import axios from "axios";
import { useAuthStore } from "@stores/authStore";
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from 'notifications/ApiEvent';
import NotificationCenter from 'notifications/NotificationCenter';
import { BudgetAccount } from './BudgetAccount';

/**
 * A model representing the data for BudgetAccountCategory.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accounts/{account}/budgets
 */
export class BudgetAccountCategory implements ApiNotificationObserver
{

    public id: number|null = null;
    public familyId: number|null = null;
    public title: string|null = null;
    public description: string|null = null;
    public iconName: string|null = null;
    public currentBudgetAmount: number|null = null;
    public currentAccountedAmount: number|null = null;

    public _isLoadingAccounts: boolean = false;

    public accounts: Array<BudgetAccount> = [];

    private _isRefreshingNumbers: boolean = false;

    public observeEvents: ApiEvent[] = [
        ApiEvent.BudgetChanged,
        ApiEvent.AccountedResultChanged,
        ApiEvent.BudgetAccountCategoryUpdated,
    ];

    /**
     * Creates a new empty model
     * @param observe boolean Wheter to add observers to the model or not
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new BudgetAccountCategory model from json data
     * @param json An object holding the data to parse
     * @returns BudgetAccountCategory
     */
    static fromJson(json: any, observe: boolean = false): BudgetAccountCategory
    {
        const obj = new BudgetAccountCategory(observe);
        obj.id = json.id;
        obj.familyId = json.family_id;
        obj.title = json.title;
        obj.description = json.description;
        obj.iconName = json.icon_name;
        obj.currentBudgetAmount = json.current_budget_amount;
        obj.currentAccountedAmount = json.current_accounted_amount;

        return obj;
    }

    /**
     * Returns an array of BudgetAccountCategorys from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<BudgetAccountCategory>
    {
        const array = new Array<BudgetAccountCategory>();

        json.forEach((item: any) => {
            const obj = BudgetAccountCategory.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(categoryId: number|null = null): string
    {
        const familyId = useAuthStore().currentFamily.id;

        if (categoryId !== null) {
            return `/api/families/${familyId}/account_categories/${categoryId}`;
        }

        return `/api/families/${familyId}/account_categories`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<BudgetAccountCategory>
     */
    public save(): Promise<BudgetAccountCategory>
    {
        return new Promise<BudgetAccountCategory>((resolve, reject) => {
            if (this.id) {
                axios.put(BudgetAccountCategory.getRoute(this.id), this.toJson()).then(response => {
                    const obj = BudgetAccountCategory.fromJson(response.data);
                    Object.assign(this, obj);
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            } else {
                axios.post(BudgetAccountCategory.getRoute(), this.toJson()).then(response => {
                    const obj = BudgetAccountCategory.fromJson(response.data);
                    Object.assign(this, obj);
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<BudgetAccountCategory>
     */
    public delete(): Promise<BudgetAccountCategory>
    {
        return new Promise<BudgetAccountCategory>((resolve, reject) => {
            if (this.id == null) {
                resolve(this);
            } else {
                axios.delete(BudgetAccountCategory.getRoute(this.id)).then(response => {
                    const obj = BudgetAccountCategory.fromJson(response.data);
                    Object.assign(this, obj);
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        })
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    private toJson() : object
    {
        return {
            'id': this.id,
            'title': this.title,
            'description': this.description,
            'icon_name': this.iconName,
        };
    }

    /**
     * Copies information from a given object onto this instance
     * @param obj BudgetAccountCategory The category to copy information from
     */
    public copyFrom(obj: BudgetAccountCategory)
    {
        this.id = obj.id;
        this.title = obj.title;
        this.description = obj.description;
        this.iconName = obj.iconName;
        this.familyId = obj.familyId;
        this.currentAccountedAmount = obj.currentAccountedAmount;
        this.currentBudgetAmount = obj.currentBudgetAmount;
    }

    /**
     * Returns a copy of the BudgetAccountCategory
     */
    public copy(observe: boolean = false): BudgetAccountCategory
    {
        let copy = new BudgetAccountCategory(observe);
        copy.copyFrom(this);

        return copy;
    }

    public refreshNumbers(): Promise<any>
    {
        this._isRefreshingNumbers = true;

        return new Promise<number>((resolve, reject) => {
            if (!this.id) {
                this._isRefreshingNumbers = false;
                reject();
            }
            let url = BudgetAccountCategory.getRoute(this.id);

            axios.get(url).then(result => {
                this.currentBudgetAmount = result.data.current_budget_amount;
                this.currentAccountedAmount = result.data.current_accounted_amount;
                this._isRefreshingNumbers = false;
                resolve(result.data.balance);
            }).catch(error => {
                console.error(error);
                this._isRefreshingNumbers = false;
                reject(error);
            })
        })
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        const authStore = useAuthStore();
        const currentPeriod = authStore.currentPeriod;

        if (notification == ApiEvent.BudgetChanged && data?.category?.id == this.id) {
            let newCategory = BudgetAccountCategory.fromJson(data.category);
            this.copyFrom(newCategory);
        }

        if (notification == ApiEvent.AccountedResultChanged && currentPeriod.month == data.month && currentPeriod.year == data.year) {
            this.refreshNumbers();
        }

        if (notification == ApiEvent.BudgetAccountCategoryUpdated && data?.category.id == this.id) {
            let newCategory = BudgetAccountCategory.fromJson(data.category);
            this.copyFrom(newCategory);
        }
    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }

        /**
     * Fetches budgets from the API, returned in a promise.
     * The BudgetAccount object is also holding the values in the budgets-prop.
     * @returns Promise<Array<AccountBudget>>
     */
        public getAccounts(): Promise<Array<BudgetAccount>>
        {
            return new Promise<Array<BudgetAccount>>((resolve, reject) => {
                this._isLoadingAccounts = true;
                const authStore = useAuthStore();

                const url = BudgetAccount.getRouteForCategory(this);

                axios.get(url, authStore.getQueryParams()).then(response => {
                    let accounts = BudgetAccount.arrayFromJson(response.data, true)
                    this.accounts = accounts;
                    this._isLoadingAccounts = false;
                    resolve(accounts);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            })
        }

}

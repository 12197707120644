import axios from "axios";
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';
import { Family } from "./Family";
import { UserSetting } from "./UserSetting";
import { UserNotificationSetting } from './UserNotificationSetting';
import { UserNote } from "./UserNote";
import { UserDevice } from "./UserDevice";

/**
 * A model representing the data for User.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accounts/{account}/budgets
 */
export class User implements ApiNotificationObserver
{
    public id: number|null = null;
    public firstName: string|null = null;
    public lastName: string|null = null;
    public email: string|null = null;
    public country: string|null = 'NO';
    public language: string|null = 'nb';
    public currency: string|null = 'NOK';
    public profilePicture: Blob|null = null;
    public password: string|null = null;
    public passwordConfirmation: string|null = null;
    public activated: boolean|null = null;
    public createdAt: DateTime|null = null;
    public updatedAt: DateTime|null = null;
    public lastActivityAt: DateTime|null = null;
    public disabledAt: DateTime|null = null;
    public disableMessage: string|null = null;
    public disabledBy: User|null = null;
    public hasPush: boolean|null = null;

    public families: Array<Family> = new Array<Family>();
    public userSettings: UserSetting = new UserSetting();
    public notificationSettings: UserNotificationSetting = new UserNotificationSetting();
    public accessRights: Array<{id, access, revoked}> = [];
    public relatedUsers: Array<User> = [];
    public notes: Array<UserNote> = [];
    public devices: array<UserDevice> = [];

    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;
    private _isDisabeling: boolean = false;
    private _isEnabeling: boolean = false;
    private _isFetchingNotes: boolean = false;
    private _isFetchingDevices: boolean = false;

    public observeEvents: ApiEvent[] = [
        ApiEvent.UserUpdated,
    ];

    /**
     * Creates a new empty User object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Returns the currently logged in user
     */
    static getMe(): Promise<User>
    {
        return new Promise<User>((resolve, reject) => {
            axios.get('/api/me?include=families.memberships.user,families.invitations').then((result: any) => {
                let me = User.fromJson(result.data, true);
                resolve(me);
            }).catch((error: any) => {
                console.error(error);
                reject(error);
            })
        })
    }

    /**
     * Returns a promise holding the UserSettings for the selected user
     */
    public getUserSettings(): Promise<UserSetting>
    {
        return new Promise<UserSetting>((resolve, reject) => {
            axios.get(UserSetting.getRoute(this)).then((result: any) => {
                let settings = UserSetting.fromJson(result.data, true);
                this.userSettings = settings;
                resolve(settings);
            }).catch((error: any) => {
                console.error(error);
                reject(error);
            })
        })
    }

    /**
     * Returns a promise holding the UserSettings for the selected user
     */
    public getNotificationSettings(): Promise<UserNotificationSetting>
    {
        return new Promise<UserNotificationSetting>((resolve, reject) => {
            axios.get(UserNotificationSetting.getRoute(this)).then((result: any) => {
                let settings = UserNotificationSetting.fromJson(result.data, true);
                this.notificationSettings = settings;
                resolve(settings);
            }).catch((error: any) => {
                console.error(error);
                reject(error);
            })
        })
    }

     /**
     * Returns a promise holding the UserNotes for the selected user
     */
     public getNotes(): Promise<Array<UserNote>>
     {
         return new Promise<Array<UserNote>>((resolve, reject) => {
             axios.get(UserNote.getRoute(this)).then((result: any) => {
                 let notes = UserNote.arrayFromJson(result.data, true);
                 this.notes = notes;
                 resolve(notes);
             }).catch((error: any) => {
                 console.error(error);
                 reject(error);
             })
         })
     }

          /**
     * Returns a promise holding the UserNotes for the selected user
     */
          public getDevices(): Promise<Array<UserDevice>>
          {
              return new Promise<Array<UserDevice>>((resolve, reject) => {
                  axios.get(UserDevice.getRoute(this)).then((result: any) => {
                      let devices = UserDevice.arrayFromJson(result.data, true);
                      this.devices = devices;
                      resolve(devices);
                  }).catch((error: any) => {
                      console.error(error);
                      reject(error);
                  })
              })
          }


    /**
     * Creates a new User model from json data
     * @param json An object holding the data to parse
     * @returns User
     */
    static fromJson(json: any, observe: boolean = false): User
    {
        const obj = new User(observe);
        obj.id = json.id ?? null;
        obj.firstName = json.first_name;
        obj.lastName = json.last_name;
        obj.email = json.email;
        obj.country = json.country;
        obj.language = json.language;
        obj.currency = json.currency;
        obj.profilePicture = json.profile_picture;
        obj.password = json.password ?? null;
        obj.passwordConfirmation = json.password_confirmation ?? null;
        obj.activated = json.activated;

        if (json.created_at) {
            obj.createdAt = DateTime.fromISO(json.created_at);
        }

        if (json.updated_at) {
            obj.updatedAt = DateTime.fromISO(json.updated_at);
        }

        obj.hasPush = json.has_push;

        if (json.disabled_at) {
            obj.disabledAt = DateTime.fromISO(json.disabled_at);
        }

        obj.disableMessage = json.disable_message;

        if (json.disabled_by) {
            obj.disabledBy = User.fromJson(json.disabled_by);
        }

        if (json.last_activity_at) {
            obj.lastActivityAt = DateTime.fromISO(json.last_activity_at);
        }

        if (json.families) {
            let families = Family.arrayFromJson(json.families, true);
            obj.families = families;
        }

        if (json.access_rights) {
            obj.accessRights = json.access_rights;
        }

        if (json.related_users) {
            obj.relatedUsers = User.arrayFromJson(json.related_users, true);
        }

        return obj;
    }

    /**
     * Returns an array of Users from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<User>
    {
        const array = new Array<User>();

        json.forEach((item: any) => {
            const obj = User.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(user: User): string
    {
        if (user.id !== null) {
            return `/api/users/${user.id}`;
        }

        return `/register`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<User>
     */
    public save(): Promise<User>
    {
        this._isSaving = true;

        return new Promise<User>((resolve, reject) => {
            if (this.id) {
                axios.put(User.getRoute(this), this.toJson()).then(response => {
                    const obj = User.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(User.getRoute(this), this.toJson()).then(response => {
                    const obj = User.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<User>
     */
    public delete(): Promise<User>
    {
        this._isDeleting = true;
        return new Promise<User>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(User.getRoute(this)).then(response => {
                    const obj = User.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isDeleting = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    /**
     * Copies the data from a User into this instance
     * @param obj Account
     */
    public copyFrom(obj: User)
    {
        this.id = obj.id;
        this.firstName = obj.firstName;
        this.lastName = obj.lastName;
        this.email = obj.email;
        this.country = obj.country;
        this.language = obj.language;
        this.currency = obj.currency;
        this.profilePicture = obj.profilePicture;
        this.activated = obj.activated;
        this.createdAt = obj.createdAt;
        this.updatedAt = obj.updatedAt;
        this.lastActivityAt = obj.lastActivityAt;
        this.disabledAt = obj.disabledAt;
        this.disableMessage = obj.disableMessage;
        this.disabledBy = obj.disabledBy;
        this.hasPush = obj.hasPush;
        this.relatedUsers = obj.relatedUsers;
    }

    public copy(observe: boolean = false): User
    {
        let copy = new User(observe);
        copy.copyFrom(this);

        return copy;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    public toJson() : object
    {
        return {
            'id': this.id,
            'first_name': this.firstName,
            'last_name': this.lastName,
            'email': this.email,
            'country': this.country,
            'language': this.language,
            'currency': this.currency,
            'password': this.password,
            'password_confirmation': this.passwordConfirmation,
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    get fullName(): string
    {
        return this.firstName + " " + this.lastName;
    }

    get isSuperAdmin(): boolean
    {
        let isSuperAdmin = false;

        this.accessRights.forEach(access => {
            if (access.access == 'system_admin' && access.revoked == 0) {
                isSuperAdmin = true;
            }
        })

        return isSuperAdmin;
    }

    get initials(): string
    {
        let name = this.firstName + ' ' + this.lastName;
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initialsArray = [...name.matchAll(rgx)] || [];

        let initials = (
            (initialsArray.shift()?.[1] || '') + (initialsArray.pop()?.[1] || '')
        ).toUpperCase();

        return initials
    }

    get isDisabled(): boolean
    {
        return this.disabledAt !== null;
    }

    get isDisabeling(): boolean
    {
        return this._isDisabeling;
    }

    get isEnabeling(): boolean
    {
        return this._isEnabeling;
    }
    get isFetchingNotes(): boolean
    {
        return this._isFetchingNotes;
    }

    get hasRelatedUsers(): boolean
    {
        return this.relatedUsers.length > 0;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        if (notification == ApiEvent.UserUpdated && data.user?.id == this.id) {
            let newUser = User.fromJson(data.user);
            this.copyFrom(newUser);

            this.getProfilePicture();
        }
    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }

    public getProfilePicture(): Promise<Blob>
    {
        return new Promise((resolve, reject) => {
            if (! this.id) {
                reject();
            }

            axios.get(`/api/users/${this.id}/profile-picture`).then((result: any) => {
                this.profilePicture = result.data.profile_picture;
                resolve(this.profilePicture);
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        })
    }

    public postProfilePicture(data: Blob): Promise<boolean>
    {
        return new Promise<boolean>((resolve, reject) => {
            let body = new FormData()
            body.append('image', data);
            axios.post(`/api/users/${this.id}/profile-picture`, body).then((result: any) => {
                resolve(true)
            }).catch((error: any) => {
                console.error(error);
                reject(error);
            })
        })
    }

    public deleteProfilePicture(): Promise<boolean>
    {
        return new Promise<boolean>((resolve, reject) => {
            axios.delete(`/api/users/${this.id}/profile-picture`).then((result: any) => {
                this.profilePicture = null;
                resolve(true)
            }).catch((error: any) => {
                console.error(error);
                reject(error);
            })
        })
    }

    public resendVerificationEmail(): Promise<boolean>
    {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`/api/users/${this.id}/resend-verification-email`).then((result: any) => {
                resolve(true)
            }).catch((error: any) => {
                console.error(error);
                reject(error);
            })
        })
    }

    public disableUser(message: string): Promise<boolean>
    {
        this._isDisabeling = true;
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`/api/users/${this.id}/disable`, {disable_message: message}).then((result: any) => {
                let newUser = User.fromJson(result.data);
                this.copyFrom(newUser);
                this._isDisabeling = false;
                resolve(true)
            }).catch((error: any) => {
                this._isDisabeling = false;
                console.error(error);
                reject(error);
            })
        })
    }

    public enableUser(): Promise<boolean>
    {
        this._isEnabeling = true;
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`/api/users/${this.id}/enable`).then((result: any) => {
                let newUser = User.fromJson(result.data);
                this.copyFrom(newUser);
                this._isEnabeling = false;
                resolve(true)
            }).catch((error: any) => {
                this._isEnabeling = false;
                console.error(error);
                reject(error);
            })
        })
    }

}

import axios from "axios";
import { useAuthStore } from "@stores/authStore";
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from 'notifications/ApiEvent';
import { VoucherLine } from "./VoucherLine";
import NotificationCenter from 'notifications/NotificationCenter';
import { Ref, ref } from 'vue';
import { DateTime } from 'luxon';
import { User } from "./User";

/**
 * A model representing the data for Voucher.
 */
export class Voucher implements ApiNotificationObserver
{

    public id: number|null = null;
    public familyId: number|null = null;
    public projectId: number| null = null;
    public date: DateTime = DateTime.now();
    public projectName: string = "";
    public description: string = "";
    public amount: Ref<number> = ref(0);
    public voucherType: string;
    public iconName: string|null = null;

    public voucherLines: Array<VoucherLine> = new Array<VoucherLine>;
    public createdBy: User;
    public createdAt: string;
    public updatedBy: User;
    public updatedAt: string;

    public observeEvents: ApiEvent[] = [
        ApiEvent.VoucherUpdated,
    ];

    private _isSaving: boolean = false;


    /**
     * Creates a new empty model
     * @param observe boolean Wheter to add observers to the model or not
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new Voucher model from json data
     * @param json An object holding the data to parse
     * @returns Voucher
     */
    static fromJson(json: any, observe: boolean = false): Voucher
    {
        const obj = new Voucher(observe);
        obj.id = json.id;
        obj.familyId = json.family_id;
        obj.projectId = json.project_id;
        obj.date = DateTime.fromISO(json.date);
        obj.projectName = json.project_name;
        obj.description = json.description;
        obj.amount = json.amount;
        obj.voucherType = json.voucher_type;
        obj.iconName = json.voucher_icon_name;
        obj.voucherLines = VoucherLine.arrayFromJson(json.lines);
        obj.createdBy = User.fromJson(json.created_by);
        obj.createdAt = json.created_at;
        obj.updatedBy = User.fromJson(json.updated_by);
        obj.updatedAt = json.updated_at;

        obj.voucherLines = VoucherLine.arrayFromJson(json.lines);

        return obj;
    }

    /**
     * Returns an array of Vouchers from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<Voucher>
    {
        const array = new Array<Voucher>();

        json.forEach((item: any) => {
            const obj = Voucher.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the route for vouchers or a specific voucher if set
     * @param voucher Voucher|null the voucher to get route to
     * @returns string
     */
    static getRoute(voucher: Voucher|null = null): string
    {
        const familyId = useAuthStore().currentFamily.id;

        if (voucher?.id) {
            return `/api/families/${familyId}/vouchers/${voucher.id}`;
        }

        return `/api/families/${familyId}/vouchers`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<Voucher>
     */
    public save(): Promise<Voucher>
    {
        this._isSaving = true;
        const authStore = useAuthStore();
        return new Promise<Voucher>((resolve, reject) => {
            if (this.id) {
                axios.put(Voucher.getRoute(this), this.toJson(), authStore.getQueryParams()).then(response => {
                    const obj = Voucher.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(Voucher.getRoute(), this.toJson(), authStore.getQueryParams()).then(response => {
                    const obj = Voucher.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<Voucher>
     */
    public delete(): Promise<Voucher>
    {
        return new Promise<Voucher>((resolve, reject) => {
            if (this.id == null) {
                resolve(this);
            } else {
                axios.delete(Voucher.getRoute(this)).then(() => {
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        })
    }

    private linesToJsonArray(): Array<any>
    {
        let array: Array<any> = [];

        this.voucherLines.forEach((line: VoucherLine) => {
            let json = line.toJson();
            array.push(json);
        })

        return array;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    private toJson() : object
    {
        return {
            'id': this.id,
            'project_id': this.projectId,
            'date': this.date.toFormat('yyyy-MM-dd'),
            'description': this.description,
            'icon_name': this.iconName,
            'lines': this.linesToJsonArray(),
        };
    }

    public copy(observe: boolean = false): Voucher
    {
        let copy = new Voucher(observe);
        copy.copyFrom(this);

        return copy;
    }

    public copyFrom(obj: Voucher)
    {
        this.id = obj.id;
        this.familyId = obj.familyId;
        this.projectId = obj.projectId;
        this.date = obj.date;
        this.projectName = obj.projectName;
        this.description = obj.description;
        this.amount = obj.amount;
        this.voucherType = obj.voucherType;
        this.iconName = obj.iconName;
        this.voucherLines = obj.voucherLines;
        this.createdBy = obj.createdBy;
        this.createdAt = obj.createdAt;
        this.updatedBy = obj.updatedBy;
        this.updatedAt = obj.updatedAt;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        if (notification == ApiEvent.VoucherUpdated && data?.voucher.id == this.id) {
            let newVoucher = Voucher.fromJson(data.voucher);
            this.copyFrom(newVoucher);
        }
    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

}

<template>
    <v-container fluid>
        <v-row align="center">
            <v-col cols="12" class="d-flex align-center">
                <div>
                    <v-img src="./images/appikon.png" height="100" width="100"/>
                </div>
                <div>
                    <p class="text-h4">{{ t('get-started') }}</p>
                    <p class="text-subtitle-2">{{ t('spend-some-minutes-getting-your-new-factum-family-ready') }}</p>
                </div>
            </v-col>
        </v-row>
        <v-stepper
            v-model="stepNumber"
            :mobile="mobile"
            :prev-text="$t('back')"
            :next-text="$t('next')"
        >
            <template v-slot:default="{ prev, next }">
                <v-stepper-header :mobile="mobile">
                    <v-stepper-item
                        v-for="(step,n) in steps"
                        :key="n+1"
                        :value="n+1"
                        :complete="stepComplete(step)"
                        :color="stepStatus(step)"
                        complete-icon="mdi-check"
                        :title="step.title"
                        :disabled="step.disabled"
                    >
                    </v-stepper-item>
                </v-stepper-header>
                <!--General settings-->
                <v-stepper-window v-if="stepNumber == 1">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <p class="text-h6">{{ t('general-settings') }}</p>
                        </v-col>
                        <v-col cols="12">
                            <p class="text-body-2">{{ t('tell-us-where-your-family-is-located-and-which-currency-to-use') }}</p>
                        </v-col>
                    </v-row>
                    <v-form ref="settingsForm" v-model="settingsFormValid">
                        <v-row class="mt-2">
                            <v-col cols="12">
                                <v-select
                                    v-model="family.country"
                                    :items="countries"
                                    item-title="title"
                                    item-value="value"
                                    :label="$t('country')"
                                    :rules="[v => !!v || $t('required')]"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="family.currency"
                                    :items="currencies"
                                    item-title="title"
                                    item-value="value"
                                    :label="$t('currency')"
                                    :rules="[v => !!v || $t('required')]"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-stepper-window>
                <!--Family members-->
                <v-stepper-window v-if="stepNumber == 2">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <p class="text-h6">{{ t('family-members') }}</p>
                        </v-col>
                        <v-col cols="12">
                            <p class="text-body-2">{{ t('to-be-able-to-compare-you-to-other-families-we-need-to-know-a-little-about-who-your-family-is') }}</p>
                        </v-col>
                    </v-row>
                    <v-form ref="personsForm" v-model="personsFormValid">
                        <v-row class="mt-2">
                            <v-col>
                                <div
                                    v-for="person in family.persons"
                                    :key="person.id"
                                    class="mt-2"
                                >
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :label="$t('name')"
                                            v-model="person.name"
                                            :rules="[v => !!v || $t('required')]"
                                        />
                                    </v-col>
                                    <v-col cols="5" md="2">
                                        <v-text-field
                                            :label="$t('birth-year')"
                                            type="number"
                                            v-model="person.birthYear"
                                            :rules="[v => !!v || $t('required')]"
                                            inputmode="numeric"
                                        />
                                    </v-col>
                                    <v-col cols="5" md="2">
                                        <v-select
                                            :label="$t('gender')"
                                            :items="genders"
                                            item-value="gender"
                                            item-title="title"
                                            v-model="person.gender"
                                            :rules="[v => !!v || $t('required')]"
                                        />
                                    </v-col>
                                    <v-col cols="2" md="2" class="text-right">
                                        <v-btn
                                            color="error"
                                            icon="mdi-delete"
                                            variant="plain"
                                            @click.stop="removePerson(person)"
                                        >
                                        </v-btn>
                                    </v-col>
                                    <v-divider class="mb-4 mt-4" v-if="mobile"/>
                                </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="text-center">
                            <v-col cols="12">
                                <v-btn
                                    color="primary"
                                    icon="mdi-plus"
                                    variant="plain"
                                    @click.stop="addNewPerson()"
                                >

                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-stepper-window>
                <!--BUDGET-->
                <v-stepper-window v-if="stepNumber == 3">
                    <v-radio-group v-model="setup.setup_budget_type">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <p class="text-h6">{{ t('budget') }}</p>
                            </v-col>
                            <v-col cols="12">
                                <p class="text-body-2">{{ t('right-now-your-budget-is-blank') }}. {{ t('do-you-want-us-to-setup-a-default-budget-for-you') }}? {{ t('you-can-edit-this-later') }}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" align="center">
                            <v-col cols="6">
                                <v-radio :label="t('ordinary-budget')" value="ordinary"/>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <p class="text-subtitle-2">{{ t('includes-groceries-but-does-not-split-between-food-and-other-household-articles') }}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" align="center">
                            <v-col cols="6">
                                <v-radio :label="t('detailed-budget')" value="detailed"/>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <p class="text-subtitle-2">{{ t('splits-between-food-and-cleaning-items') }}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" align="center">
                            <v-col cols="6">
                                <v-radio :label="t('empty')" value="none"/>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <p class="text-subtitle-2">{{ t('start-fresh-and-build-your-own-budget') }}</p>
                            </v-col>
                        </v-row>
                    </v-radio-group>
                </v-stepper-window>
                <!--Other expenses-->
                <v-stepper-window v-if="stepNumber == 4">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <p class="text-h6">{{ t('other-expenses') }}</p>
                        </v-col>
                        <v-col cols="12">
                            <p class="text-body-2">{{ t('check-the-boxes-corresponding-to-the-expenses-your-family-has') }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" md="3">
                            <v-checkbox
                                v-model="setup.add_car"
                                :label="$t('car-expenses')"
                                color="success"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-checkbox
                                v-model="setup.add_car_loan"
                                :label="$t('car-loan')"
                                color="success"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-checkbox
                                v-model="setup.add_house_rent"
                                :label="$t('rent')"
                                color="success"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-checkbox
                                v-model="setup.add_mortgage"
                                :label="$t('mortgage')"
                                color="success"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-checkbox
                                v-model="setup.add_kids_expenses"
                                :label="$t('kids-expenses')"
                                color="success"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-checkbox
                                v-model="setup.add_kindergarden"
                                :label="$t('kindergarden')"
                                color="success"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-checkbox
                                v-model="setup.add_pets_expenses"
                                :label="$t('pets-expenses')"
                                color="success"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-checkbox
                                v-model="setup.add_tobacco"
                                :label="$t('tobacco')"
                                color="success"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-stepper-window>
                <!--Money accounts-->
                <v-stepper-window v-if="stepNumber == 5">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <p class="text-h6">{{ t('money-accounts') }}</p>
                        </v-col>
                        <v-col cols="12">
                            <p class="text-body-2">{{ t('add-your-money-accounts-with-todays-balance-to-let-us-show-you-how-your-money-develops-with-time') }}.</p>
                        </v-col>
                    </v-row>
                    <v-form ref="moneyAccountForm" v-model="moneyAccountFormValid">
                        <v-row>
                            <v-col>
                                <div
                                    v-for="account in moneyAccounts"
                                    :key="account.id"
                                    class="mt-2"
                                >
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :label="$t('name')"
                                            v-model="account.accountName"
                                            :rules="[v => !!v || $t('required')]"
                                        />
                                    </v-col>
                                    <v-col cols="10" md="3">
                                        <currency-text-field
                                            :label="$t('balance')"
                                            type="number"
                                            v-model="account.initialBalance"
                                            :rules="[v => !!v || $t('required')]"
                                            inputmode="decimal"
                                        />
                                    </v-col>
                                    <v-col cols="2" md="3" class="text-right">
                                        <v-btn
                                            color="error"
                                            variant="plain"
                                            icon="mdi-delete"
                                            @click.stop="removeBankAccount(account)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-divider class="mb-4 mt-4" v-if="mobile"/>
                                </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="text-center">
                            <v-col cols="12">
                                <v-btn
                                    icon="mdi-plus"
                                    variant="plain"
                                    color="primary"
                                    @click.stop="addMoneyAccount()"
                                >
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-stepper-window>
                <!--DONE-->
                <v-stepper-window v-if="stepNumber == 6">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <p class="text-h6 text-center">{{ t('thats-it')}}!</p>
                        </v-col>
                        <v-col cols="12">
                            <p class="text-center">{{ t('we-have-all-the-information-we-need') }}!</p>
                            <p class="text-center">{{ t('press-complete-to-start-the-installation') }}</p>
                        </v-col>
                    </v-row>
                    <v-row v-if="completionStarted == false" class="text-center">
                        <v-col>
                            <v-btn :block="mobile" variant="flat" color="primary" @click="complete">{{ t('complete') }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="completionStarted" align="center" justify="center" dense no-gutters>
                        <v-col cols="12" align="center">
                            <v-img src="./images/logo_animation.gif" max-width="300"/>
                        </v-col>
                        <v-col align="center">
                            <p class="text-h6">{{ statusMessage }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="text-center">{{ t('this-wizard-will-close-automatically-when-the-configuration-is-completed') }}. {{ t('this-might-take-a-while') }}</p>
                        </v-col>
                    </v-row>
                </v-stepper-window>

                <v-stepper-actions
                    :disabled="disableNextButton"
                    color="primary"
                    @click:prev="prev"
                    @click:next="next"
                />
            </template>
        </v-stepper>
    </v-container>


</template>

<script setup lang="ts">
import { ref, Ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { FamilyPerson } from '@models/FamilyPerson';
import { Gender } from '../../models/FamilyPerson';
import { useDisplay } from 'vuetify';
import { MoneyAccount, MoneyAccountType } from '../../models/MoneyAccount';
import { useAuthStore } from '../../stores/authStore';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useMoneyStore } from '../../stores/moneyStore';
import { countries, currencies } from '../../FactumLocale.ts'

const { t } = useI18n();

const setup = ref({
    setup_budget_type: "ordinary",
    add_car: false,
    add_car_loan: false,
    add_kids_expenses: false,
    add_pets_expenses: false,
    add_kindergarden: false,
    add_tobacco: false,
    add_house_rent: false,
    add_mortgage: false,
})
const { mobile } = useDisplay();
const steps = computed(() => [
    {
        title: t('general-settings'),
        icon: 'mdi-apple',
        value: '1',
        valid: true,
    },
    {
        title: t('family-members'),
        icon: 'mdi-apple',
        value: '2',
        valid: true,
        rules: [v => !!v || "Required."]
    },
    {
        title: t('budget'),
        icon: 'mdi-apple',
        value: '3',
        valid: true,
    },
    {
        title: t('other-expenses'),
        icon: 'mdi-apple',
        value: '4',
        valid: true,
        disabled: setup.value.setup_budget_type == 'none',
    },
    {
        title: t('money-accounts'),
        icon: 'mdi-apple',
        value: '5',
        valid: true,
    },
    {
        title: t('complete'),
        icon: 'mdi-apple',
        value: '6',
        valid: true,
    },
]);

const stepNumber = ref(1);

function stepComplete(step) {
    return step.value < stepNumber.value
}
function stepStatus(step) {
    return step.value < stepNumber.value ? 'green' : 'blue'
}

const settingsFormValid = ref(false);
const personsFormValid = ref(false);
const moneyAccountFormValid = ref(false);

function validate(n) {
    steps.value[n].valid = false;

    if (n == 1) {
        // General settings
        if (settingsFormValid.value) {
            steps.value[n].valid = true;
        }
    }

    if (n == 2) {
        //Family persons
        if (family.value.persons.length > 0) {
            if (personsFormValid.value) {
                steps.value[n].valid = true;
            }
        }
    }

    if (n == 3) {
        steps.value[n].valid = true;
    }

    if (n == 4) {
        steps.value[n].valid = true;
    }

    if (n == 5) {
        //Money accounts
        if (moneyAccountFormValid.value) {
            steps.value[n].valid = true;
        }
    }

    if (n == 6) {
        steps.value[n].valid = true;
    }

    return steps.value[n].valid
}

const disableNextButton = computed(() => {
    if (stepNumber.value == 1) {
        if (validate(stepNumber.value) == false) {
            //Both should be disabled
            return true
        }
        return 'prev'
    }

    if (stepNumber.value == 6) {
        return 'next';
    }

    if (validate(stepNumber.value) == false) {
        return 'next';
    }

    return undefined;
})

const moneyAccounts = ref(new Array<MoneyAccount>());

function addMoneyAccount()
{
    moneyAccounts.value.push(new MoneyAccount());
}

const genders = ref([
    {
        gender: Gender.MALE,
        title: t('male')
    },
    {
        gender: Gender.FEMALE,
        title: t('female')
    },
]);

const authStore = useAuthStore();

const family = ref(authStore.currentFamily);
const newPerson = ref(new FamilyPerson());

function addNewPerson()
{
    family.value.persons.push(newPerson.value);
    newPerson.value = new FamilyPerson();
}

function addUserAsPerson()
{
    if (family.value.persons.length < 1) {
        let userPerson = new FamilyPerson();
        userPerson.name = authStore.user.firstName;
        family.value.persons.push(userPerson);
    }
}

function removePerson(person: FamilyPerson)
{
    const index = family.value.persons.indexOf(person);

    if (index > -1) {
        family.value.persons.splice(index, 1);
    }
}

function removeBankAccount(account: MoneyAccount)
{
    const index = moneyAccounts.value.indexOf(account);

    if (index > -1) {
        moneyAccounts.value.splice(index, 1);
    }
}

const statusMessage = ref("Utfører endringer på familien")
const completionStarted = ref(false);

async function complete()
{
    completionStarted.value = true;

    family.value.save().then(() => {
        statusMessage.value = t('creating-persons')
        family.value.persons.forEach(async (person) => {
            person.family = family.value;
            await person.save();
        })

        statusMessage.value = t('creating-money-accounts')
        moneyAccounts.value.forEach(async (account) => {
            account.accountType = MoneyAccountType.Bank;
            await account.save();
        })

        statusMessage.value = t('creating-budget')
        axios.post('/api/families/' + family.value.id + '/setup', setup.value).then(() => {
            family.value.setupCompletedAt = DateTime.now();
            // Refreshing the window
            location.reload();
        }).catch(error => {
            console.error(error);
        })
    }).catch(error => {
        console.error(error);
        // Refreshing the window
        location.reload();
    })
}

family.value.getPersons().then(() => {
    addUserAsPerson();
})

let moneyStore = useMoneyStore();

moneyStore.getAccounts().then(accounts => {
    moneyAccounts.value = accounts;
})

</script>

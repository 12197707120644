export const FAIcons: Array<string>  = [
"mdi-human-male-female-child",
"mdi-football",
"mdi-cylinder",
"mdi-cat",
"mdi-lipstick",
"mdi-tools",
"mdi-apple",
"mdi-hair-dryer",
"mdi-cellphone",
"mdi-liquor",
"mdi-apple-icloud",
"mdi-speaker",
"mdi-tennis",
"mdi-parking",
"mdi-gift",
"mdi-bus",
"mdi-palm-tree",
"mdi-television",
"mdi-shield-account",
"mdi-gavel",
"mdi-bank",
"mdi-cloud",
"mdi-arrow-right",
"mdi-cow",
"mdi-party-popper",
"mdi-home",
"mdi-movie",
"mdi-newspaper",
"mdi-spotify",
"mdi-car-info",
"mdi-shield-car",
"mdi-basket",
"mdi-laptop",
"mdi-emoticon-cool-outline",
"mdi-home-lightning-bolt-outline",
"mdi-sofa",
"mdi-shield-airplane",
"mdi-credit-card",
"mdi-doctor",
"mdi-book",
"mdi-dropbox",
"mdi-piggy-bank",
"mdi-web",
"mdi-pasta",
"mdi-weight-lifter",
"mdi-tshirt-crew",
"mdi-shield",
"mdi-car-side",
"mdi-dog",
"mdi-dog-side",
"mdi-fish",
"mdi-fishbowl",
"mdi-sail-boat",
"mdi-ferry",
"mdi-faucet",
"mdi-countertop",
"mdi-fridge",
"mdi-food",
"mdi-food-apple",
"mdi-food-drumstick",
"mdi-hamburger",
"mdi-bicycle",
"mdi-motorbike",
"mdi-hammer-wrench",
"mdi-balloon",
"mdi-ticket",
"mdi-ring",
"mdi-string-lights",
"mdi-cross",
"mdi-baguette",
"mdi-delete",
"mdi-horse",
"mdi-dropbox",
"mdi-netflix",
"mdi-android",
"mdi-facebook",
"mdi-google-drive",
"mdi-airplane",
"mdi-train",
"mdi-flash",
"mdi-soccer",
"mdi-necklace",
"mdi-ev-station",
"mdi-spray-bottle",
"mdi-candy",
"mdi-invoice-text-clock-outline",
"mdi-invoice-outline",
"mdi-glasses",
"mdi-circle",
"mdi-controller",
"mdi-wifi",
"mdi-bed",
"mdi-hand-coin",
"mdi-passport",
]

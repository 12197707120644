
<template>
    <fa-dialog
        :open="open"
        :title="note?.id ? note.title : $t('new-note')"
        :fullscreen="mobile"
        :width="mobile ? '100%' : '1000px'"
        :height="mobile ? '100%' : '800px'"
        icon="mdi-note-edit-outline"
        @close="open = false"
    >
    <template v-slot:header-buttons>
        <v-row>
            <v-col>
                <v-btn
                    class="ml-2"
                    color="success"
                    variant="outlined"
                    @click="saveAndClose()"
                    size="small"
                    :disabled="formValid == false"
                    :loading="note.isSaving"
                >
                    {{ $t("save-and-close") }}
                </v-btn>
            </v-col>
            <v-col align="right">
                <v-btn
                    v-if="note.id"
                    class="ml-2"
                    color="error"
                    variant="outlined"
                    @click="doDelete()"
                    size="small"
                >
                    {{ $t("delete") }}
                </v-btn>
            </v-col>
        </v-row>

    </template>
    <template v-slot:main>
        <v-form v-model="formValid" ref="form">
            <v-row class="pt-3" dense>
                <v-col cols="12" md="9">
                    <v-text-field
                        v-model="note.title"
                        :label="$t('title')"
                        :rules="[Rules.Required]"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <date-text-field
                        v-model="note.date"
                        :label="$t('date')"
                    />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col>
                    <editor
                        height="auto"
                        v-model="note.note"
                         api-key="6uy05l71nlthne66hbtckk47znjyb3km66brm3mzsta7xmgj"
                        :init="{
                            height: '500',
                            plugins: 'lists link image table code'
                        }"
                        :mobile="{
                            menubar: false,
                            toolbarmode: 'floating'
                        }"
                    />
                </v-col>
            </v-row>
        </v-form>
    </template>

    </fa-dialog>
    <confirm-dialog ref="confirmDialog" />

</template>

<script setup lang="ts">
import { User } from '../../models/User';
import { UserNote } from '../../models/UserNote';
import { ref, Ref, computed } from 'vue';
import { Rules } from '../../types/ValidationRules';
import { useDisplay } from 'vuetify';
import Editor from '@tinymce/tinymce-vue';
import { useI18n } from 'vue-i18n';
import ConfirmDialog from '/resources/js/components/ConfirmDialog.vue';

const user: Ref<User> = ref(null);
const note: Ref<UserNote> = ref(null);
const open: Ref<boolean> = ref(false);
const formValid: Ref<boolean> = ref(false);
const form = ref(null);
const confirmDialog = ref(null);
const { t } = useI18n();

const { mobile } = useDisplay();

const props = defineProps({

})

const emits = defineEmits([
    'didSaveNewNote',
    'didSaveNote',
    'didDeleteNote',
])

defineExpose({
    openNewNote,
    openNote,

})

function openNewNote(_user: User)
{
    user.value = _user;
    note.value = new UserNote();
    note.value.user = _user;
    open.value = true;
}

function openNote(_note: UserNote)
{
    note.value = _note.copy(true);
    open.value = true;
}

function doDelete()
{
    confirmDialog.value
        .confirm(
            t("delete-note"),
            t("do-you-really-want-to-delete-this-note")
        )
        .then((confirmed) => {
            if (confirmed) {
                open.value=false;
                note.value.delete().then(() => {
                    emits("didDeleteNote", note.value);
                });
            }
        });
}

function saveAndClose()
{
    form.value.validate().then(result => {
        if (result.valid) {
            if (note.value.id) {
                note.value.save().then(result => {
                    emits('didSaveNote', result);
                }).catch(error => {
                    console.error(error);
                })

            } else {
                note.value.save().then(result => {
                    emits('didSaveNewNote', result);
                }).catch(error => {
                    console.error(error);
                })
            }
            open.value = false;
        }
    })

}

</script>

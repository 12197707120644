import { computed } from 'vue'
import { useI18n } from 'vue-i18n';
import { i18n } from './i18n.js'

    const { t } = i18n.global;
    export const countries: [] = computed(() => [
        {title: t('norway'), value: 'NO'},
        {title: t('sweden'), value: 'SE'},
    ]);

    export const currencies: [] = computed(() => [
        {title: t('norwegian-krone') + ' (NOK)', value: 'NOK'},
        {title: t('swedish-krone') + ' (SEK)', value: 'SEK'},
        {title: t('euro') + ' (€)', value: 'EUR'},
        {title: t('united-states-dollar') + ' ($)', value: 'USD'},
    ]);


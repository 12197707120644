<template>
    <fa-dialog
        :open="props.open"
        :title="$t('create-account')"
        :height="mobile? '100%' : 550"
        :width="mobile? '100%' : 500"
        icon="mdi-account-plus"
        :fullscreen="mobile"
        @close="$emit('cancel')"
    >
        <template v-slot:main>
            <v-row>
                <v-col class="text-center">
                    <span class="text-center">{{ $t('creating-an-account-is-100-percent-free') }} &nbsp</span>
                </v-col>
            </v-row>
            <v-form autocomplete="on" v-model="validForm" class="pt-3">
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="props.data.first_name"
                            type="text"
                            :label="$t('first-name')"
                            :rules="[Rules.FirstName]"
                            :error-messages="errors.first_name"
                            autocomplete="given-name"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="props.data.last_name"
                            type="text"
                            :label="$t('last-name')"
                            :rules="[Rules.LastName]"
                            :error-messages="errors.last_name"
                            autocomplete="family-name"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="props.data.email"
                            type="email"
                            :label="$t('email')"
                            :rules="[Rules.Email]"
                            :error-messages="errors.email"
                            autocomplete="email"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="props.data.password"
                            type="password"
                            :label="$t('password')"
                            :error-messages="errors.password"
                            :rules="[
                                Rules.Password,
                            ]"
                            autocomplete="new-password"
                        />
                    </v-col>
                </v-row>
            </v-form>
            <v-row class="mt-3 mb-5">
                <v-col>
                    <v-btn
                        variant="flat"
                        color="primary"
                        :loading="loading"
                        :disabled="!validForm"
                        :block="mobile"
                        @click="$emit('register')"
                    >
                        {{ $t('create-account') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
</fa-dialog>
</template>
<script setup lang="ts">

import { useDisplay } from 'vuetify';
import { Rules } from '../../../types/ValidationRules';
import { computed, ref } from 'vue';

const props = defineProps({
    open: {
        default: false,
        type: Boolean,
    },
    data: {
        default: {
            "first_name": "",
            "last_name": "",
            "email": "",
            "password": "",
            "password_confirmation": "",
        },
        type: Object,
    },
    value: {
        default: '',
        type: String,
    },
    loading: {
        default: false,
        type: Boolean,
    },
    errors: {
        default: () => {},
        type: Object,
    },
})

const { mobile } = useDisplay();

const validForm = ref(false);

</script>


<template v-slot:activator="{ on, attrs }">
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-btn
                v-bind="props"
            >
            <v-icon
                size="24"
                :icon="haveSelectedNow ? 'mdi-calendar' : 'mdi-calendar-alert'"
                :color="haveSelectedNow ? 'default' : 'warning'"
            />
                <span :class="haveSelectedNow ? 'ml-3' : 'ml-3 text-warning'">
                    {{ monthName(currentPeriod.month) + ' ' + currentPeriod.year }}
                </span>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-center">
                <v-icon>mdi-calendar</v-icon>
                <p>{{ $t('select-period') }}</p>
            </v-card-title>
            <v-card-subtitle class="text-center">
                <p>{{ $t('this-affects-many-parts-of-the-system') }}</p>
            </v-card-subtitle>
            <v-list>
                <v-list-item>
                    <v-list-item-title
                        class="text-center"
                    >
                        {{ currentPeriod.year }}
                    </v-list-item-title>
                    <template v-slot:prepend>
                        <v-btn
                            icon="mdi-chevron-left"
                            variant="flat"
                            @click.stop="setYear(Number(currentPeriod.year) - 1)"
                        />
                    </template>
                    <template v-slot:append>
                        <v-btn
                            icon="mdi-chevron-right"
                            variant="flat"
                            @click.stop="setYear(Number(currentPeriod.year) + 1)"
                        />
                    </template>
                </v-list-item>
                <v-list-item
                    v-for="month in months"
                    :key="month.value"
                    density="compact"
                    @click="setMonth(month.value)"
                    :class="currentPeriod.month == month.value ? 'bg-success' : null"
                >
                    <v-list-item-title class="text-subtitle-2 text-center">
                        {{ month.name }}
                    </v-list-item-title>

                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>


<script setup lang="ts">
import { useDisplay } from 'vuetify';
import { useAuthStore } from '@stores/authStore';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { DateTime } from 'luxon';

const { t } = useI18n();

const months = [
    {
        value: 1,
        name: t('january')
    },
    {
        value: 2,
        name: t('february')
    },
    {
        value: 3,
        name: t('march')
    },
    {
        value: 4,
        name: t('april')
    },
    {
        value: 5,
        name: t('may')
    },
    {
        value: 6,
        name: t('june')
    },
    {
        value: 7,
        name: t('july')
    },
    {
        value: 8,
        name: t('august')
    },
    {
        value: 9,
        name: t('september')
    },
    {
        value: 10,
        name: t('october')
    },
    {
        value: 11,
        name: t('november')
    },
    {
        value: 12,
        name: t('december')
    },
];
const authStore = useAuthStore();

const currentPeriod = computed(() => authStore.currentPeriod);

const haveSelectedNow = computed(() => {
    let month = DateTime.now().month;
    let year = DateTime.now().year;

    return currentPeriod.value.year == year && currentPeriod.value.month == month;
})
function monthName(value) {
    return months[value - 1]?.name;
}

function setMonth(month) {
    authStore.setCurrentPeriod(month, currentPeriod.value.year);
}

function setYear(year) {
    authStore.setCurrentPeriod(currentPeriod.value.month, year);
}
</script>

import axios from "axios";
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';
import { useAuthStore } from '../stores/authStore';
import { User } from "./User";
import { Family } from './Family';
import { FamilyRole } from '../types/FamilyRole';
import { useFamilyStore } from "@stores/familyStore";

/**
 * A model representing the data for FamilyInvitation.
 */
export class FamilyInvitation implements ApiNotificationObserver
{
    public id: number|null = null;
    public email: String|null = null;
    public role: FamilyRole|null = null;
    public family: Family|null = null;
    public createdAt: DateTime|null = null;
    public createdBy: User|null = null

    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;

    public observeEvents: ApiEvent[] = [
        ApiEvent.FamilyInvitationCreated,
    ];

    /**
     * Creates a new empty FamilyInvitation object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new FamilyInvitation model from json data
     * @param json An object holding the data to parse
     * @returns FamilyInvitation
     */
    static fromJson(json: any, observe: boolean = false): FamilyInvitation
    {
        const obj = new FamilyInvitation(observe);
        obj.id = json.id;
        obj.email = json.email;
        obj.role = json.role as FamilyRole;
        if (json.family) {
            obj.family = Family.fromJson(json.family);
        }
        obj.createdAt = DateTime.fromISO(json.created_at);

        if (json.created_by) {
            obj.createdBy = User.fromJson(json.created_by);
        }

        return obj;
    }

    /**
     * Returns an array of FamilyInvitations from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<FamilyInvitation>
    {
        const array = new Array<FamilyInvitation>();

        json.forEach((item: any) => {
            const obj = FamilyInvitation.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(family: Family, invitation: FamilyInvitation|null = null): string
    {
        let authStore = useAuthStore();
        let userId = authStore.user.id;

        if (invitation?.id == null) {
            return `/api/users/${userId}/families/${family.id}/invitations?include=createdBy`;
        }

        return `/api/users/${userId}/families/${family.id}/invitations/${invitation.id}?include=createdBy`;
    }

    static getRouteForCurrentUsersInvitations(invitation: FamilyInvitation|null = null): string
    {
        let authStore = useAuthStore();
        let userId = authStore.user.id;

        if (invitation?.id == null) {
            return `/api/users/${userId}/invitations`;
        }

        return `/api/users/${userId}/invitations/${invitation.id}`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<FamilyInvitation>
     */
    public save(): Promise<FamilyInvitation>
    {
        this._isSaving = true;

        return new Promise<FamilyInvitation>((resolve, reject) => {
            if (this.id) {
                axios.put(FamilyInvitation.getRoute(this.family, this), this.toJson()).then(response => {
                    const obj = FamilyInvitation.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(FamilyInvitation.getRoute(this.family), this.toJson()).then(response => {
                    const obj = FamilyInvitation.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<FamilyInvitation>
     */
    public delete(): Promise<FamilyInvitation>
    {
        this._isDeleting = true;
        return new Promise<FamilyInvitation>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(FamilyInvitation.getRoute(this.family, this)).then(response => {
                    const obj = FamilyInvitation.fromJson(response.data);
                    this.copyFrom(obj)
                    this._isDeleting = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    public accept(): Promise<Family>
    {
        this._isSaving = true;

        return new Promise<Family>((resolve, reject) => {
            axios.post(FamilyInvitation.getRouteForCurrentUsersInvitations(this) + '/accept', this.toJson()).then(response => {
                const family = Family.fromJson(response.data.family);
                this._isSaving = false;
                resolve(family);
            }).catch(error => {
                console.error(error);
                this._isSaving = false;
                reject(error);
            })
        });
    }

    public reject(): Promise<FamilyInvitation>
    {
        this._isSaving = true;

        return new Promise<FamilyInvitation>((resolve, reject) => {
            axios.post(FamilyInvitation.getRouteForCurrentUsersInvitations(this) + '/reject', this.toJson()).then(response => {
                const invitation = FamilyInvitation.fromJson(response.data.invitation);
                this.copyFrom(invitation);
                this._isSaving = false;
                resolve(this);
            }).catch(error => {
                console.error(error);
                this._isSaving = false;
                reject(error);
            })
        });
    }

    /**
     * Copies the data from a FamilyInvitation into this instance
     * @param obj Account
     */
    public copyFrom(obj: FamilyInvitation)
    {
        this.id = obj.id;
        this.email = obj.email;
        this.role = obj.role;
        this.family = obj.family;
        this.createdAt = obj.createdAt;
        this.createdBy = obj.createdBy;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    public toJson() : object
    {
        return {
            'id': this.id,
            'email': this.email,
            'role': this.role.toString(),
            'family': this.family.toJson(),
            'created_at': this.createdAt?.toISO(),
            'created_by': this.createdBy?.toJson(),
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {

    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }
}

<template>
    <v-dialog
        v-model="open"
        width="auto"
        max-width="400px"
    >
        <v-card>
            <v-container fluid class="pa-0 elevation-4">
                <v-row>
                    <v-col>
                        <v-card
                            color="main"
                            height="40"
                            flat
                            class="rounded-0"
                        >
                        <v-card-title>
                            <v-icon>mdi-alert</v-icon>
                            {{ title }}
                        </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-card-text>{{ message }}</v-card-text>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-card-actions>
                            <v-btn
                                :text="okButtonText"
                                @click="ok"
                                :color="okButtonColor"
                                variant="flat"
                                :width="variant == 'confirm' ? '40%' : '100%'"
                            />
                            <v-spacer v-if="variant=='confirm'"/>
                            <v-btn
                                v-if="variant=='confirm'"
                                :text="cancelButtonText"
                                @click="cancel"
                                :color="cancelButtonColor"
                                variant="flat"
                                width="40%"
                            />
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            open: false,
            title: null,
            message: null,
            okButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            okButtonColor: 'primary',
            cancelButtonColor: 'main',
            resolve: null,
            reject: null,
            variant: 'confirm',
        };
    },
    expose: [
        'confirm',
        'alert',
        'okButtonText',
        'cancelButtonText',
        'okButtonColor',
        'cancelButtonColor',
    ],
    emits: [],
    methods: {
        confirm(title, message) {
            this.title = title;
            this.message = message;
            this.variant = 'confirm';
            this.open = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            })
        },
        alert(title, message) {
            this.title = title;
            this.message = message;
            this.variant = 'alert';
            this.open = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            })
        },
        ok() {
            this.open = false;
            this.resolve(true);
        },
        cancel() {
            this.open = false;
            this.resolve(false);
        }
    }
}
</script>

<style>

</style>

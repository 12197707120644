import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.css";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
import { light } from './themes/faLight';
import { dark } from './themes/faDark';

export const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    components: {
        ...components,
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light,
            dark,
        }
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
          mdi,
        },
    },
    options: {
        customProperties: true,
    },
    defaults: {
        VTextField: {
            variant: 'outlined',
            density: 'compact',
            hideDetails: 'auto',
        },
        VSelect: {
            variant: 'outlined',
            density: 'compact',
            hideDetails: 'auto',
        },
        VTextarea: {
            variant: 'outlined',
            density: 'compact',
            hideDetails: 'auto',
        },
        VSnackbar: {
            style: 'margin-bottom: env(safe-area-inset-bottom); margin-top: env(safe-area-inset-top);'
        },
        VAutocomplete: {
            hideDetails: 'auto',
        }
    }
  })


<template>
    <v-container fluid class="bg-main">
        <v-row>
            <v-col>
                <span class="text-h5 text-primary">{{ $t('projects') }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="searchString"
                    density="compact"
                    :label="$t('search')"
                    append-inner-icon="mdi-magnify"
                    single-line
                    hide-details
                    clearable
                ></v-text-field>
            </v-col>
            <v-col class="text-right" cols="3" md="2">
                <v-btn
                    color="primary"
                    variant="flat"
                    block
                    @click.prevent="$router.push({name: 'Project', params: {id: 'new'}})"
                >
                    {{ mobile ? '' : $t('add-project') }}
                    <v-icon v-if="mobile">mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
    <v-container fluid>
        <v-row class="text-center" v-if="!isLoadingProjects && projects.length > 0">
            <v-col>
                <v-btn-toggle color="primary" v-model="filterActiveProjects" mandatory border>
                    <v-btn variant="text" :value="true">{{ t('active') }}</v-btn>
                    <v-btn variant="text" :value="false">{{ t('finished') }}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
    </v-container>
    <empty-state
        v-if="!isLoadingProjects && filteredProjects.length == 0"
        icon="mdi-file-link"
        :title="t('no-projects')"
        :action-button-text="t('create-your-first-project')"
        :loading="isLoadingProjects"
        @actionButtonClick="$router.push({name: 'Project', params: {id: 'new'}})"
    >
        <template v-slot:content>
            {{ t('projects-are-a-great-way-to-get-an-overview-of-income-or-expenses-related-to-an-event-or-a-project') }}.
            <br>
            {{ t('you-can-set-goals-for-how-much-you-want-to-earn-spend-or-save') }}.
        </template>
    </empty-state>
    <v-skeleton-loader
        v-for="index in 3"
        :key="index"
        class=""
        type="list-item-three-line, divider"
        :loading="isLoadingProjects"
    >
        <template></template>
    </v-skeleton-loader>
    <v-list v-if="!isLoadingProjects && filteredProjects.length > 0">
        <project-row
            v-for="project in filteredProjects"
            :key="project.id"
            :project="project"
        />
    </v-list>
</template>

<script setup lang="ts">
import { useProjectStore } from '../../stores/projectStore';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ProjectRow from './components/ProjectRow.vue';
import { useDisplay } from 'vuetify';
import { useAuthStore } from '../../stores/authStore';
import { Project } from '../../models/Project';

const projectStore = useProjectStore();
const projects = computed(() => projectStore.projects);
const filterActiveProjects = ref(true);

const filteredProjects = computed(() => {
    return projects.value.filter((project: Project) => {
        if (filterActiveProjects.value == true) {
            return project.isCompleted == false
            && (project.projectName.toLowerCase().includes(searchString.value) || project.description?.toLowerCase().includes(searchString.value));
        } else {
            return project.isCompleted == true
            && (project.projectName.toLowerCase().includes(searchString.value) || project.description?.toLowerCase().includes(searchString.value));
        }
    })
});

const isLoadingProjects = computed(() => projectStore.isLoadingProjects);

const authStore = useAuthStore();
const family = computed(() => authStore.currentFamily);

const { t } = useI18n();
const { mobile } = useDisplay();

const components = {
    ProjectRow,
};

const breadcrumbs = [
        {
            title: t('home'),
            disabled: false,
            to: {
                name: 'Home'
            }
        },
        {
            title: t('projects'),
            disabled: true,
            to: {
                name: 'Projects'
            }
        },
    ];

let searchString = ref("");

projectStore.getProjects();

watch(family, (newVal, oldVal) => {
    projectStore.getProjects();
});
</script>

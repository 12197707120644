import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from './ApiEvent';
import { FamilyInvitation } from '../models/FamilyInvitation';
import { Family } from '../models/Family';
import { useFamilyStore } from '../stores/familyStore';
import NotificationCenter from './NotificationCenter';
import { BudgetAccount, BudgetAccountType } from '../models/BudgetAccount';
import { useBudgetStore } from '../stores/budgetStore';
import { BudgetAccountCategory } from '../models/BudgetAccountCategory';
import { MoneyAccount, MoneyAccountType } from '../models/MoneyAccount';
import { useMoneyStore } from '../stores/moneyStore';
import { useCalculationStore } from '../stores/calculationStore';
import { useAuthStore } from '../stores/authStore';
import { Account } from '../models/Account';

export default class SystemEventObserver implements ApiNotificationObserver
{
    constructor() {
        NotificationCenter.shared().registerObserver(this);
    }

    public observeEvents: ApiEvent[] = [
        ApiEvent.FamilyInvitationCreated,
        ApiEvent.AccountCreated,
        ApiEvent.BudgetAccountCategoryCreated,
        ApiEvent.AccountedResultChanged,
        ApiEvent.BudgetChanged,
        ApiEvent.UserDisabled,
        ApiEvent.AccountDeleted,
    ];

    public didReceiveNotification(notification: ApiEvent, data: any) {
        let budgetStore = useBudgetStore();
        let moneyStore = useMoneyStore();
        let familyStore = useFamilyStore();
        let calculationStore = useCalculationStore();
        let authStore = useAuthStore();

        const currentPeriod = authStore.currentPeriod;

        if (notification == ApiEvent.FamilyInvitationCreated) {
            let invitation = FamilyInvitation.fromJson(data.invitation);
            const index = familyStore.invitations.findIndex((obj) => obj.id === invitation.id);

            if (index == -1) {
                let family = Family.fromJson(data.family);
                invitation.family = family;
                familyStore.invitations.push(invitation);
            }
        }

        if (notification == ApiEvent.AccountCreated) {
            let accountType = data.account.account_type;

            if (Object.values(BudgetAccountType).includes(accountType)) {
                let newAccount = BudgetAccount.fromJson(data.account, true);
                budgetStore.addAccount(newAccount);
            }

            if (Object.values(MoneyAccountType).includes(accountType)) {
                let newAccount = MoneyAccount.fromJson(data.account, true);
                moneyStore.addAccount(newAccount);
            }
        }

        if (notification == ApiEvent.BudgetAccountCategoryCreated) {
            let newCategory = BudgetAccountCategory.fromJson(data.category, true);

            budgetStore.addCategory(newCategory);
        }

        if (notification == ApiEvent.BudgetAccountCategoryUpdated) {
            let newCategory = BudgetAccountCategory.fromJson(data.category, true);

            const index = budgetStore.categories.indexOf(newCategory);

            if (index > -1) {
                budgetStore.categories[index].copyFrom(newCategory);
            }
        }

        if (notification == ApiEvent.AccountedResultChanged) {
            calculationStore.getTotals();
            calculationStore.getLiquidityRatio();
            budgetStore.getAccountedResultChartData();
            budgetStore.getAllAccounts();
        }

        if (notification == ApiEvent.BudgetChanged) {
            calculationStore.getTotals();
            calculationStore.getLiquidityRatio();
            budgetStore.getBudgetForecastChartData();
            budgetStore.getBudgetExpensesPieChartData();
            budgetStore.getAllAccounts();
            budgetStore.getUpcomingEvents();
        }

        if (notification == ApiEvent.UserDisabled) {
            authStore.logout();
        }

        if (notification == ApiEvent.AccountDeleted) {
            budgetStore.removeAccount(BudgetAccount.fromJson(data.account));
            moneyStore.removeAccount(MoneyAccount.fromJson(data.account));
        }
    }

}

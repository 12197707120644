import axios from "axios";
import { useAuthStore } from "@stores/authStore";
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from 'notifications/ApiEvent';
import { Voucher } from "./Voucher";
import { Transaction } from "../types/Transaction";
import { VoucherLine } from './VoucherLine';
import NotificationCenter from 'notifications/NotificationCenter';
import { DateTime } from 'luxon';
import { User } from "./User";

/**
 * A model representing the data for MoneyAccount.
 * The model-data can be retrieved from /api/families/{family}/money_accounts
 */
export class MoneyAccount implements ApiNotificationObserver
{
    public id: number|null = null;
    public accountType: MoneyAccountType|null = null;
    public accountName: string = "";
    public description: string = "";
    public iconName: string = "";
    public initialBalance: number = 0;
    public initialBalanceDate: DateTime = DateTime.now();
    public excludeFromBalance: boolean = false;
    public balance: number = 0;

    public transactions: Array<Transaction> = [];
    public owner: User|null = null;


    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;
    private _isLoadingTransactions: boolean = false;
    private _isLoadingBalance: boolean = false;

    public observeEvents: ApiEvent[] = [
        ApiEvent.AccountUpdated,
        ApiEvent.AccountedResultChanged,
        ApiEvent.VoucherCreated,
        ApiEvent.VoucherUpdated,
        ApiEvent.VoucherDeleted,
    ];

    /**
     * Creates a new empty model
     * @param observe boolean Wheter to add observers to the model or not
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new MoneyAccount model from json data
     * @param json An object holding the data to parse
     * @returns MoneyAccount
     */
    static fromJson(json: any, observe: boolean = false): MoneyAccount
    {
        const obj = new MoneyAccount(observe);
        obj.id = json.id;
        obj.accountType = json.account_type;
        obj.accountName = json.account_name;
        obj.description = json.description;
        obj.iconName = json.icon_name;
        obj.initialBalance = json.initial_balance;
        obj.initialBalanceDate = DateTime.fromISO(json.initial_balance_date);
        obj.excludeFromBalance = json.exclude_from_balance;
        obj.balance = json.balance;

        if (json.owner_user) {
            obj.owner = User.fromJson(json.owner_user);
        }

        return obj;
    }

    /**
     * Returns an array of MoneyAccounts from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<MoneyAccount>
    {
        const array = new Array<MoneyAccount>();

        json.forEach((item: any) => {
            const obj = MoneyAccount.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param accoun
     * @returns string
     */
    static getRoute(account: MoneyAccount|null = null): string
    {
        const familyId = useAuthStore().currentFamily.id;

        if (account?.id) {
            return `/api/families/${familyId}/money_accounts/${account.id}`;
        }

        return `/api/families/${familyId}/money_accounts`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<MoneyAccount>
     */
    public save(): Promise<MoneyAccount>
    {
        this._isSaving = true;
        return new Promise<MoneyAccount>((resolve, reject) => {
            if (this.id) {
                axios.put(MoneyAccount.getRoute(this), this.toJson()).then(response => {
                    const obj = MoneyAccount.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(MoneyAccount.getRoute(), this.toJson()).then(response => {
                    const obj = MoneyAccount.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<MoneyAccount>
     */
    public delete(): Promise<MoneyAccount>
    {
        this._isDeleting = true;
        return new Promise<MoneyAccount>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(MoneyAccount.getRoute(this)).then(response => {
                    this._isDeleting = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    private toJson() : object
    {
        return {
            'id': this.id,
            'account_type': this.accountType,
            'account_name': this.accountName,
            'description': this.description,
            'icon_name': this.iconName,
            'initial_balance': this.initialBalance,
            'initial_balance_date': this.initialBalanceDate.toFormat('yyyy-MM-dd'),
            'exclude_from_balance': this.excludeFromBalance,
            'owner_user': this.owner,
        };
    }


    /**
     * Copies the data from a MoneyAccount into this instance
     * @param obj MoneyAccount
     */
    public copyFrom(obj: MoneyAccount)
    {
        this.id = obj.id;
        this.accountName = obj.accountName;
        this.accountType = obj.accountType;
        this.description = obj.description;
        this.iconName = obj.iconName;
        this.initialBalance = obj.initialBalance;
        this.initialBalanceDate = obj.initialBalanceDate;
        this.excludeFromBalance = obj.excludeFromBalance;
        this.balance = obj.balance;
        this.owner = obj.owner;
    }

    public refreshBalance(): Promise<number>
    {
        this._isLoadingBalance = true;
        return new Promise<number>((resolve, reject) => {
            if (this.id == null) {
                this._isLoadingBalance = false;
                reject();
            }
            let url = MoneyAccount.getRoute(this);
            axios.get(url).then(result => {
                this.balance = Number(result.data.balance);
                this._isLoadingBalance = false;
                resolve(result.data.balance);
            }).catch(error => {
                console.error(error);
                this._isLoadingBalance = false;
                reject(error);
            })
        })
    }

    /**
     * Returns true if a save-process is running
     */
    get isSaving(): boolean
    {
        return this._isSaving;
    }

    /**
     * Returns true if a delete-process is running
     */
    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    get isLoadingTransactions(): boolean
    {
        return this._isLoadingTransactions;
    }

    get isLoadingBalance(): boolean
    {
        return this._isLoadingBalance;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        const authStore = useAuthStore();
        const currentPeriod = authStore.currentPeriod;

        if (notification == ApiEvent.AccountUpdated && data?.account?.id == this.id) {
            let newAccount = MoneyAccount.fromJson(data.account);
            this.copyFrom(newAccount);
        }

        if (notification == ApiEvent.AccountedResultChanged && currentPeriod.month == data.month && currentPeriod.year == data.year) {
            let account = data.accounts.find((account) => account.id == this.id);
            if (account) {
                this.balance = account.balance;
            }
        }

        if (notification == ApiEvent.VoucherCreated) {
            this.handleVoucherEvent(Voucher.fromJson(data.voucher));
        }

        if (notification == ApiEvent.VoucherUpdated) {
            this.handleVoucherEvent(Voucher.fromJson(data.voucher));
        }

        if (notification == ApiEvent.VoucherDeleted) {
            this.handleVoucherEvent(Voucher.fromJson(data.voucher));
        }
    }

    public registerObservers() {
        NotificationCenter.shared().registerObserver(this);
    }

    public getTransactions(fromDate: string, toDate: string): Promise<Array<Transaction>>
    {
        this._isLoadingTransactions = true;
        return new Promise<Array<Transaction>>((resolve, reject) => {
            axios.get(MoneyAccount.getRoute(this) + `/transactions?from=${fromDate}&to=${toDate}`)
            .then((result: any) => {
                let arr = Transaction.arrayFromJson(result.data);
                this.transactions = arr;
                this._isLoadingTransactions = false;
                resolve(arr);
            })
            .catch(error => {
                console.error(error);
                this._isLoadingTransactions = false;
                reject(error);
            })
        });
    }

    handleVoucherEvent(voucher: Voucher)
    {
        let existingLines = this.transactions.filter((obj: Transaction) => obj.voucherId == voucher.id);
        let changedSomething = false;

        if (existingLines.length > 0) {
            changedSomething = true;
            existingLines.forEach((line: Transaction) => {
                this.transactions.splice(this.transactions.indexOf(line), 1);
            })
        }

        voucher.voucherLines.forEach((line: VoucherLine) => {
            if (line.accountId == this.id) {
                changedSomething = true;
                let newTransaction = Transaction.fromVoucher(voucher, line);
                this.transactions.push(newTransaction);
            }
        })

        if (changedSomething) {
            this.refreshBalance();
        }
    }
}

export enum MoneyAccountType {
    Bank = 'BANK',
    Saving = 'SAVING',
    Cash = 'CASH',
}

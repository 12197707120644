
<template>
    <v-container fluid class="text-center" style="margin-top: 10%;">
        <v-img src="./images/appikon.png" max-height="200px"/>
        <h1>{{ $t('oops-nothing-here') }}</h1>
        <h3>{{ $t('we-could-not-find-the-page-you-are-looking-for') }}</h3>
        <v-row class="mt-4">
            <v-col cols="12" md="6" class="text-right">
                <v-btn
                    :block="mobile"
                    @click="$router.back()"
                >
                    {{ $t('go-back') }}
                </v-btn>
            </v-col>
            <v-col cols="12" md="6" class="text-left">
                <v-btn
                    :block="mobile"
                    @click="$router.push('/')"
                >
                    {{ $t('go-home') }}
                </v-btn>
            </v-col>
        </v-row>

    </v-container>
  </template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();

</script>

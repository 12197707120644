<template>
    <v-row no-gutters>
        <v-col cols="12">
            <Pie
                id="my-chart-id"
                :options="chartOptions"
                :data="chartData"
            />
        </v-col>
    </v-row>
  </template>

<script setup lang="ts">
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'
import { computed } from 'vue';
import { toRefs } from 'vue';
import { reactive } from 'vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const props = defineProps(reactive({
    data: {
        type: Array<{}>,
        required: true,
    },
    itemLabel: {
        type: String,
        required: true,
    },
    itemData: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: false,
    }
}));

const { data: data } = toRefs(props)
const itemLabel = computed(() => props.itemLabel);
const itemData = computed(() => props.itemData);
const label = computed(() => props.label);

const labels = computed(() => {
    return data.value.map(obj => {
        return obj[itemLabel.value];
    });
});

const values = computed(() => {
    return data.value.map(obj => { return obj[itemData.value] });
});

const colors = computed(() => {
    var colors = [
        '#ef7159',
        '#fbc98e',
        '#81a7d6',
        '#decc60',
        '#c7bba1',
    ];

    // var dynamicColor = function() {
    //     var r = Math.floor(Math.random() * 255);
    //     var g = Math.floor(Math.random() * 255);
    //     var b = Math.floor(Math.random() * 255);
    //     return "rgb(" + r + "," + g + "," + b + ")";
    // };

    // for (var i in data.value) {
    //     colors.push(dynamicColor());
    // }

    return colors;
});

const chartData = computed(() => {
    return {
        labels: labels.value,
        datasets: [
            {
                label: label.value,
                data: values.value,
                backgroundColor: colors.value,
            }
        ]
    }
});

const chartOptions = {
    responsive: true,
    cutout: '50%',
    maintainAspectRatio: false,
    radius: 90,
    plugins: {
        legend: {
            display: true,
            position: 'bottom'
        },
        datalabels: {
            formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value*100 / sum).toFixed(2)+"%";
                return percentage;
            },
            color: '#fff',
        }
    },
}

</script>

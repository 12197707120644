
<template>
    <v-card flat>
        <v-card-title class="d-flex align-center pe-2 pt-5" fixed>
            <v-icon icon="mdi-account-multiple"></v-icon> &nbsp;
            {{ $t('users') }}
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                density="compact"
                :label="$t('search')"
                single-line
                flat
                hide-details
                variant="solo-filled"
            ></v-text-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
            v-model="selected"
            density="comfortable"
            v-model:items-per-page="itemsPerPage"
            :loading="loading"
            :headers="headers"
            :items="users"
            @click:row="select"
            hover
            :search="search"
            v-model:sort-by="sortBy"
            :no-data-text="$t('no-users-matching-the-filter')"
            :custom-sort="customSort"
        >
            <template v-slot:item.activated="{ item }">
                <v-row dense justify="center">
                    <v-col align="center">
                        <v-icon size="x-large" v-if="item.isDisabled" color="error">mdi-account-cancel</v-icon>
                        <v-icon size="x-large" v-if="item.activated == false && item.isDisabled == false" color="orange">mdi-account-alert</v-icon>
                        <v-icon v-if="!item.isDisabled && item.activated" size="x-large" color="success">mdi-account</v-icon>
                    </v-col>
                </v-row>
                <v-row dense v-if="mobile && (item.hasPush || item.hasRelatedUsers)" align="center" justify="center">
                    <v-col cols="6" v-if="item.hasPush" align="center">
                        <v-icon color="green" size="small">mdi-square-rounded-badge-outline</v-icon>
                    </v-col>
                    <v-col cols="6" v-if="item.hasRelatedUsers" align="center">
                        <v-icon color="green" size="small">mdi-link-variant</v-icon>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.hasPush="{ item }">
                <v-row>
                    <v-col align="center" justify="center">
                        <v-icon size="x-large" v-if="item.hasPush" color="green">mdi-square-rounded-badge-outline</v-icon>
                    </v-col>
                </v-row>

            </template>
            <template v-slot:item.hasRelatedUsers="{ item }">
                <v-row>
                    <v-col align="center" justify="center">
                        <v-icon size="x-large" v-if="item.hasRelatedUsers" color="green">mdi-link-variant</v-icon>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.lastActivityAt="{ item }">
                    <p class="text-right">
                        <span v-if="!mobile" class="pr-2">{{ item.lastActivityAt ? item.lastActivityAt.toRelative() ?? $t('never') : $t('never') }}</span>
                        <v-icon
                            :color="item.lastActivityAt == null ? 'gray' : item.lastActivityAt.diffNow('minutes').minutes > -5 ? 'green' : 'red'"
                        >
                            mdi-circle
                        </v-icon>
                    </p>
            </template>
            <template v-slot:item.fullName="{ item }">
                <tr @click="select(null, {item})">
                    <td colspan="3" style="max-width: 60dvw;">
                        <p class="text-subtitle-1 font-weight-bold overflow">{{ item.fullName }}</p>
                        <p class="text-body-2" style="white-space: nowrap; overflow:hidden; text-overflow: ellipsis;">{{ item.email }}</p>
                        <p class="text-body-2">{{ $t('last-seen') }} {{ item.lastActivityAt ? item.lastActivityAt.toRelative() ?? $t('never') : $t('never') }}</p>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-card>

    <userDialog ref="userDialog"/>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '../../stores/userStore';
import UserDialog from './UserDialog.vue';
import { onUnmounted } from 'vue';

const { mobile } = useDisplay();
const { t } = useI18n();
const userStore = useUserStore();
const userDialog = ref(null);

const selected = ref([]);
const itemsPerPage = ref(20);
const search = ref("");
const sortBy = ref([{key: 'lastActivityAt', order: 'desc'}]);
const loading = ref(false);
const users = computed(() => userStore.users);

const headers = computed(() => {
        if (mobile.value) {
            return [
                {title: null, align: 'start', sortable: true, key: 'activated', },
                {title: t('name'), align: 'start', sortable: true, key: 'fullName', width: '50px'},
                {title: null, align: 'start', sortable: true, key: 'lastActivityAt'},
            ]
        }
        return [
            {title: t('activated'), align: 'center', sortable: true, key: 'activated', width:'50px'},
            {title: t('notifications'), align: 'start', sortable: true, key: 'hasPush', width:'50px'},
            {title: t('related'), align: 'start', sortable: true, key: 'hasRelatedUsers', width:'50px'},
            {title: t('first-name'), align: 'start', sortable: true, key: 'firstName'},
            {title: t('last-name'), align: 'start', sortable: true, key: 'lastName'},
            {title: t('email'), align: 'start', sortable: true, key: 'email'},
            {title: t('language'), align: 'center', sortable: true, key: 'language'},
            {title: t('last-seen'), align: 'end', sortable: true, key: 'lastActivityAt'},
        ];

});


function select(event, {item})
{
    userDialog.value.openUser(item);
    //this.$refs.voucherDialog.openVoucher(item);
}

function customSort(items, index, isDesc) {
    items.sort((a, b) => {

        if (index === "lastActivityAt") {
            if (a === b) {
                return 0;
            }
            // nulls sort after anything else
            if (a === null) {
                return 1;
            }
            if (b === null) {
                return -1;
            }
        }

        // otherwise, if we're ascending, lowest sorts first
        if (!isDesc) {
            return a < b ? -1 : 1;
        }

        // if descending, highest sorts first
        return a < b ? 1 : -1;
      });
      return items;
}
userStore.getUsers();

const timer = setInterval(() => userStore.getUsers(), 10000);

onUnmounted(() => {
    clearInterval(timer);
})


</script>

import axios from "axios";
import { defineStore } from "pinia";
import { User } from '../models/User';
import { Ref, ref } from 'vue';

export const useUserStore = defineStore('UserStore', () => {
    let users: Ref<Array<User>> = ref([]);

    function getUsers(): Promise<Array<User>>
    {
        return new Promise<Array<User>>((resolve, reject) => {
            let url = '/api/users?include=families';

            axios.get(url).then(result => {
                const fetchedUsers = User.arrayFromJson(result.data);
                users.value = fetchedUsers;
                resolve(users.value);
            }).catch(error => {
                console.error(error);
                reject(error);
            })

        })
    }
    return {
        users,
        getUsers,
    }
})

<template>
    <div class="box" :style="mobile ? 'height: calc(100vh - 100px - env(safe-area-inset-top) - env(safe-area-inset-bottom));' : 'height: 94vh;'">
        <div class="row header bg-accent elevation-3">
            <v-img src="./images/logo_animated_pulse.gif" style="max-width: 70px;"/>
            <p class="text-h5 ml-3">Factus</p>
        </div>
        <div class="row content pa-3" ref="contentsection">
            <v-row>
                <v-col class="text-center text-subtitle-2">
                    {{ t('factus-can-answer-economy-related-questions-and-help-you-do-stuff-in-factum') }}.
                    {{ t('ask-factus-about-something') }}...
                </v-col>
            </v-row>
            <v-row
                v-for="message in messages"
                :key="message.message"
            >
                <v-col class="text-body-2">
                    <div
                        v-html="parseMarkdown(message.message)"
                        :class="message.role == 'user' ? 'user-message' : message.role == 'assistant' ? 'assistant-message' : 'error-message'"
                    ></div>
                </v-col>
            </v-row>
            <v-row v-if="waitingForResponse">
                <v-col>
                    <v-skeleton-loader type="sentences" />
                </v-col>
            </v-row>
            <p ref="bottomelement"></p>
        </div>
        <div class="row footer pa-3">
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        v-model="message"
                        ref="messageField"
                        rows="1"
                        max-rows="10"
                        variant="outlined"
                        rounded
                        auto-grow
                        :label="t('write-a-message')"
                        @keydown.enter.prevent="sendMessage()"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>


<script setup lang="ts">
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { Factus } from '../../Factus/Factus';
import { marked } from 'marked';

const { t } = useI18n();
const { mobile } = useDisplay();
const waitingForResponse = ref(false);

const messageField = ref(null);
const bottomelement = ref(null);
const message = ref("");
const messages = ref([]);

function parseMarkdown(message)
{
    return marked(message);
}

function sendMessage()
{
    waitingForResponse.value = true;
    messages.value.push({message: message.value, role: "user"});
    Factus.ask(message.value).then(answer => {
        messages.value.push({message: answer, role: 'assistant'});
        nextTick(() => {
            bottomelement.value.scrollIntoView({behavior: 'smooth'});
            waitingForResponse.value = false;
        })
    }).catch(error => {
        waitingForResponse.value = false;

        if (error.response.status == 403) {
            messages.value.push({message: t('you-dont-have-access-to-factus'), role: 'error'});
        } else {
            messages.value.push({message: t('en-error-occured'), role: 'error'});
        }
    })
    message.value = "";
    nextTick(() => {
        bottomelement.value.scrollIntoView({behavior: 'smooth'});
    })

}

</script>

<style lang="scss">
html,
body {
    height: 100%;
    margin: 0;
}

.box {
    display: flex;
    flex-flow: column;
}

.box .row {
    border: none;
}

.box .row.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 1 auto;
    height: 60px;
}

.box .row.content {
    flex: 1 1 auto;
    overflow: scroll;
    padding-right: 5px;
    padding-left: 5px;
}

.box .row.footer {
    flex: 0 1 auto;
    min-height: 70px;
}

.assistant-message {
    padding-left: 10px;
    text-align: left !important;
    max-width: 90%;

    ul, ol {
        margin-left: 25px;
    }
}

.error-message {
    padding-left: 10px;
    text-align: left !important;
    max-width: 90%;
    color: red;

    ul, ol {
        margin-left: 25px;
    }
}

.user-message {
    text-align: right !important;
    background-color: lightgray;
    border-radius: 15px;
    padding: 3px 10px;
    width: fit-content;
    margin-left: auto;
    margin-right: 5px;
    color: black;
    max-width: 90%;
}
</style>

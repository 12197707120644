import axios from "axios";
import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { Family } from '../models/Family';
import { FamilyInvitation } from '../models/FamilyInvitation';

export const useFamilyStore = defineStore('FamilyStore', {
    state: () => {
        return {
            isCreatingFamily: false,
            isLoadingFamilies: false,
            families: [],
            invitations: [],
        }
    },
    actions: {
        addFamily(family: Family) {
            this.isLoadingFamilies = true;
            const index = this.families.findIndex((obj) => obj.id === family.id);

            if (index == -1) {
                this.families.push(family);
            }
            const authStore = useAuthStore();
            authStore.user.families.push(family);

            this.isLoadingFamilies = false;
        },

        updateFamily(family: Family) {
            this.isLoadingFamilies = true;
            const index = this.families.findIndex((obj) => obj.id === family.id);

            if (index > -1) {
                this.families[index].copyFrom(family);
            }
            const authStore = useAuthStore();
            const userFamilyIndex = authStore.user.families.findIndex((obj) => obj.id === family.id);

            if (userFamilyIndex > -1) {
                authStore.user.families[userFamilyIndex].copyFrom(family);
            }

            this.isLoadingFamilies = false;
        },

        removeFamily(family: Family) {
            this.isLoadingFamilies = true;
            const index = this.families.findIndex((obj) => obj.id === family.id);

            if (index > -1) {
                this.families.splice(index, 1);
            }
            const authStore = useAuthStore();
            const userFamilyIndex = authStore.user.families.findIndex((obj) => obj.id === family.id);

            if (userFamilyIndex > -1) {
                authStore.user.families.splice(userFamilyIndex, 1);
            }

            if (authStore.currentFamily.id === family.id) {
                authStore.currentFamily = null;
                authStore.initCurrentFamily();
            }
        },

        async getFamilies() {
            this.isLoadingFamilies = true;
            let authStore =  useAuthStore();
            if (await authStore.isVerified == false) {
                this.isLoadingFamilies = false;
                return;
            } else if (authStore.user == null || authStore.user.id == null) {
                this.isLoadingFamilies = false;
                return;
            } else {
                return new Promise((resolve, reject) => {
                    let userId = authStore.user.id
                    if (userId === null) {
                        this.isLoadingFamilies = false;
                        reject();
                    }

                    axios.get(`/api/users/${userId}/families?include=memberships.user`)
                    .then(result => {
                        this.families = Family.arrayFromJson(result.data.data, true);
                        this.isLoadingFamilies = false;
                        resolve(this.families);
                    })
                    .catch(error => {
                        console.error(error);
                        this.isLoadingFamilies = false;
                        reject(error);
                    })
                });
            }
        },

        async getInvitations(): Promise<Array<FamilyInvitation>> {
            let authStore = useAuthStore();
            if (await authStore.isVerified() == false || authStore.isVerified == undefined) {
                return;
            } else if (authStore.user == null || authStore.user.id == null) {
                return;
            } else {
                return new Promise<Array<FamilyInvitation>>((resolve, reject) => {
                    axios.get(`/api/users/${authStore.user.id}/invitations`)
                    .then(result => {
                        this.invitations = FamilyInvitation.arrayFromJson(result.data);
                        resolve(this.invitations);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
                });
            }
        },

        removeInvitation(invitation: FamilyInvitation) {
            const index = this.invitations.findIndex((obj: any) => obj.id === invitation.id);

            if (index > -1) {
                this.invitations.splice(index, 1);
            }
        },

        async sendInvitation(family: any, invitation: any) {
            return new Promise((resolve, reject) => {
                let authStore = useAuthStore();
                let userId = authStore.user.id
                if (userId === null) {
                    return;
                }

                axios.post(`/api/users/${userId}/families/${family.id}/invitations?include=createdBy`, invitation)
                .then(result => {
                    resolve(result.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
            });
        },

        async getSentInvitations(family: any) {
            return new Promise((resolve, reject) => {
                let authStore = useAuthStore();
                let userId = authStore.user.id
                if (userId === null) {
                    return;
                }

                axios.get(`/api/users/${userId}/families/${family.id}/invitations/`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
            });
        },

        async deleteInvitation(invitation: any) {
            return new Promise((resolve, reject) => {
                let authStore = useAuthStore();
                let userId = authStore.user.id
                if (userId === null) {
                    return;
                }

                axios.delete(`/api/users/${userId}/families/${invitation.family.id}/invitations/${invitation.id}`)
                .then(() => {
                    resolve(null);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
            });
        },

        async deleteMembership(family: any, membership: any) {
            return new Promise((resolve, reject) => {
                let authStore = useAuthStore();
                let userId = authStore.user.id
                if (userId === null) {
                    return;
                }

                axios.delete(`/api/users/${userId}/families/${family.id}/memberships/${membership.id}`)
                .then(() => {
                    resolve(null);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
            });
        },

        async updateMembership(family: any, membership: any) {
            return new Promise((resolve, reject) => {
                let authStore = useAuthStore();
                let userId = authStore.user.id
                if (userId === null) {
                    return;
                }

                axios.put(`/api/users/${userId}/families/${family.id}/memberships/${membership.id}`, membership)
                .then(result => {
                    resolve(result.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
            });
        }
    },
});

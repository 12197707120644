export const dark = {
    dark: true,
    colors: {
        background: '#111111',
        surface: "#222222",
        primary: '#fff',
        main: "#333333",
        info: '#ADDFFF',
        mint: '#92B0B5',
        mintLight: '#CED1C1',
        accent: '#5F6A72',
        gray: "#909090",
    }
}

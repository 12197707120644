<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1 class="text-success">{{ $t('hello') }}, {{ user.firstName }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3">
                <v-card color="success" @click="$router.push({name: 'Users'})">
                    <v-card-title class="text-center">
                        {{ $t('active-users') }}
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                        {{ $t('right-now') }}
                    </v-card-subtitle>
                    <v-card-text class="text-center">
                        <h1>{{ statistics.currentActiveUsers }}</h1>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" md="3">
                <v-card @click="$router.push({name: 'Users'})">
                    <v-card-title class="text-center">
                        {{ $t('active-users') }}
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                        {{ $t('last-24-hours') }}
                    </v-card-subtitle>
                    <v-card-text class="text-center">
                        <h1>{{ statistics.activeUsersToday }}</h1>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" md="3">
                <v-card @click="$router.push({name: 'Users'})">
                    <v-card-title class="text-center">
                        {{ $t('new-users') }}
                    </v-card-title>
                    <v-card-subtitle class="text-center">{{ $t('last-24-hours') }}</v-card-subtitle>
                    <v-card-text class="text-center">
                        <h1>{{ statistics.newUsersToday }}</h1>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" md="3">
                <v-card @click="$router.push({name: 'Users'})">
                    <v-card-title class="text-center">
                        {{ $t('users-count') }}
                    </v-card-title>
                    <v-card-subtitle class="text-center">{{ $t('total') }}</v-card-subtitle>
                    <v-card-text class="text-center">
                        <h1>{{ statistics.userCount }}</h1>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="6">
                <v-card>
                    <v-card-title>{{ $t('platforms') }}</v-card-title>
                    <v-card-text>
                        <PieChart
                            :data="statistics.userAgentPieChartData"
                            item-data="count"
                            item-label="user_agent"
                            :label="$t('count')"
                            />

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { Ref, computed, onUnmounted } from 'vue';
import { User } from '@models/User';
import { useAuthStore } from '../../stores/authStore';
import { SuperadminStatistics, useSuperadminStore } from '../../stores/superadminStore';
import PieChart from '../budget/Charts/PieChart.vue';

const authStore = useAuthStore();
const superadminStore = useSuperadminStore();
const user: Ref<User> = computed(() => authStore.user);
const statistics: Ref<SuperadminStatistics> = computed(() => superadminStore.statistics);

superadminStore.getStatistics();

const timer = setInterval(() => superadminStore.getStatistics(), 10000);

onUnmounted(() => {
    clearInterval(timer);
})

</script>

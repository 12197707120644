
<template>
    <fa-dialog
        :open="open"
        :title="user?.fullName"
        @close="open = false"
        :fullscreen="mobile"
        :width="mobile ? '100%' : '800px'"
        :height="mobile ? '100%' : '700px'"
        :icon-image="user?.profilePicture"
        icon="mdi-account"
        remove-margins
    >
        <template v-slot:tabs>
            <v-btn-toggle
                v-model="currentView"
                rounded="0"
                color="primary"
                group
                mandatory
                align="center"
            >
                <v-btn value="main">
                    <v-icon>mdi-home</v-icon>
                    <span class="hidden-sm-and-down">{{ $t('home') }}</span>
                </v-btn>
                <v-btn value="families">
                    <v-icon>mdi-account-group</v-icon>
                    <span class="hidden-sm-and-down">{{ $t('families') }}</span>
                </v-btn>

                <v-btn value="relations">
                    <v-icon>mdi-link-variant</v-icon>
                    <span class="hidden-sm-and-down">{{ $t('relations') }}</span>
                </v-btn>
                <v-btn value="notes">
                    <v-icon>mdi-note-edit-outline</v-icon>
                    <span class="hidden-sm-and-down">{{ $t('notes') }}</span>
                </v-btn>
                <v-btn value="devices">
                    <v-icon>mdi-devices</v-icon>
                    <span class="hidden-sm-and-down">{{ $t('devices') }}</span>
                </v-btn>

            </v-btn-toggle>
        </template>
        <template v-slot:main v-if="currentView == 'main'">
            <v-container fluid>
                <v-row no-gutters>
                    <v-col cols="4" md="3">
                        {{ $t('created') }}
                    </v-col>
                    <v-col cols="8" md="6">
                        {{ $d(user.createdAt.toJSDate(), 'long') }}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" md="3">
                        {{ $t('updated') }}
                    </v-col>
                    <v-col cols="8" md="6">
                        {{ $d(user.updatedAt.toJSDate(), 'long') }}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" md="3">
                        {{ $t('family-count') }}
                    </v-col>
                    <v-col cols="8" md="6">
                        {{ user.families.length }}
                    </v-col>
                </v-row>
                <v-row v-if="user.activated == false">
                    <v-col>
                        <v-alert
                            type="warning"
                        >
                            {{ $t('this-user-has-not-confirmed-its-email-address-and-is-not-yet-activated') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            variant="flat"
                            color="primary"
                            @click="resendVerificationEmail()"
                            v-if="user.activated == false"
                        >
                            {{ $t('resend-verification-email') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="user.isDisabled == false">
                    <v-col>
                        <v-btn
                            variant="flat"
                            color="red"
                            @click="shouldDisableUser = true"
                            v-if="shouldDisableUser == false"
                            :block="mobile"
                        >
                            {{ $t('disable-user') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-form v-model="blockUserFormValid" v-if="user.isDisabled == false">
                    <v-row v-if="shouldDisableUser">
                        <v-col cols="12">
                            <v-textarea
                                v-model="user.disableMessage"
                                :label="$t('message')"
                                :rules="[v => !!v || $t('required')]"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn
                                :disabled="blockUserFormValid == false || blockUserFormValid == null"
                                variant="flat"
                                color="red"
                                @click="disable()"
                                :loading="user.isDisabeling"
                                :block="mobile"
                            >
                                {{ $t('disable-user') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn
                                variant="flat"
                                color="gray"
                                @click="shouldDisableUser = false"
                                v-if="user.isDisabled == false"
                                :block="mobile"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row v-if="user.isDisabled">
                    <v-col>
                        <v-alert
                            type="error"
                            :title="$t('this-user-is-deactivated')"
                        >
                            <v-row>
                                <v-col cols="12">{{ $t('disabled-by') }} {{ user.disabledBy.fullName }} {{ $d(user.disabledAt.toJSDate(), 'short') }}</v-col>
                                <v-col cols="12">
                                    {{ user.disableMessage }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        variant="flat"
                                        color="success"
                                        @click="enable()"
                                        :loading="user.isEnabeling"
                                        :block="mobile"
                                    >
                                        {{ $t('enable') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template v-slot:main v-if="currentView == 'families'">
            <v-data-table
                v-model="familyTable.selected.value"
                density="comfortable"
                v-model:items-per-page="familyTable.itemsPerPage.value"
                :loading="familyTable.loading.value"
                :headers="familyTable.headers.value"
                :items="user.families"
                @click:row="openFamily"
                hover
                :search="familyTable.search.value"
                v-model:sort-by="familyTable.sortBy.value"
                :no-data-text="$t('no-families')"
                show-expand
            >
                <template v-slot:item.familyName="{ item }">
                    <p class="font-weight-bold">{{ item.familyName }}</p>
                </template>
                <template v-slot:header.lastActivityAt>
                    <p class="text-right">{{ $t('last-activity') }}</p>
                </template>
                <template v-slot:item.lastActivityAt="{ item }">
                    <p class="text-right">{{ item.lastActivityAt ? item.lastActivityAt.toRelative() ?? $t('never') : $t('never') }}</p>
                </template>
                <template v-slot:expanded-row="{ item }" class="w-100">
                    <tr v-if="item.setupCompletedAt == null">
                        <td colspan="3">
                            <p class="text font-weight-bold text-red">{{ $t('this-family-has-not-completed-setup') }}</p>
                        </td>
                    </tr>
                    <v-card-subtitle v-if="item.setupCompletedAt !== null">Statistikk</v-card-subtitle>
                    <tr v-if="item.setupCompletedAt !== null">
                        <td colspan="3">
                            <v-row dense>
                                <v-col>{{ $t('members') }}</v-col>
                                <v-col>{{ item.statistics?.users_count }}</v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>{{ $t('persons') }}</v-col>
                                <v-col>{{ item.statistics?.persons_count }}</v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>{{ $t('budget-categories') }}</v-col>
                                <v-col>{{ item.statistics?.budget_categories_count }}</v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>{{ $t('budget-accounts') }}</v-col>
                                <v-col>{{ item.statistics?.budget_accounts_count }}</v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>{{ $t('money-accounts') }}</v-col>
                                <v-col>{{ item.statistics?.money_accounts_count }}</v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>{{ $t('vouchers') }}</v-col>
                                <v-col>{{ item.statistics?.vouchers_count }}</v-col>
                            </v-row>

                        </td>
                    </tr>
                    <v-container class="full-width">
                    </v-container>
                </template>
            </v-data-table>
        </template>
        <template v-slot:main v-if="currentView == 'notes'">
            <v-container>
                <v-row>
                    <v-col>
                        <v-btn
                            variant="outlined"
                            color="primary"
                            @click="openNewUserNote()"
                        >{{ $t('new-note') }}</v-btn>
                    </v-col>
                </v-row>

            </v-container>
            <v-data-table
                v-model="notesTable.selected.value"
                density="comfortable"
                v-model:items-per-page="notesTable.itemsPerPage.value"
                :loading="notesTable.loading.value"
                :headers="notesTable.headers.value"
                :items="user.notes"
                @click:row="openUserNote"
                hover
                :search="notesTable.search.value"
                v-model:sort-by="notesTable.sortBy.value"
                :no-data-text="$t('no-notes')"
            >
                <template v-slot:item.date="{ item }">
                    <p class="font-weight-bold">{{ $d(item.date.toJSDate(), 'short') }}</p>
                </template>
            </v-data-table>
        </template>
        <template v-slot:main v-if="currentView == 'devices'">
            <v-data-table
                v-model="devicesTable.selected.value"
                density="comfortable"
                v-model:items-per-page="devicesTable.itemsPerPage.value"
                :loading="devicesTable.loading.value"
                :headers="devicesTable.headers.value"
                :items="user.devices"
                hover
                :search="devicesTable.search.value"
                v-model:sort-by="devicesTable.sortBy.value"
                :no-data-text="$t('no-devices')"
            >
                <template v-slot:item.deviceOs="{ item }">
                    <td>
                        <v-sheet class="d-flex flex-wrap">
                            <v-icon v-if="item.deviceOs == 'MacOS'">mdi-apple</v-icon>
                            <v-icon v-if="item.deviceOs == 'iPhone'">mdi-apple</v-icon>
                            <v-icon v-if="item.deviceOs == 'iPad'">mdi-apple</v-icon>
                            <v-icon v-if="item.deviceOs == 'Windows'">mdi-microsoft-windows</v-icon>
                            <p>{{ item.deviceOs }}</p>
                        </v-sheet>
                    </td>
                </template>
                <template v-slot:item.userAgent="{ item }">
                    <td>
                        <v-sheet class="d-flex flex-wrap">

                                <v-icon v-if="item.userAgent == 'Chrome'">mdi-google-chrome</v-icon>
                                <v-icon v-if="item.userAgent == 'Opera'">mdi-opera</v-icon>
                                <v-icon v-if="item.userAgent == 'Edge'">mdi-microsoft-edge</v-icon>
                                <v-icon v-if="item.userAgent == 'Safari'">mdi-apple-safari</v-icon>
                                <v-icon v-if="item.userAgent == 'Firefox'">mdi-firefox</v-icon>
                                <img
                                    src="/images/appikon.png"
                                    v-if="item.userAgent == 'Factum ios app'"
                                    class="ma-0 pa-0"
                                    style="height: 21px; width: 21px; object-fit: cover;"
                                />
                                <p>{{ item.userAgent }}</p>
                        </v-sheet>
                    </td>
                </template>
                <template v-slot:item.isMobile="{ item }">
                    <v-icon v-if="item.isMobile">mdi-cellphone</v-icon>
                    <v-icon v-else>mdi-monitor</v-icon>
                </template>
                <template v-slot:item.createdAt="{ item }">
                    <p class="font-weight-bold">{{ $d(item.createdAt.toJSDate(), 'short') }}</p>
                </template>
            </v-data-table>
        </template>
    </fa-dialog>

    <FamilyDialog ref="familyDialog" />
    <UserNoteDialog
        ref="userNoteDialog"
        @did-save-new-note="didSaveNewNote"
        @did-save-note="didSaveNote"
        @did-delete-note="didDeleteNote"
    />
    <v-snackbar
        v-model="showVerificationEmailSentBar"
        color="success"
        :location="mobile ? 'top' : 'bottom'"
    >
        {{ $t("email-was-sent") }}.
    </v-snackbar>
    <v-snackbar
        v-model="showSnackBar"
        color="success"
        :location="mobile ? 'top' : 'bottom'"
    >
        {{ snackBarMessage }}.
    </v-snackbar>
</template>

<script setup lang="ts">
import { ref, Ref, computed } from 'vue';
import { User } from '../../models/User';
import { useDisplay } from 'vuetify';
import FamilyDialog from '../families/FamilyDialog.vue';
import { Family } from '../../models/Family';
import { useI18n } from 'vue-i18n';
import UserNoteDialog from './UserNoteDialog.vue';
import { UserNote } from '../../models/UserNote';

const open = ref(false);

const { mobile } = useDisplay();

const { t } = useI18n();
const currentView = ref("main");
const user: Ref<User|null> = ref(null);
const shouldDisableUser = ref(false);
const blockUserFormValid = ref(false);
const showSnackBar = ref(false);
const snackBarMessage = ref("");

defineExpose({
    openUser,
})

function openUser(_user: User)
{
    currentView.value = 'main';
    user.value = _user;
    user.value.getProfilePicture();
    getFamilyStatistics();
    user.value.getNotes();
    user.value.getDevices();
    open.value = true;
}

function openUserNote(index, { item })
{
    userNoteDialog.value.openNote(item);
}

function openNewUserNote()
{
    userNoteDialog.value.openNewNote(user.value);
}

function didSaveNewNote(note)
{
    user.value.notes.push(note);
}

function didSaveNote(note)
{
    let index = user.value.notes.map((item) => {return item.id}).indexOf(note.id);
    let oldNote = user.value.notes[index];
    oldNote.copyFrom(note);
}

function didDeleteNote(note)
{
    let index = user.value.notes.map((item) => {return item.id}).indexOf(note.id);

    if (index > -1) {
        user.value.notes.splice(index, 1);
    }
}

const familyDialog = ref(null);
const userNoteDialog = ref(null);

function openFamily(index, { item })
{
    familyDialog.value.openFamily(item);
}

// Family table
const familyTable = new class{
    selected = ref([]);
    itemsPerPage = ref(20);
    search = ref("");
    sortBy = ref([]);
    loading = ref(false);

    headers = computed(() => {
        if (mobile.value) {
            return [
                {title: t('name'), align: 'start', sortable: true, key: 'familyName'},
                {title: t('last-activity'), align: 'start', sortable: true, key: 'lastActivityAt', class: 'text-right'},
            ]
        }
        return [
            {title: t('name'), align: 'start', sortable: true, key: 'familyName'},
            {title: t('last-activity'), align: 'start', sortable: true, key: 'lastActivityAt'},
        ];

    });
};

// Notes table
const notesTable = new class{
    selected = ref([]);
    itemsPerPage = ref(20);
    search = ref("");
    sortBy = ref([{key: 'date', order: 'desc'}]);
    loading = ref(false);

    headers = computed(() => {
        if (mobile.value) {
            return [
                {title: t('date'), align: 'start', sortable: true, key: 'date', width: '50px'},
                {title: t('title'), align: 'start', sortable: true, key: 'title'},
            ]
        }
        return [
            {title: t('date'), align: 'start', sortable: true, key: 'date', width: '50px'},
            {title: t('title'), align: 'start', sortable: true, key: 'title'},
        ];

    });
};

// Notes table
const devicesTable = new class{
    selected = ref([]);
    itemsPerPage = ref(20);
    search = ref("");
    sortBy = ref([{key: 'createdAt', order: 'desc'}]);
    loading = ref(false);

    headers = computed(() => {
        if (mobile.value) {
            return [
                {title: t('os'), align: 'start', sortable: true, key: 'deviceOs'},
                {title: t('agent'), align: 'start', sortable: true, key: 'userAgent'},
                {title: t('device'), align: 'start', sortable: true, key: 'isMobile'},
                {title: t('login-date'), align: 'start', sortable: true, key: 'createdAt'},
            ]
        }
        return [
            {title: t('os'), align: 'start', sortable: true, key: 'deviceOs'},
            {title: t('agent'), align: 'start', sortable: true, key: 'userAgent'},
            {title: t('device'), align: 'start', sortable: true, key: 'isMobile'},
            {title: t('login-date'), align: 'start', sortable: true, key: 'createdAt'},
        ];

    });
};



function getFamilyStatistics()
{
    user.value?.families?.forEach((family) => {
        family.getStatistics()
    });
}

const showVerificationEmailSentBar = ref(false);
function resendVerificationEmail()
{
    user.value.resendVerificationEmail().then(() => {
        showVerificationEmailSentBar.value = true;
    })
}

function disable()
{
    user.value.disableUser(user.value.disableMessage).then(() => {
        snackBarMessage.value = t('the-user-was-disabled');
        showSnackBar.value = true;
    })
}

function enable()
{
    user.value.enableUser().then(() => {
        snackBarMessage.value = t('the-user-was-enabled');
        showSnackBar.value = true;
        shouldDisableUser.value = false;
    })
}



</script>

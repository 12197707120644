
<template>
    <v-list-item
        :title="props.project.projectName"
        :subtitle="props.project.description"
        append-icon="mdi-chevron-right"
        @click="$router.push({name: 'Project', params: {id: props.project.id}})"
    >
            <template v-slot:default>
                <v-chip
                    class="mt-auto mb-auto"
                    :color="project.projectResult > 0 ? 'success' : 'error'"
                    variant="outlined"
                    size="default"
                    density="compact"
                >
                    {{ $n(project.projectResult, 'currency', currency) }}
                </v-chip>

            </template>
    </v-list-item>
    <v-divider />

</template>

<script setup lang="ts">
import { Project } from '../../../models/Project';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '../../../stores/authStore';
import { computed } from 'vue';

    const props = defineProps({
        project: {
            type: [Project, Object],
            required: true,
            default: null
        }
    });

    const authStore = useAuthStore();
    const currency = computed(() => authStore.currentFamily.currency);

    const { t } = useI18n();
</script>

<template>
    <v-text-field
        ref="input"
        type="date"
        v-model="displayValue"
        v-bind:label="label"
        v-bind:placeholder="placeholder"
        v-bind:readonly="readonly"
        v-bind:disabled="disabled"
        v-bind:density="density"
        v-bind:variant="variant"
        v-bind:hide-details="hideDetails"
        v-bind:error="error"
        v-bind:error-messages="errorMessages"
        v-bind:rules="rules"
        v-bind:clearable="clearable"
        v-bind:backgroundColor="backgroundColor"
        v-bind:inputmode="inputmode"
        v-bind:width="width"
        prepend-inner-icon="mdi-calendar"
        hideDetails="auto"
    ></v-text-field>
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import { width } from 'vuetify';
export default {
    props: {
        modelValue: {
            default: DateTime.now()
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: "outlined",
        },
        density: {
            type: String,
            default: "compact",
        },
        error: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
        errorMessages: {
            type: [Array, String],
            default: () => [],
        },
        rules: {
            type: [Array, String],
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: "white",
        },
        inputmode: {
            type:String,
            default: 'text'
        },
        width: {
            type: [String, Number],
            default: null
        }
    },
    data: () => ({
        hasFocus: false,
    }),
    /*
     v-model="cmpValue": Dessa forma, ao digitar, o valor é atualizado automaticamente no componente pai.
     O valor digitado entra pelo newValue do Set e é emitido para o componente pai.
     the-vue-mask nao funciona corretamente ao incluir valores existentes no componente pai.
    */
    computed: {
        displayValue: {
            get: function () {
                if (this.modelValue) {
                    return this.modelValue.toFormat('yyyy-MM-dd')
                } else {
                    return null;
                }

            },
            set: function (newValue) {
                if (newValue == null) {
                    this.$emit("update:modelValue", null);
                }
                let date = DateTime.fromISO(newValue);
                if (date.isValid) {
                    this.$emit("update:modelValue", date);
                } else {
                    this.$emit("update:modelValue", null);
                }
            },
        },
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        }
    }

};
</script>
<style lang="scss">
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
</style>

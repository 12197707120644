import axios from "axios";
import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { BudgetAccountCategory } from '../models/BudgetAccountCategory';
import { BudgetAccount } from '../models/BudgetAccount';
import { Account } from "../models/Account";
import { AccountBudgetEvent } from '../models/AccountBudgetEvent';

export const useBudgetStore = defineStore('BudgetStore', {
    state: () => {
        return {
            categories: [],
            accounts: [],
            allAccounts: [],
            accountBudgets: [],
            accountBudgetEvents: [],
            upcomingEvents: [],
            selectedCategory: null,
            selectedAccount: null,
            isLoadingCategories: false,
            isLoadingAccounts: false,
            isLoadingAllAccounts: false,
            isLoadingAccountBudgets: false,
            isLoadingAccountBudgetEvents: false,
            isLoadingUpcomingEvents: false,
            isLoadingBudgetForecastChartData: false,
            isLoadingAccountedResultChartData: false,
            isLoadingBudgetExpensesPieChartData: false,
            isLoadingBudgetAccountChartData: false,
            budgetForecastChartFutureMonths: null,
            accountedResultChartPreviousMonths: 5,
            charts: {
                budgetForecast: [],
                accountedResult: [],
                budgetExpenses: [],
            }
        }
    },
    actions: {
        async getCategories(): Promise<Array<BudgetAccountCategory>> {
            this.isLoadingCategories = true;

            return new Promise((resolve, reject) => {
                let authStore = useAuthStore();
                let familyId = authStore.currentFamily?.id;
                if (familyId === null || familyId == undefined) {
                    this.isLoadingCategories = false;
                    reject('No family set');
                }
                const url = BudgetAccountCategory.getRoute();

                axios.get(url, authStore.getQueryParams()).then(response => {
                    let categories: Array<BudgetAccountCategory> = BudgetAccountCategory.arrayFromJson(response.data, true);
                    this.categories = categories;
                    this.isLoadingCategories = false;
                    resolve(categories);
                }).catch(error => {
                    console.error(error);
                    this.isLoadingCategories = false;
                    reject(error);
                })
            })
        },

        addCategory(category: BudgetAccountCategory) {
            this.isLoadingCategories = true;
            const index = this.categories.findIndex((obj) => obj.id === category.id);

            if (index == -1) {
                this.categories.push(category);
            }

            this.isLoadingCategories = false;
        },

        addAccount(account: BudgetAccount) {
            this.isLoadingAccounts = true;
            const index = this.accounts.findIndex((obj: BudgetAccount) => obj.id === account.id);

            if (index == -1) {
                this.accounts.push(account);
            }

            const allAccountsIndex = this.allAccounts.findIndex((obj) => obj.id === account.id);

            if (allAccountsIndex == -1) {
                this.allAccounts.push(account);
            }

            if (this.selectedCategory?.id == account.category.id) {
                let index = this.selectedCategory.accounts.findIndex((obj) => obj.id === account.id)
                if (index == -1) {
                    this.selectedCategory.accounts.push(account);
                }
            }

            this.isLoadingAccounts = false;
        },

        async getAccounts(): Promise<Array<BudgetAccount>> {
            this.isLoadingAccounts = true;

            return new Promise<Array<BudgetAccount>>((resolve, reject) => {
                const authStore = useAuthStore();
                let familyId = authStore.currentFamily?.id;
                if (familyId === null || familyId == undefined) {
                    this.isLoadingAccounts = false;
                    reject("No family to load accounts from");
                }
                if (this.selectedCategory == null) {
                    this.isLoadingAccounts = false;
                    resolve([]);
                }
                const url = BudgetAccount.getRouteForCategory(this.selectedCategory);

                axios.get(url, authStore.getQueryParams()).then(response => {
                    let accounts = BudgetAccount.arrayFromJson(response.data, true)
                    this.accounts = accounts;
                    this.isLoadingAccounts = false;
                    resolve(accounts);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            })
        },

        removeAccount(account: BudgetAccount) {
            this.isLoadingAccounts = true;

            const index = this.accounts.findIndex((obj) => obj.id === account.id);

            if (index > -1) {
                this.accounts.splice(index, 1);
            }

            if (this.selectedAccount?.id == account.id) {
                this.selectedAccount = null;
            }

            if (this.selectedCategory?.id == account.category?.id) {
                let index = this.selectedCategory?.accounts.map(function(x) {return x.id; }).indexOf(account.id);
                if (index > -1) {
                    this.selectedCategory.accounts.splice(index, 1);
                }
            }

            const allAccountsIndex = this.allAccounts.findIndex((obj) => obj.id === account.id);

            if (index > -1) {
                this.allAccounts.splice(allAccountsIndex, 1);
            }
            this.isLoadingAccounts = false;
        },

        clearAccounts() {
            this.isLoadingAccounts = true;
            this.accounts = [];
            this.selectedAccount = null;
            this.isLoadingAccounts = false;
        },

        async deleteAccount(account) {
            this.isLoadingAccounts = true;
            let authStore = useAuthStore();
            let familyId = authStore.currentFamily?.id;

            if (familyId === null || familyId == undefined) {
                this.isLoadingAccounts = false;
                return;
            }

            if (account.id) {
                await axios.delete(`/api/families/${familyId}/account_categories/${account.category.id}/accounts/${account.id}`, account)
                .then (result => {
                    this.removeAccount(account);
                    this.isLoadingAccounts = false;
                });
            }
        },

        setSelectedCategory(category) {
            this.selectedCategory = category;
            this.selectedAccount = null;
            this.getAccounts();
        },
        setSelectedAccount(account: BudgetAccount) {
            this.selectedAccount = account;
        },

        async saveCategory(category) {
            this.isLoadingCategories = true;
            let authStore = useAuthStore();
            let familyId = authStore.currentFamily?.id;

            if (familyId === null || familyId == undefined) {
                this.isLoadingAccounts = false;
                return;
            }

            return new Promise ((resolve, reject) => {
                if (category.id) {
                    axios.put(`/api/families/${familyId}/account_categories/${category.id}`, category, authStore.getQueryParams())
                    .then(result => {
                        this.isLoadingCategories = false;
                        resolve({success: true, errors: null, data: result.data});
                    })
                    .catch(err => {
                        this.isLoadingCategories = false;
                        resolve({success: false, errors: err.response?.data?.errors});
                    })
                } else {
                    axios.post(`/api/families/${familyId}/account_categories`, category, authStore.getQueryParams())
                    .then(result => {

                        this.addCategory(BudgetAccountCategory.fromJson(result.data));
                        this.isLoadingCategories = false;
                        resolve({success: true, errors: null, data: result.data});
                    })
                    .catch(error => {
                        console.error(error);
                        this.isLoadingCategories = false;
                        resolve({success: false, errors: error.response?.data?.errors});
                    })
                }
            });
        },
        async getAccountedResultChartData(months: number|null) {
            let authStore = useAuthStore();
            const userId = authStore.user?.id;

            if (userId == undefined || userId == null) {
                return;
            }

            const familyId = authStore.currentFamily?.id;
            let futureMonths = 5;

            if (familyId === null || familyId == undefined) {
                this.isLoadingAccountedResultChartData = false;
                return;
            }

            if (!months) {
                let localstorageValue = localStorage.getItem(`${userId}-${familyId}_budget_forecast_chart_future_months`)
                if (localstorageValue) {
                    futureMonths = Number(localstorageValue);
                }
            } else {
                futureMonths = months;
            }
            this.isLoadingAccountedResultChartData = true;

            return new Promise ((resolve, reject) => {
                axios.get(`/api/families/${familyId}/budget/charts/result?months=${futureMonths}`, authStore.getQueryParams())
                .then(result => {
                    this.charts.accountedResult = result.data;
                    this.isLoadingAccountedResultChartData = false;
                    resolve({success: true, errors: null, data: result.data});
                })
                .catch(err => {
                    console.error(err);
                    this.isLoadingAccountedResultChartData = false
                    resolve({success: false, errors: err.response?.data?.errors});
                })
            });
        },
        async getBudgetForecastChartData(months: number|null) {
            let authStore = useAuthStore();
            const userId = authStore.user?.id;

            if (userId == undefined || userId == null) {
                return;
            }
            const familyId = authStore.currentFamily?.id;
            let futureMonths = 5;

            if (familyId === null || familyId == undefined) {
                this.isLoadingAccountedResultChartData = false;
                return;
            }

            if (!months) {
                let localstorageValue = localStorage.getItem(`${userId}-${familyId}_budget_forecast_chart_future_months`)
                if (localstorageValue) {
                    futureMonths = Number(localstorageValue);
                }
            } else {
                futureMonths = months;
            }

            this.isLoadingBudgetForecastChartData = true;

            if (familyId === null || familyId == undefined) {
                this.isLoadingBudgetForecastChartData = false;
                return;
            }

            return new Promise ((resolve, reject) => {
                axios.get(`/api/families/${familyId}/budget/charts/forecast?months=${futureMonths}`, authStore.getQueryParams())
                .then(result => {
                    this.charts.budgetForecast = result.data;
                    this.isLoadingBudgetForecastChartData = false;
                    resolve({success: true, errors: null, data: result.data});
                })
                .catch(err => {
                    console.error(err);
                    this.isLoadingBudgetForecastChartData = false
                    resolve({success: false, errors: err.response?.data?.errors});
                })
            });
        },
        async getBudgetExpensesPieChartData() {
            this.isLoadingBudgetExpensesPieChartData = true;
            let authStore = useAuthStore();
            let familyId = authStore.currentFamily?.id;

            if (familyId === null || familyId == undefined) {
                this.isLoadingBudgetExpensesPieChartData = false;
                return;
            }

            return new Promise ((resolve, reject) => {
                axios.get(`/api/families/${familyId}/budget/charts/expenses`, authStore.getQueryParams())
                .then(result => {
                    this.charts.budgetExpenses = result.data;
                    this.isLoadingBudgetExpensesPieChartData = false;
                    resolve({success: true, errors: null, data: result.data});
                })
                .catch(err => {
                    console.error(err);
                    this.isLoadingBudgetExpensesPieChartData = false;
                    resolve({success: false, errors: err.response?.data?.errors});
                })
            });
        },

        async getUpcomingEvents() {
            this.isLoadingUpcomingEvents = true;
            let authStore = useAuthStore();
            let familyId = authStore.currentFamily?.id;

            if (familyId === null || familyId == undefined) {
                this.isLoadingUpcomingEvents = false;
                return;
            }

            return new Promise ((resolve, reject) => {
                axios.get(`/api/families/${familyId}/budget/upcoming-events`, authStore.getQueryParams())
                .then(result => {
                    this.upcomingEvents = AccountBudgetEvent.arrayFromJson(result.data, true);
                    this.isLoadingUpcomingEvents = false;
                    resolve(result.data);
                })
                .catch(err => {
                    console.error(err);
                    this.isLoadingUpcomingEvents = false;
                    reject(err);
                })
            });
        },

        async getAllAccounts(): Promise<Array<Account>> {
            this.isLoadingAllAccounts = true;

            return new Promise((resolve, reject) => {
                let authStore = useAuthStore();
                let familyId = authStore.currentFamily?.id;
                if (familyId === null || familyId == undefined) {
                    this.isLoadingAllAccounts = false;
                    reject("No family selected")
                }

                axios.get(`/api/families/${familyId}/accounts`, authStore.getQueryParams())
                    .then(result => {
                        let accounts = Account.arrayFromJson(result.data, true);
                        this.allAccounts = accounts;
                        this.isLoadingAllAccounts = false;
                        resolve(accounts);
                    })
                    .catch(error => {
                        console.error(error);
                        this.isLoadingAllAccounts = false;
                        reject();
                    });
            });
        },

        async getBudgetAccountAccountedAmountChartData(account: BudgetAccount): Promise<any> {
            this.isLoadingBudgetAccountChartData = true;
            let authStore = useAuthStore();
            let familyId = authStore.currentFamily?.id;

            if (familyId === null || familyId == undefined) {
                this.isLoadingBudgetAccountChartData = false;
                return;
            }

            return new Promise ((resolve, reject) => {
                axios.get(`/api/families/${familyId}/accounts/${account.id}/accounted-amount-chart-data`, authStore.getQueryParams())
                .then(result => {
                    this.isLoadingBudgetAccountChartData = false;
                    resolve(result.data);
                })
                .catch(err => {
                    console.error(err);
                    this.isLoadingBudgetAccountChartData = false;
                    reject({success: false, errors: err.response?.data?.errors});
                })
            });
        },
    },
});

import { Voucher } from "../models/Voucher";
import { VoucherLine } from '../models/VoucherLine';
import { DateTime } from 'luxon';

export class Transaction
{
    public voucherId: number|null = null;
    public lineId: number|null = null;
    public date: DateTime|null = DateTime.now();
    public projectName: string = "";
    public description: string = "";
    public debit: number|null = null;
    public credit: number|null = null;

    constructor() {

    }

    /**
     * Creates a new Transaction from json data
     * @param json An object holding the data to parse
     * @returns Transaction
     */
    static fromJson(json: any): Transaction
    {
        const obj = new Transaction();
        obj.voucherId = json.voucher_id;
        obj.lineId = json.line_id;
        obj.date = DateTime.fromISO(json.date);
        obj.projectName = json.project_name;
        obj.description = json.description;
        obj.debit = json.debit;
        obj.credit = json.credit;


        return obj;
    }

    /**
     * Creates a new Transaction from a voucher and a voucher line
     * @param voucher The voucher to get data from
     * @param voucherLine The voucherLine to get data from
     * @returns Transaction
     */
        static fromVoucher(voucher: Voucher, voucherLine: VoucherLine): Transaction
        {
            const obj = new Transaction();
            obj.voucherId = voucher.id;
            obj.lineId = voucherLine.id;
            obj.date = voucher.date;
            obj.projectName = voucher.projectName;
            obj.description = voucher.description;
            obj.debit = voucherLine.debit;
            obj.credit = voucherLine.credit;


            return obj;
        }

    /**
     * Returns an array of Transaction from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any): Array<Transaction>
    {
        const array = new Array<Transaction>();

        json.forEach(item => {
            const obj = Transaction.fromJson(item);
            array.push(obj);
        });

        return array;
    }
}

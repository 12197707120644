<template>
    <v-autocomplete
        v-model:model-value="value"
        :items="accounts"
        item-value="id"
        item-title="accountName"
        variant="outlined"
        density="compact"
        auto-select-first
        item-props
        open-on-clear
        :label="label ?? ''"
        clearable
        :rules="rules"
        :no-data-text="$t('no-accounts')"
        hideDetails="auto"
    >
        <template v-slot:chip="{ item }">
            <v-icon class="mr-5">{{ item.raw.iconName }}</v-icon>{{  item.raw.accountName }}
        </template>
        <template v-slot:item="{ item, props: {onClick} }">
            <v-list-item
                :prepend-icon="item.raw.iconName"
                :title="item.raw.accountName"
                @click="onClick"
            >
                <template v-slot:append v-if="Object.values(BudgetAccountType).includes(item.raw.accountType)">
                    <BudgetAccountProgressIndicator :account="item.raw" size="40" />
                </template>
                <template v-slot:append v-else>
                    <p class="text-subtitle-2">{{ $n(item.raw.balance, 'currency', currency) }}</p>
                </template>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script setup lang="ts">
import { Ref } from 'vue';
import { computed } from 'vue';
import { useBudgetStore } from '@stores/budgetStore';
import { Account } from '../../models/Account';
import BudgetAccountProgressIndicator from '@components/BudgetAccountProgressIndicator.vue';
import { BudgetAccountType } from '@models/BudgetAccount';
import { useAuthStore } from '../../stores/authStore';

    const props = defineProps([
        'modelValue',
        'label',
        'rules',
    ])
    const budgetStore = useBudgetStore();
    const accounts: Ref<Array<Account>> = computed(() => budgetStore.allAccounts);

    const emits = defineEmits(['update:modelValue'])

    const value = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            emits('update:modelValue', value);
        }
    })

    const authStore = useAuthStore();
    const currency = computed(() => authStore.currentFamily.currency);

</script>

import axios from "axios";
import { useAuthStore } from "@stores/authStore";
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from 'notifications/ApiEvent';
import NotificationCenter from 'notifications/NotificationCenter';
import { DateTime } from 'luxon';
import { User } from "./User";
import { Family } from './Family';
import { Voucher } from "./Voucher";

/**
 * A model representing the data for Project.
 */
export class Project implements ApiNotificationObserver
{
    public id: number|null = null;
    public family: Family|null = null;
    public projectName: string = "";
    public startDate: DateTime|null = null;
    public endDate: DateTime|null = null;
    public description: string = "";
    public isCompleted: boolean = false;
    public projectResult: number = 0;
    public projectTotalIncome: number = 0;
    public projectTotalExpenses: number = 0;
    public projectExpenses: [] = [];
    public projectIncome: [] = [];

    public createdBy: User;
    public createdAt: string;
    public updatedBy: User;
    public updatedAt: string;

    public vouchers: Array<Voucher> = [];

    public observeEvents: ApiEvent[] = [
        ApiEvent.ProjectCreated,
        ApiEvent.ProjectUpdated,
        ApiEvent.ProjectDeleted,
        ApiEvent.VoucherCreated,
        ApiEvent.VoucherUpdated,
        ApiEvent.VoucherDeleted,
    ];

    private _isSaving: boolean = false;
    private _isLoadingVouchers: boolean = false;


    /**
     * Creates a new empty model
     * @param observe boolean Wheter to add observers to the model or not
     */
    constructor(observe: boolean = true)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new Project model from json data
     * @param json An object holding the data to parse
     * @returns Project
     */
    static fromJson(json: any, observe: boolean = true): Project
    {
        const obj = new Project(observe);
        obj.id = json.id;
        if (json.family) {
            obj.family = Family.fromJson(json.family);
        }
        obj.projectName = json.project_name;

        if (json.start_date) {
            obj.startDate = DateTime.fromISO(json.start_date);
        }

        if (json.end_date) {
            obj.endDate = DateTime.fromISO(json.end_date);
        }

        obj.description = json.description;
        obj.isCompleted = json.is_completed;

        if (json.created_by) {
            obj.createdBy = User.fromJson(json.created_by);
        }
        obj.createdAt = json.created_at;

        if (json.updated_by) {
            obj.updatedBy = User.fromJson(json.updated_by);
        }
        obj.updatedAt = json.updated_at;
        obj.projectResult = json.project_result;
        obj.projectTotalIncome = json.project_total_income;
        obj.projectTotalExpenses = json.project_total_expenses;
        obj.projectExpenses = json.project_expenses;
        obj.projectIncome = json.project_income;

        return obj;
    }

    /**
     * Returns an array of Projects from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = true): Array<Project>
    {
        const array = new Array<Project>();

        json.forEach((item: any) => {
            const obj = Project.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the route for projects or a specific project if set
     * @param project Project|null the project to get route to
     * @returns string
     */
    static getRoute(project: Project|null = null): string
    {
        const familyId = useAuthStore().currentFamily.id;

        if (project?.id) {
            return `/api/families/${familyId}/projects/${project.id}`;
        }

        return `/api/families/${familyId}/projects`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<Project>
     */
    public save(): Promise<Project>
    {
        this._isSaving = true;
        const authStore = useAuthStore();

        return new Promise<Project>((resolve, reject) => {
            if (this.id) {
                axios.put(Project.getRoute(this), this.toJson(), authStore.getQueryParams()).then(response => {
                    const obj = Project.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(Project.getRoute(), this.toJson(), authStore.getQueryParams()).then(response => {
                    const obj = Project.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<Project>
     */
    public delete(): Promise<Project>
    {
        return new Promise<Project>((resolve, reject) => {
            if (this.id == null) {
                resolve(this);
            } else {
                axios.delete(Project.getRoute(this)).then(() => {
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        })
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    private toJson() : object
    {
        return {
            'id': this.id,
            'project_name': this.projectName,
            'start_date': this.startDate?.toFormat('yyyy-MM-dd'),
            'end_date': this.endDate?.toFormat('yyyy-MM-dd'),
            'description': this.description,
            'is_completed': this.isCompleted,
        };
    }

    public copy(observe: boolean = false): Project
    {
        let copy = new Project(observe);
        copy.copyFrom(this);

        return copy;
    }

    public copyFrom(obj: Project)
    {
        this.id = obj.id;
        this.family = obj.family;
        this.projectName = obj.projectName;
        this.startDate = obj.startDate;
        this.endDate = obj.endDate;
        this.description = obj.description;
        this.isCompleted = obj.isCompleted;
        this.createdBy = obj.createdBy;
        this.createdAt = obj.createdAt;
        this.updatedBy = obj.updatedBy;
        this.updatedAt = obj.updatedAt;
        this.projectResult = obj.projectResult;
        this.projectTotalIncome = obj.projectTotalIncome;
        this.projectTotalExpenses = obj.projectTotalExpenses;
        this.projectExpenses = obj.projectExpenses;
        this.projectIncome = obj.projectIncome;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        if (notification == ApiEvent.ProjectUpdated && data?.project.id == this.id) {
            let newProject = Project.fromJson(data.project);
            this.copyFrom(newProject);
        }

        if (notification == ApiEvent.VoucherCreated && data?.voucher.project_id == this.id) {
            let newVoucher = Voucher.fromJson(data.voucher);
            this.vouchers.push(newVoucher);
        }

        if (notification == ApiEvent.VoucherUpdated && data?.voucher.project_id == this.id) {
            let newVoucher = Voucher.fromJson(data.voucher);
            let index = this.vouchers.map((item) => {return item.id}).indexOf(newVoucher.id);
                if (index > -1) {
                    this.vouchers[index].copyFrom(newVoucher);
                } else {
                    this.vouchers.push(newVoucher);
                }
        }

        if (notification == ApiEvent.VoucherDeleted && data?.voucher.project_id == this.id) {
            let newVoucher = Voucher.fromJson(data.voucher);
            let index = this.vouchers.map((item) => {return item.id}).indexOf(newVoucher.id);
                if (index > -1) {
                    this.vouchers.splice(index, 1);
                }
        }
    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isLoadingVouchers(): boolean
    {
        return this._isLoadingVouchers;
    }

    /**
     * Fetches vouchers related to the project from the API
     * @returns
     */
    public getVouchers(): Promise<Array<Voucher>>
    {
        this._isLoadingVouchers = true;
        return new Promise<Array<Voucher>>((resolve, reject) => {
            let url = Project.getRoute(this) + '/vouchers';
            axios.get(url).then(response => {
                let vouchers = Voucher.arrayFromJson(response.data, true);

                this.vouchers = vouchers;
                this._isLoadingVouchers = false;
                resolve(vouchers);
            }).catch(error => {
                console.error(error);
                this._isLoadingVouchers = false;
                reject(error);
            })
        })
    }

}

<template>
     <v-app-bar
        app
        :density="mobile ? 'compact' : 'default'"
        class="device-padding-top"
    >
    <v-app-bar-nav-icon @click="$emit('toggle-drawer')" v-if="!shouldShowWizard"></v-app-bar-nav-icon>
    <div style="display: flex; justify-content: center; align-items: center;" v-if="!mobile">
        <img src="/images/appikon.png" style="height: 40px;" />
        <img src="/images/logo_text.png" style="height: 60px;" />
    </div>
    <v-toolbar-title>
    </v-toolbar-title>
    <family-selector />
    <period-selector v-if="!shouldShowWizard"/>
    </v-app-bar>
</template>

<script setup>
import { useDisplay, useTheme } from 'vuetify';
import { useAuthStore } from '@stores/authStore';
import PeriodSelector from './PeriodSelector.vue';
import { computed } from 'vue';

const authStore = useAuthStore();

const { mobile } = useDisplay()

const notificationCount = computed(() => {
    return 0;
})

const hasNotifications = computed(() => {
    return false;
})

const shouldShowWizard = computed(() => {
    return authStore.shouldShowWizard();
});

const theme = useTheme();

function toggleTheme() {
    if (theme.global.current.value.dark) {
        theme.global.name.value = 'light';
        authStore.darkMode = false;
    } else {
        theme.global.name.value = 'dark';
        authStore.darkMode = true;
    }
}

const { logout } = authStore;

</script>

<style>
 .device-padding-top {
        padding-top: env(safe-area-inset-top, 100px);
    }
</style>

import axios from "axios";
import { useAuthStore } from "@stores/authStore";
import { BudgetAccount } from './BudgetAccount';
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';

/**
 * A model representing the data for AccountBudget.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accounts/{account}/budgets
 */
export class AccountBudget implements ApiNotificationObserver
{

    public id: number| null = null;
    public account: BudgetAccount|null = null;
    public budgetAmount: number = 0;
    public budgetRecurrencyInterval: number = 1;
    public budgetRecurrencyCycle: BudgetRecurrencyCycle = BudgetRecurrencyCycle.MONTH;
    public budgetStartAt: DateTime = DateTime.now();
    public budgetEndAt: DateTime|null = null;

    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;
    private _original: Object = {};

    public observeEvents: ApiEvent[] = [
        ApiEvent.AccountBudgetUpdated,
    ];

    /**
     * Creates a new empty AccountBudget object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new AccountBudget model from json data
     * @param json An object holding the data to parse
     * @returns AccountBudget
     */
    static fromJson(json: any, observe: boolean = false): AccountBudget
    {
        const obj = new AccountBudget(observe);
        obj.id = json.id;
        obj.budgetAmount = Number(json.budget_amount);
        obj.budgetRecurrencyInterval = json.budget_recurrency_interval;
        obj.budgetRecurrencyCycle = json.budget_recurrency_cycle;
        obj.budgetStartAt = DateTime.fromISO(json.budget_start_at);

        if (json.budget_end_at) {
            obj.budgetEndAt = DateTime.fromISO(json.budget_end_at);
        } else {
            obj.budgetEndAt = null;
        }

        if (json.account) {
            let account = BudgetAccount.fromJson(json.account, true);
            obj.account = account
        }

        obj._original = JSON.parse(JSON.stringify(obj));

        return obj;
    }

    /**
     * Returns an array of AccountBudgets from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<AccountBudget>
    {
        const array = new Array<AccountBudget>();

        json.forEach(item => {
            const obj = AccountBudget.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(account: BudgetAccount, budgetId: number|null = null): string
    {
        const familyId = useAuthStore().currentFamily.id;
        const categoryId = account.category.id;
        const accountId = account.id;

        if (budgetId !== null) {
            return `/api/families/${familyId}/account_categories/${categoryId}/accounts/${accountId}/budgets/${budgetId}`;
        }

        return `/api/families/${familyId}/account_categories/${categoryId}/accounts/${accountId}/budgets`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<AccountBudget>
     */
    public save(): Promise<AccountBudget>
    {
        this._isSaving = true;
        return new Promise<AccountBudget>((resolve, reject) => {
            if (this.id) {
                axios.put(AccountBudget.getRoute(this.account, this.id), this.toJson()).then(response => {
                    const obj = AccountBudget.fromJson(response.data);
                    Object.assign(this, obj);
                    this._original = obj;
                    this._isSaving = false;
                    NotificationCenter.shared().notifyManually(ApiEvent.AccountBudgetUpdated, {accountBudget: this.toJson()});
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(AccountBudget.getRoute(this.account), this.toJson()).then(response => {
                    const obj = AccountBudget.fromJson(response.data);
                    Object.assign(this, obj);
                    this._original = obj;
                    this._isSaving = false;
                    NotificationCenter.shared().notifyManually(ApiEvent.AccountBudgetCreated, {accountBudget: this.toJson()});
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<AccountBudget>
     */
    public delete(): Promise<AccountBudget>
    {
        this._isDeleting = true;
        return new Promise<AccountBudget>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(AccountBudget.getRoute(this.account, this.id)).then(response => {
                    const obj = AccountBudget.fromJson(response.data);
                    Object.assign(this, obj);
                    this._isDeleting = false;
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    /**
     * Copies the data from a AccountBudget into this instance
     * @param obj BudgetAccount
     */
    public copyFrom(obj: AccountBudget, deep: boolean = true)
    {
        this.id = obj.id;
        this.account = obj.account;
        this.budgetAmount = obj.budgetAmount;
        this.budgetRecurrencyInterval = obj.budgetRecurrencyInterval;
        this.budgetRecurrencyCycle = obj.budgetRecurrencyCycle;
        this.budgetStartAt = obj.budgetStartAt;
        this.budgetEndAt = obj.budgetEndAt;
    }

    /**
     * Returns a copy of the AccountBudget
     */
    public copy(observe: boolean = false): AccountBudget
    {
        let copy = new AccountBudget(observe);
        copy.copyFrom(this);

        return copy;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    private toJson() : object
    {
        return {
            'id': this.id,
            'budget_amount': this.budgetAmount,
            'budget_recurrency_interval': this.budgetRecurrencyInterval,
            'budget_recurrency_cycle': this.budgetRecurrencyCycle,
            'budget_start_at': this.budgetStartAt?.toFormat('yyyy-MM-dd'),
            'budget_end_at': this.budgetEndAt ? this.budgetEndAt.toFormat('yyyy-MM-dd') : null,
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        if (notification == ApiEvent.AccountBudgetUpdated && data?.accountBudget.id == this.id) {
            let newBudget = AccountBudget.fromJson(data.accountBudget);
            this.copyFrom(newBudget);
        }
    }

    public registerObservers() {
        NotificationCenter.shared().registerObserver(this);
    }

}

enum BudgetRecurrencyCycle {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
}

<template>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        persistent
        width="auto"
      >
        <v-card
          color="primary"
          style="min-width: 250px; min-height: 50px;"
        >
          <v-card-text class="text-center">
            {{ text }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-1 mt-3"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>

  <script>
  export default {
    props: {
        text: String,
    },
    data () {
      return {
        dialog: true,
      }
    },

    watch: {
    },
  }
</script>

import './bootstrap';

// Creating the application
import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue'
import AppBar from './layouts/components/AppBar.vue'
import FamilySelectorVue from './layouts/components/FamilySelector.vue';
import PersonSelector from '/resources/js/components/PersonSelector.vue'
import CurrencyTextField from "/resources/js/components/CurrencyTextField.vue";
import DateTextField from "/resources/js/components/DateTextField.vue";
import FADialog from "/resources/js/components/FADialog.vue";
import EmptyState from "/resources/js/components/EmptyState.vue";
import IconSelector from "@components/IconSelector.vue";
import * as Sentry from "@sentry/vue";

const app = createApp(App);
app.component('app-bar', AppBar);
app.component('family-selector', FamilySelectorVue);
app.component('person-selector', PersonSelector);
app.component('currency-text-field', CurrencyTextField);
app.component('date-text-field', DateTextField);
app.component('fa-dialog', FADialog);
app.component('icon-selector', IconSelector);
app.component('empty-state', EmptyState);

import { i18n } from './i18n';
app.use(i18n);

//Setting up moment
import moment from 'moment/moment';
app.use(moment);
window.moment = moment;

// Setting up Axios
import axios from 'axios';
axios.defaults.baseURL = import.meta.env.APP_URL;
axios.defaults.headers['Accept'] = 'application/json';
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest'
};

axios.interceptors.response.use(response => {
    return response;
 }, error => {
    if (error.response?.status === 401) {
        location.reload();
    } else {
        throw error;
    }
 });

//Setting up Pinia
import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);

import { vuetify } from './factumVuetify.js'
import router from './router.js'

// Setting up router
app.use(router);
// Setting up vuetify
app.use(vuetify);

Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.mount("#app");

<template>
    <router-view v-if="isLoaded"/>
    <div v-else style="height: 100dvh; display: flex; justify-content: center; z-index: 10000; background-color: #d9ebee;">
        <v-img src="./images/logo_animated_pulse.gif" class="mt-auto mb-auto" max-width="400"/>
    </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@stores/authStore';
import { computed, ref, watch } from 'vue';
import { useTheme } from 'vuetify/lib/framework.mjs';
import NotificationCenter from 'notifications/NotificationCenter';
import { useI18n } from 'vue-i18n';
import SystemEventObserver from './notifications/SystemEventObserver';

var isLoaded = ref(false);

const authStore = useAuthStore();
document.body.onfocus = ((e) => {
    authStore.fetchRequiredData();
    NotificationCenter.shared().reconnect();
})

const currentFamily = computed(() => authStore.currentFamily);
const user = computed(() => authStore.user);

const { initCurrentFamily } = authStore;
const { initCurrentPeriod } = authStore;
const theme = useTheme();
const { locale } = useI18n();

function initColorScheme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // dark mode
        theme.global.name.value = 'dark'
        authStore.darkMode = true;
    } else {
        // light mode
        theme.global.name.value = 'light'
        authStore.darkMode = false;
    }
}

function setLanguage()
{
    if (authStore.user) {
        locale.value = user.value.language;
    } else {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (timezone === "Europe/Oslo") {
            locale.value = "nb";
        } else {
            locale.value = "en";
        }
    }
}
setLanguage();
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    const newColorScheme = e.matches ? "dark" : "light";

    if (newColorScheme == "dark") {
        theme.global.name.value = 'dark';
        authStore.darkMode = true;
    } else {
        theme.global.name.value = 'light'
        authStore.darkMode = false;
    }
});

watch(currentFamily, (newVal, oldVal) => {
    if (newVal?.id == oldVal?.id) {
        return;
    }
    if (newVal == null) {
        return;
    }
    if (user == undefined || user == null) {
        return;
    }
    if (user.value) {
        localStorage.setItem(user.value.id + '_current_family_id', newVal?.id);
    }

    if (oldVal !== null) {
        //Unsubscribe
        NotificationCenter.shared().unsubscribe();
    }

    if (newVal !== null) {
        //Subscribe
        NotificationCenter.shared().subscribe();
    }
})

watch(user, (newVal, oldVal) => {
    setLanguage();
})

const token = localStorage.getItem('token');

if (token && token != 'undefined') {
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
}

authStore.isLoggedIn().then(isLoggedIn => {
    if (isLoggedIn) {
        authStore.getMe().then(() => {
            NotificationCenter.shared().subscribe();
            setLanguage();
            initCurrentPeriod();
            initCurrentFamily();
            initColorScheme();
            authStore.postDeviceInfo();
            isLoaded.value = true;
        }).catch(error => {
            console.error(error);
            isLoaded.value = true;
        })
    } else {
        isLoaded.value = true;
    }
}).catch(error => {
    console.error(error);
    isLoaded.value = true;
})

// IMPORTANT
//THIS NEEDS TO STAY HERE TO ADD SYSTEM OBSERVER!!
const systemEventObserver = new SystemEventObserver();
</script>

<template>
<div id="app">
    <v-app v-if="user">
        <app-bar color="main" @toggle-drawer="drawer = !drawer"/>
        <v-navigation-drawer
            v-model="drawer"
            v-if="!shouldShowWizard()"
            width="350"
            color="main"
            style="padding-top: env(safe-area-inset-top);"
        >
            <v-list>
                <v-list-group v-if="user.isSuperAdmin" value="Superadmin">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                            :title="$t('super-admin')"
                            v-bind="props"
                            prepend-icon="mdi-shield-crown"
                            color="red"
                            base-color="red"
                        />
                    </template>
                    <v-list-item
                        :title="$t('home')"
                        prepend-icon="mdi-home"
                        :to="{name: 'Superadmin'}"
                        router
                    />
                    <v-list-item
                        :title="$t('users')"
                        prepend-icon="mdi-account"
                        :to="{name: 'Users'}"
                        router
                    />
                    <v-list-item
                        :title="$t('families')"
                        prepend-icon="mdi-account-group"
                        to=""
                        router
                    />
                    <v-list-item
                        :title="$t('feedbacks')"
                        prepend-icon="mdi-chat-question-outline"
                        :to="{name: 'Feedbacks'}"
                        router
                    />
                </v-list-group>
                <v-divider v-if="user.isSuperAdmin"/>
                <v-list-group value="Users">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                            :title="user.fullName"
                            :subtitle="user.email"
                            v-bind="props"
                            :prepend-avatar="user.profilePicture"
                        >
                        </v-list-item>
                    </template>
                    <v-list-item
                        :title="$t('my-profile')"
                        prepend-icon="mdi-account"
                        to="/profile"
                        router
                    ></v-list-item>
                    <v-list-item
                        :title="$t('settings')"
                        prepend-icon="mdi-cog"
                        to="/user-settings"
                        router
                    ></v-list-item>
                    <v-list-item
                        :title="$t('my-families')"
                        prepend-icon="mdi-account-group"
                        to="/families"
                        router
                    ></v-list-item>
                    <v-list-item
                        :title="$t('logout')"
                        prepend-icon="mdi-lock"
                        @click="authStore.logout"
                    >

                    </v-list-item>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-item
                    v-for="(page, i) in routes"
                    :key="i"
                    :to="page.path"
                    router
                    exact
                    :title="page.title"
                    :prepend-icon="page.icon"
                    v-show="page.visible"
                />
            </v-list>
        </v-navigation-drawer>
        <v-main :class="shouldShowWizard() ? 'bg-main' : ''">
            <FamilySetupWizard v-if="shouldShowWizard()"/>
            <router-view v-slot="{ Component, route }" v-else>
                <v-slide-x-transition>
                    <div :key="route.name">
                        <component :is="Component" />
                    </div>
                </v-slide-x-transition>
            </router-view>
        </v-main>
        <v-bottom-navigation v-if="mobile && !shouldShowWizard()" bg-color="main" class="device-padding-bottom">
            <v-btn @click="$router.push('/')" class="pa-0">
                <v-icon>mdi-home</v-icon>
                <span>{{ $t('home') }}</span>
            </v-btn>

            <v-btn @click="$router.push('/budget')" class="pa-0">
                <v-icon>mdi-chart-pie</v-icon>
                <span>{{ $t('budget') }}</span>
            </v-btn>

            <v-btn :to="'/accounting'" class="pa-0">
                <v-icon>mdi-bookshelf</v-icon>
                <span>{{ $t('accounting') }}</span>
            </v-btn>
            <v-btn :to="'/money'" class="pa-0">
                <v-icon>mdi-bank</v-icon>
                <span>{{ $t('money') }}</span>
            </v-btn>
            <v-btn :to="{name: 'Superadmin'}" v-if="user.isSuperAdmin" class="pa-0">
                <v-icon color="red">mdi-shield-crown</v-icon>
                <span class="text-red">{{ $t('admin') }}</span>
            </v-btn>
        </v-bottom-navigation>
        <v-snackbar
            v-model="showFamilySwitchSnackbar"
            location="center"
            color="info"
            timeout="2000"
            transition="fade-transition"
        >
        <v-row>
            <v-col class="text-center">
                <v-icon size="x-large">mdi-account-group</v-icon>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-center">
                <p class="text-h6">Byttet familie</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-center">
                {{ $t("switched-to") }} {{ currentFamily.familyName }}
            </v-col>
        </v-row>
        </v-snackbar>
    </v-app>
</div>

</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';
import AppBar from './components/AppBar.vue';
import { useAuthStore } from '@stores/authStore';
import FamilySetupWizard from '../components/family-setup-wizard/FamilySetupWizard.vue';
import { ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { watch } from 'vue';
import { computed } from 'vue';

let drawer: Ref<boolean> = ref(true);
let showFamilySwitchSnackbar: Ref<boolean> = ref(false);

let authStore = useAuthStore();
let user = authStore.user;
let currentFamily = computed(() => authStore.currentFamily);
let shouldShowWizard = authStore.shouldShowWizard;

drawer.value = ! useDisplay().smAndDown.value;
const { mobile } = useDisplay();

const { t } = useI18n();
let routes = computed(() => [
        {
            title: t('home'),
            path: '/',
            icon: 'mdi-home-account',
            auth: ['admin', 'user'],
            sublinks: [],
            visible: true,
        },
        {
            title: t('budget'),
            path: '/budget',
            icon: 'mdi-chart-pie',
            auth: ['admin'],
            visible: true,
        },
        {
            title: t('accounting'),
            path: '/accounting',
            icon: 'mdi-bookshelf',
            auth: ['admin'],
            visible: true,
        },
        {
            title: t('money'),
            path: '/money',
            icon: 'mdi-bank',
            auth: ['admin'],
            visible: true,
        },
        {
            title: t('projects'),
            path: {name: 'Projects'},
            icon: 'mdi-file-link',
            auth: ['admin'],
            visible: true,
        },
        {
            title: 'Factus',
            path: {name: 'Assistant'},
            icon: 'mdi-robot-excited',
            auth: ['admin'],
            visible: currentFamily.value.isAiActivated
        },
    ]);

watch(currentFamily, (newVal, oldVal) => {
    if (currentFamily && newVal) {
        showFamilySwitchSnackbar.value = true;
    }
});
</script>

<style>
    .device-padding-bottom {
        padding-bottom: env(safe-area-inset-bottom);
        height: calc(env(safe-area-inset-bottom) + 56px)!important;
    }
    .logo-container {
        margin-top: auto;
    }
</style>

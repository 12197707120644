import { mapState } from 'pinia';
import { useAuthStore } from '../stores/authStore';
<template>
    <div>
        <v-text-field
            v-model="displayValue"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:density="density"
            v-bind:variant="variant"
            v-bind:hide-details="hideDetails"
            v-bind:error="error"
            v-bind:error-messages="errorMessages"
            v-bind:rules="rules"
            v-bind:clearable="clearable"
            v-bind:backgroundColor="backgroundColor"
            v-bind:inputmode="inputmode"
            v-on:blur="onBlur"
            v-on:focus="onFocus"
            hideDetails="auto"
        ></v-text-field>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useAuthStore } from '@stores/authStore';
export default {
    props: {
        modelValue: {
            // type: String,
            type: [Number, String],
            default: 0,
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: "outlined",
        },
        density: {
            type: String,
            default: "compact",
        },
        error: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
        errorMessages: {
            type: [Array, String],
            default: () => [],
        },
        rules: {
            type: [Array, String],
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: "white",
        },
        inputmode: {
            type:String,
            default: 'text'
        }
    },
    data: () => ({
        hasFocus: false,
    }),
    /*
     v-model="cmpValue": Dessa forma, ao digitar, o valor é atualizado automaticamente no componente pai.
     O valor digitado entra pelo newValue do Set e é emitido para o componente pai.
     the-vue-mask nao funciona corretamente ao incluir valores existentes no componente pai.
    */
    computed: {
        ...mapState(useAuthStore, {
            currentFamily: 'currentFamily'
        }),
        displayValue: {
            get: function () {
                if (this.hasFocus) {
                    let value = parseFloat(this.modelValue) ?? 0;
                    if (value == 0) {
                        return null;
                    }

                    return value;
                }
                return this.$n(parseFloat(this.modelValue) ?? 0, "currency", this.currentFamily.currency);
            },
            set: function (newValue) {
                if(isNaN(this.cleanNumber(newValue)) == false) {
                    this.$emit("update:modelValue", this.cleanNumber(newValue));
                }
            },
        },
    },
    methods: {
        onBlur() {
            this.hasFocus = false;
        },
        onFocus() {
            this.hasFocus = true;
        },
        cleanNumber: function (value) {
            if (value == 0) {
                return 0;
            }
            if (value == null || value == '') {
                return 0;
            }
            let result = "";
            if (value) {
                let flag = false;
                let arrayValue = value.toString().split("");
                for (var i = 0; i < arrayValue.length; i++) {
                    if (this.isInteger(arrayValue[i])) {
                        if (!flag) {
                            // Retirar zeros à esquerda
                            if (arrayValue[i] !== "0") {
                                result = result + arrayValue[i];
                                flag = true;
                            }
                        } else {
                            result = result + arrayValue[i];
                        }
                    } else if (arrayValue[i] == '.' || arrayValue[i] == ',') {
                        result = result + '.';
                    }
                }
            }
            return parseFloat(result);
        },
        isInteger(value) {
            let result = false;
            if (Number.isInteger(parseInt(value))) {
                result = true;
            }
            return result;
        },
    },
};
</script>

export const light = {
    dark: false,
    colors: {
        background: '#fff',
        surface: "#fff",
        primary: '#00778b',
        main: "#d9ebee",
        info: '#ADDFFF',
        accent: '#95B0B7',
        gray: "#909090",
        success:"#008B5A",
        error: "#8B1400",
    }
}

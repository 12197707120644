<template>
<v-dialog
        v-model="props.open"
        transition="dialog-bottom-transition"
        :fullscreen="fullscreen"
        :max-width="width"
        :height="fullscreen ? '100%' : height"
        scrollable
        @click:outside="emits('close')"
    >
        <v-card height="70vh" :loading="isLoading">
            <!--HEADER-->
            <v-container fluid class="pa-0 elevation-4" style="z-index:999999">
                <v-row>
                    <v-col>
                        <v-card
                            color="main"
                            height="40"
                            flat
                            class="rounded-0"
                            :style="fullscreen ? 'padding-top: env(safe-area-inset-top)!important; height: calc(env(safe-area-inset-top) + 40px)!important;' : ''"
                        />
                        <v-icon
                            v-if="showCloseButton"
                            style="margin-top: -70px; margin-left: 15px"
                            @click="emits('close')"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <!--Icon and title-->
                <v-row style="margin-top: -60px" no-gutters dense>
                    <v-col align="center">
                        <v-avatar color="accent" size="x-large">
                            <v-img :src="iconImage" v-if="iconImage"/>
                            <v-icon size="x-large" v-else>
                                {{ icon }}
                            </v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-row no-gutters dense>
                    <v-col align="center">
                        <slot name="title"></slot>
                        <v-card-title v-if="!hasSlot('title')">{{ props.title }}</v-card-title>
                    </v-col>
                </v-row>
                <slot name="subtitle"></slot>
                <v-row v-if="props.subtitle && !hasSlot('subitle')">
                    <v-col>
                        <v-card-subtitle>
                            {{ props.subtitle }}
                        </v-card-subtitle>
                    </v-col>
                </v-row>
                <!--Buttons in header-->
                <v-card-actions v-if="hasSlot('header-buttons')">
                    <v-container fluid class="pa-0">
                        <slot name="header-buttons"></slot>
                    </v-container>
                </v-card-actions>

                <!--TAB-BAR-->
                <v-row no-gutters dense v-if="hasSlot('tabs')">
                    <v-col align="center">
                        <slot name="tabs"></slot>
                    </v-col>
                </v-row>
            </v-container>
            <!--Dialog content-->
            <v-container fluid :class="removeMargins ? 'pa-0 h-100' : 'h-100'" style="overflow-y: auto; overflow-x: hidden;">

                <slot name="main"></slot>
                <slot name="bottom-main"></slot>
            </v-container>
            <slot name="footer"></slot>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { useSlots } from "vue";
import { computed } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
        default: false,
    },
    title: {
        type: [String, null],
        required: true,
        default: null
    },
    subtitle: {
        type: [String, null],
        required: false,
        default: null
    },
    showCloseButton: {
        type: Boolean,
        default: true
    },
    width: {
        type: [Number, String],
        default: 500
    },
    height: {
        type: [Number, String],
        default: 500,
    },
    fullscreen: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
        default: "",
    },
    iconImage: {
        type: [String, null],
        default: null
    },
    removeMargins: {
        type: Boolean,
        default: false,
    }
});

const emits = defineEmits([
    'close'
]);

const slots = useSlots()

function hasSlot(name) {
    return !!slots[name];
  }

</script>

import axios from "axios";
import { defineStore } from "pinia";
import { useAuthStore } from "./authStore.js";

export const namespaced = true
export const useCalculationStore = defineStore('CalculationStore', {
    state:() => {
        return {
            budgetResult: {
                'budget_total_income': 0,
                'budget_total_variable_expenses': 0,
                'budget_total_fixed_expenses': 0,
            },
            accountingResult: {
                'accounted_total_income': 0,
                'accounted_total_variable_expenses': 0,
                'accounted_total_fixed_expenses': 0,
            },
            currentMoneyBalance: 0,
            liquidityRatio: 0,
            overconsumptionChartData: [],
            isLoadingOverconsumptionChartData: false,
            isLoadingResults: false,
            isLoadingLiquidityRatio: false,
        }
    },

    actions: {
        async getTotals() {
            this.isLoadingResults = true;

            const authStore = useAuthStore();
            let familyId = authStore.currentFamily?.id;

            if (familyId === null || familyId == undefined) {
                this.isLoadingResults = false;
                return;
            }

            axios.get(`/api/families/${familyId}/accounting/totals`, authStore.getQueryParams()).then(result => {
                this.accountingResult = result.data.accounted;
                this.budgetResult = result.data.budget;
                this.currentMoneyBalance = result.data.current_money_balance;
                this.isLoadingResults = false;
            }).catch((error: any) => {
                console.error(error);
                this.isLoadingResults = false;
            })
        },

        getLiquidityRatio() {
            this.isLoadingLiquidityRatio = true;

            const authStore = useAuthStore();
            let familyId = authStore.currentFamily?.id;

            return new Promise((resolve, reject) => {
                if (familyId === null || familyId == undefined) {
                    this.isLoadingLiquidityRatio = false;
                    resolve(null);
                }

                axios.get(`/api/families/${familyId}/calculations/liquidity-ratio`, authStore.getQueryParams()).then(result => {
                    this.liquidityRatio = result.data.liquidity_ratio;
                    this.isLoadingLiquidityRatio = false;
                    resolve(this.liquidityRatio);
                }).catch((error: any) => {
                    console.error(error);
                    this.isLoadingLiquidityRatio = false;
                    reject(error);
                })
            })
        },

        getOverconsumptionChartData() {
            this.isLoadingOverconsumptionChartData = true;

            const authStore = useAuthStore();
            let familyId = authStore.currentFamily?.id;

            return new Promise((resolve, reject) => {
                if (familyId === null || familyId == undefined) {
                    this.isLoadingOverconsumptionChartData = false;
                    resolve(null);
                }

                axios.get(`/api/families/${familyId}/calculations/overconsumption-chart`, authStore.getQueryParams()).then(result => {
                    this.overconsumptionChartData = result.data;
                    this.isLoadingOverconsumptionChartData = false;
                    resolve(this.overconsumptionChartData);
                }).catch((error: any) => {
                    console.error(error);
                    this.isLoadingOverconsumptionChartData = false;
                    reject(error);
                })
            })
        },

        getAccountedResult()
        {
            return this.accountingResult.accounted_total_income - this.accountingResult.accounted_total_variable_expenses - this.accountingResult.accounted_total_fixed_expenses;
        },

        getBudgetResult()
        {
            return this.budgetResult.budget_total_income - this.budgetResult.budget_total_variable_expenses - this.budgetResult.budget_total_fixed_expenses;
        }
    },
});

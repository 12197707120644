import axios from "axios";
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';
import { User } from './User';

/**
 * A model representing the data for UserDevice.
 * The model-data can be retrieved from /api/users/id/notes
 */
export class UserDevice implements ApiNotificationObserver
{
    public id: number|null = null;
    public user: User|null = null;
    public deviceOs: string|null = null;
    public userAgent: string|null = null;
    public isMobile: boolean|null = null;

    public createdAt: DateTime|null = null;

    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;

    public observeEvents: ApiEvent[] = [

    ];

    /**
     * Creates a new empty UserDevice object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new UserDevice model from json data
     * @param json An object holding the data to parse
     * @returns UserDevice
     */
    static fromJson(json: any, observe: boolean = false): UserDevice
    {
        const obj = new UserDevice(observe);

        obj.id = json.id;

        if (json.user) {
            obj.user = User.fromJson(json.user);
        }

        obj.deviceOs = json.device_os;
        obj.userAgent = json.user_agent;
        obj.isMobile = json.is_mobile;

        if (json.created_at) {
            obj.createdAt = DateTime.fromISO(json.created_at);
        }

        return obj;
    }

    /**
     * Returns an array of Familys from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<UserDevice>
    {
        const array = new Array<UserDevice>();

        json.forEach((item: any) => {
            const obj = UserDevice.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(user: User, device: UserDevice|null = null): string
    {
        if (device?.id == null) {
            return `/api/users/${user.id}/devices`;
        } else {
            return `/api/users/${user.id}/devices/${device.id}`;
        }

    }


    /**
     * Copies the data from a UserDevice into this instance
     * @param obj Account
     */
    public copyFrom(obj: UserDevice)
    {
        this.id = obj.id;
        this.user = obj.user;
        this.deviceOs = obj.deviceOs;
        this.userAgent = obj.userAgent;
        this.isMobile = obj.isMobile;
        this.createdAt = obj.createdAt;
    }

    public copy(observe: boolean = false): UserDevice
    {
        let copy = new UserDevice(observe);
        copy.copyFrom(this);

        return copy;
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {

    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }
}

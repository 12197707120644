import axios from "axios";
import { defineStore } from "pinia";
import { useAuthStore } from "./authStore.js";
import { Voucher } from "../models/Voucher";
import { Transaction } from "../types/Transaction";
import { MoneyAccount } from '../models/MoneyAccount';

export const namespaced = true
export const useAccountingStore = defineStore('AccountingStore', {
    state:() => {
        return {
            vouchers: [],
            isLoadingVouchers: false,
            isLoadingVoucherLines: false,
            isLoadingTransactions: false,
            isSavingVoucher: false,
        }
    },

    actions: {
        getVouchers(payload): Promise<Array<Voucher>> {
            this.isLoadingVouchers = true;

            return new Promise((resolve, reject) => {
                const authStore = useAuthStore();

                let familyId = authStore.currentFamily.id;
                if (familyId === null) {
                    this.isLoadingVouchers = false;
                    reject();
                }
                axios.get(`/api/families/${familyId}/vouchers`, {params: payload})
                .then(result => {
                    this.vouchers = Voucher.arrayFromJson(result.data, true);
                    this.isLoadingVouchers = false;
                    resolve(result.data);
                })
                .catch(error => {
                    console.error(error);
                    this.isLoadingVouchers = false;
                    reject(error);
                })
            });
        },

        getVoucherById(id): Promise<Voucher> {
            this.isLoadingVouchers = true;

            return new Promise<Voucher>((resolve, reject) => {
                const authStore = useAuthStore();

                let familyId = authStore.currentFamily.id;
                if (familyId === null) {
                    this.isLoadingVouchers = false;
                    reject();
                }
                let existing = this.vouchers.find(obj => {
                    return obj.id == id;
                })

                if (existing) {
                    resolve(existing);
                }

                axios.get(`/api/families/${familyId}/vouchers/${id}`)
                .then(result => {
                    this.isLoadingVouchers = false;
                    let voucher = Voucher.fromJson(result.data);
                    resolve(voucher);
                })
                .catch(error => {
                    console.error(error);
                    this.isLoadingVouchers = false;
                    reject(error);
                })
            });
        },

        addVoucher(voucher: Voucher): void {
            let exists = this.vouchers.find(obj => obj.id === voucher.id);
            if (exists) {
                Object.assign(exists, voucher);
            } else {
                this.vouchers.push(voucher);
            }
        },

        updateVoucher(voucher: Voucher): void {
            let old = this.vouchers.find(obj => obj.id === voucher.id);
            if (old) {
                Object.assign(old, voucher);
            }
        },

        removeVoucher(voucher: Voucher): void {
            const index = this.vouchers.findIndex((obj) => obj.id === voucher.id);

            if (index > -1) {
                this.vouchers.splice(index, 1);
            }
        },

        deleteVoucher(voucher): Promise<null> {
            this.isLoadingVouchers = true;

            const authStore = useAuthStore();
            let familyId = authStore.currentFamily.id;

            if (familyId === null) {
                this.isLoadingVouchers = false;
                return;
            }

            return new Promise((resolve, reject) => {
                axios.delete(Voucher.getRoute(voucher), authStore.getQueryParams())
                .then(result => {
                    this.removeVoucher(voucher);
                    this.isLoadingVouchers = false;
                    resolve(null);
                })
                .catch(error => {
                    console.error(error);
                    this.isLoadingVouchers = false;
                    reject(error);
                })
            });
        },

        getTransactions(payload): Promise<Array<Transaction>> {
            this.isLoadingTransactions = true;
            let authStore = useAuthStore();
            let familyId = authStore.currentFamily.id;
            if (familyId === null) {
                this.isLoadingTransactions = false;
                return;
            }

            return new Promise<Array<Transaction>>((resolve, reject) => {
                axios.get(MoneyAccount.getRoute(payload.account) + `/transactions?from=${payload.from}&to=${payload.to}`)
                .then(result => {
                    this.isLoadingTransactions = false;
                    resolve(Transaction.arrayFromJson(result.data));
                })
                .catch(error => {
                    console.error(error);
                    this.isLoadingTransactions = false;
                    reject(error);
                })
            });
        },

        getTransactionsForVoucher(payload): Promise<Array<Transaction>> {
            this.isLoadingTransactions = true;

            return new Promise<Array<Transaction>>((resolve, reject) => {
                let authStore = useAuthStore();
                let familyId = authStore.currentFamily.id;
                if (familyId === null) {
                    this.isLoadingTransactions = false;
                    reject();
                }
                axios.get(MoneyAccount.getRoute(payload.account) + `/transactions/${payload.voucher.id}`)
                .then(result => {
                    this.isLoadingTransactions = false;
                    resolve(Transaction.arrayFromJson(result.data));
                })
                .catch(error => {
                    console.error(error);
                    this.isLoadingTransactions = false;
                    reject(error);
                })
            });
        },
    },
});

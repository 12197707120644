<template>
    <v-progress-circular
        :model-value="progress().toFixed()"
        :size="props.size"
        :width="Number(props.size) / 10"
        style="font-size: 10px"
        :color="progressColor()"
    >
        {{ progress().toFixed(0) }}%
    </v-progress-circular>
</template>

<script setup lang="ts">
import { BudgetAccount } from '@models/BudgetAccount';


let props = defineProps({
    account: {
        type: [BudgetAccount, Object],
        required: true,
    },
    size: {
        type: [Number, String],
        default: "60"
    },
})

function progress(): Number {
    let budget = props.account.currentBudgetAmount;
    let accounted = props.account.currentAccountedAmount;

    if (budget == 0 && accounted > 0) {
        return 100;
    }

    if (budget == 0) {
        return 0;
    }

    return Number((accounted / budget) * 100);
}

function progressColor() {
    let percent = parseInt(progress().toFixed(0));

    if (percent < 80) {
        return "green";
    } else if (percent < 90) {
        return "warning";
    } else {
        return "error";
    }
}

</script>

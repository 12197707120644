export enum ApiEvent {
    BudgetChanged = 'budget-changed',
    AccountedResultChanged = 'result-changed',

    BudgetAccountCategoryCreated = 'account-category-created',
    BudgetAccountCategoryUpdated = 'account-category-updated',

    AccountCreated = 'account-created',
    AccountUpdated = 'account-updated',
    AccountDeleted = 'account-deleted',

    AccountBudgetCreated = 'account-budget-created',
    AccountBudgetUpdated = 'account-budget-updated',
    AccountBudgetDeleted = 'account-budget-deleted',

    AccountBudgetEventCreated = 'account-budget-event-created',
    AccountBudgetEventUpdated = 'account-budget-event-updated',
    AccountBudgetEventDeleted = 'account-budget-event-deleted',

    VoucherCreated = 'voucher-created',
    VoucherUpdated = 'voucher-updated',
    VoucherDeleted = 'voucher-deleted',

    UserUpdated = 'user-updated',
    UserSettingUpdated = 'user-setting-updated',
    UserDisabled = 'user-disabled',
    UserNotificationSettingUpdated = 'user-notification-setting-updated',

    FamilyUpdated = 'family-updated',

    FamilyInvitationCreated = 'family-invitation-created',

    ProjectCreated = 'project-created',
    ProjectUpdated = 'project-updated',
    ProjectDeleted = 'project-deleted',

}

import axios from "axios";
import { useAuthStore } from "@stores/authStore";
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from 'notifications/ApiEvent';

/**
 * A model representing the data for VoucherLine.
 */
export class VoucherLine implements ApiNotificationObserver
{

    public id: number = null;
    public voucherId: number = null;
    public accountId: number = null;
    public accountName: string = null;
    public note: string = null;
    public credit: number = 0;
    public debit: number = 0;
    public deletedAt: string = null;

    public observeEvents: ApiEvent[] = [
    ];


    /**
     * Creates a new empty model
     * @param observe boolean Wheter to add observers to the model or not
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new VoucherLine model from json data
     * @param json An object holding the data to parse
     * @returns VoucherLine
     */
    static fromJson(json: any, observe: boolean = false): VoucherLine
    {
        const obj = new VoucherLine(observe);
        obj.id = json.id;
        obj.voucherId = json.voucher_id;
        obj.accountId = json.account_id;
        obj.accountName = json.account_name;
        obj.note = json.note;
        obj.credit= Number(json.credit);
        obj.debit = Number(json.debit);

        return obj;
    }

    /**
     * Returns an array of VoucherLines from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<VoucherLine>
    {
        const array = new Array<VoucherLine>();

        json.forEach(item => {
            const obj = VoucherLine.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the route for VoucherLines or a specific VoucherLine if set
     * @param VoucherLine VoucherLine|null the VoucherLine to get route to
     * @returns string
     */
    static getRoute(voucherLine: VoucherLine|null = null): string
    {
        const familyId = useAuthStore().currentFamily.id;

        if (voucherLine?.id !== null) {
            return `/api/families/${familyId}/vouchers/${voucherLine.voucherId}/voucher_lines/${voucherLine.id}`;
        }

        return `/api/families/${familyId}/vouchers/${voucherLine.voucherId}/voucher_lines/`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<VoucherLine>
     */
    public save(): Promise<VoucherLine>
    {
        return new Promise<VoucherLine>((resolve, reject) => {
            if (this.id) {
                axios.put(VoucherLine.getRoute(this), this.toJson()).then(response => {
                    const obj = VoucherLine.fromJson(response.data);
                    Object.assign(this, obj);
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            } else {
                axios.post(VoucherLine.getRoute(), this.toJson()).then(response => {
                    const obj = VoucherLine.fromJson(response.data);
                    Object.assign(this, obj);
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<VoucherLine>
     */
    public delete(): Promise<VoucherLine>
    {
        return new Promise<VoucherLine>((resolve, reject) => {
            if (this.id == null) {
                resolve(this);
            } else {
                axios.delete(VoucherLine.getRoute(this)).then(response => {
                    const obj = VoucherLine.fromJson(response.data);
                    Object.assign(this, obj);
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            }
        })
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    public toJson() : object
    {
        return {
            'id': this.id,
            'voucher_id': this.voucherId,
            'account_id': this.accountId,
            'account_name': this.accountName,
            'note': this.note,
            'credit': Number(this.credit),
            'debit': Number(this.debit),
        };
    }

    public copyFrom(obj: VoucherLine)
    {
        obj.id = obj.id;
        obj.voucherId = obj.voucherId;
        obj.accountId = obj.accountId;
        obj.accountName = obj.accountName;
        obj.note = obj.note;
        obj.credit= obj.credit;
        obj.debit = obj.debit;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {

    }

    public registerObservers()
    {
    }

}

import axios from "axios";
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';
import { User } from "./User";
import { useAuthStore } from '../stores/authStore';

/**
 * A model representing the data for UserNotificationSetting.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accounts/{account}/budgets
 */
export class UserNotificationSetting implements ApiNotificationObserver
{
    public dailyAccountingReminderPush: boolean = true;
    public budgetExceededEmail: boolean = false;
    public budgetExceededPush: boolean = true;
    public voucherCreatedPush: boolean = true;

    private _isSaving: boolean = false;

    public observeEvents: ApiEvent[] = [
        ApiEvent.UserNotificationSettingUpdated,
    ];

    /**
     * Creates a new empty UserNotificationSetting object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new UserNotificationSetting model from json data
     * @param json An object holding the data to parse
     * @returns UserNotificationSetting
     */
    static fromJson(json: any, observe: boolean = false): UserNotificationSetting
    {
        const obj = new UserNotificationSetting(observe);
        obj.dailyAccountingReminderPush = json.daily_accounting_reminder_push;
        obj.budgetExceededEmail = json.budget_exceeded_email;
        obj.budgetExceededPush = json.budget_exceeded_push;
        obj.voucherCreatedPush = json.voucher_created_push;


        return obj;
    }

    /**
     * Returns an array of UserSettings from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<UserNotificationSetting>
    {
        const array = new Array<UserNotificationSetting>();

        json.forEach((item: any) => {
            const obj = UserNotificationSetting.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(user: User): string
    {
        if (user.id == null) {
            throw Error("Cannot get settings for unregistered users")
        }

        return `/api/users/${user.id}/notification-settings`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<UserNotificationSetting>
     */
    public save(): Promise<UserNotificationSetting>
    {
        this._isSaving = true;

        return new Promise<UserNotificationSetting>((resolve, reject) => {
            let authStore = useAuthStore();
            axios.put(UserNotificationSetting.getRoute(authStore.user), this.toJson()).then(response => {
                const obj = UserNotificationSetting.fromJson(response.data);
                this.copyFrom(obj);
                this._isSaving = false;
                resolve(this);
            }).catch(error => {
                console.error(error);
                this._isSaving = false;
                reject(error);
            })
        });
    }

    /**
     * Copies the data from a UserNotificationSetting into this instance
     * @param obj Account
     */
    public copyFrom(obj: UserNotificationSetting)
    {
        this.dailyAccountingReminderPush = obj.dailyAccountingReminderPush;
        this.budgetExceededEmail = obj.budgetExceededEmail;
        this.budgetExceededPush = obj.budgetExceededPush;
        this.voucherCreatedPush = obj.voucherCreatedPush;
    }

    public copy(observe: boolean = false): UserNotificationSetting
    {
        let copy = new UserNotificationSetting(observe);
        copy.copyFrom(this);

        return copy;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    public toJson() : object
    {
        return {
            'daily_accounting_reminder_push': this.dailyAccountingReminderPush,
            'budget_exceeded_email': this.budgetExceededEmail,
            'budget_exceeded_push': this.budgetExceededPush,
            'voucher_created_push': this.voucherCreatedPush,
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        if (notification == ApiEvent.UserNotificationSettingUpdated) {
            let obj = UserNotificationSetting.fromJson(data.settings);
            this.copyFrom(obj);
        }
    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }
}

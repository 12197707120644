import axios from "axios";
import { useAuthStore } from "@stores/authStore";
import { BudgetAccount } from './BudgetAccount';
import { DateTime } from "luxon";
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from "notifications/ApiEvent";
import NotificationCenter from '../notifications/NotificationCenter';

/**
 * A model representing the data for AccountBudgetEvent.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accounts/{account}/budgets
 */
export class AccountBudgetEvent implements ApiNotificationObserver
{

    public id: number;
    public account: BudgetAccount;
    public date: DateTime = DateTime.now();
    public action: AccountBudgetEventAction = AccountBudgetEventAction.ADD;
    public amount: number = 0;
    public description: string = "";

    private _isSaving: boolean = false;
    private _isDeleting: boolean = false;
    private _original: Object = {};

    /**
     * Creates a new empty AccountBudgetEvent object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }
    public observeEvents: ApiEvent[] = [ApiEvent.AccountBudgetEventUpdated];

    /**
     * Creates a new AccountBudgetEvent model from json data
     * @param json An object holding the data to parse
     * @returns AccountBudgetEvent
     */
    static fromJson(json: any, observe: boolean = false): AccountBudgetEvent
    {
        const obj = new AccountBudgetEvent(observe);
        obj.id = json.id;
        obj.date = DateTime.fromISO(json.date);
        obj.action = json.action;
        obj.amount = Number(json.amount);
        obj.description = json.description;

        if (json.account) {
            let account = BudgetAccount.fromJson(json.account, true);
            obj.account = account
        }

        obj._original = JSON.parse(JSON.stringify(obj));

        return obj;
    }

    /**
     * Returns an array of AccountBudgetEvents from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<AccountBudgetEvent>
    {
        const array = new Array<AccountBudgetEvent>();

        json.forEach(item => {
            const obj = AccountBudgetEvent.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(account: BudgetAccount, budgetId: number|null = null): string
    {
        const familyId = useAuthStore().currentFamily.id;
        const categoryId = account.category.id;
        const accountId = account.id;
        if (budgetId !== null) {
            return `/api/families/${familyId}/account_categories/${categoryId}/accounts/${accountId}/budget_events/${budgetId}`;
        }

        return `/api/families/${familyId}/account_categories/${categoryId}/accounts/${accountId}/budget_events`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<AccountBudgetEvent>
     */
    public save(): Promise<AccountBudgetEvent>
    {
        this._isSaving = true;
        return new Promise<AccountBudgetEvent>((resolve, reject) => {
            if (this.id) {
                axios.put(AccountBudgetEvent.getRoute(this.account, this.id), this.toJson()).then(response => {
                    const obj = AccountBudgetEvent.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            } else {
                axios.post(AccountBudgetEvent.getRoute(this.account), this.toJson()).then(response => {
                    const obj = AccountBudgetEvent.fromJson(response.data);
                    this.copyFrom(obj);
                    this._isSaving = false;
                    resolve(this);
                }).catch(error => {
                    console.error(error);
                    this._isSaving = false;
                    reject(error);
                })
            }
        });
    }

    /**
     * Deletes the model through the API
     * @returns Promise<AccountBudgetEvent>
     */
    public delete(): Promise<AccountBudgetEvent>
    {
        this._isDeleting = true;
        return new Promise<AccountBudgetEvent>((resolve, reject) => {
            if (this.id == null) {
                this._isDeleting = false;
                resolve(this);
            } else {
                axios.delete(AccountBudgetEvent.getRoute(this.account, this.id)).then(response => {
                    const obj = AccountBudgetEvent.fromJson(response.data);
                    Object.assign(this, obj);
                    this._isDeleting = false;
                    resolve(obj);
                }).catch(error => {
                    console.error(error);
                    this._isDeleting = false;
                    reject(error);
                })
            }
        })
    }

    /**
     * Restores the object to the last saved version
     */
    public restore() {
        this.copyFrom(AccountBudgetEvent.fromJson(this._original));
    }

    public copyFrom(obj: AccountBudgetEvent)
    {
        this.id = obj.id;
        this.date = obj.date;
        this.action = obj.action;
        this.amount = obj.amount;
        this.description = obj.description;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    private toJson() : object
    {
        return {
            'id': this.id,
            'date': this.date.toFormat('yyyy-MM-dd'),
            'action': this.action,
            'amount': this.amount,
            'description': this.description,
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    get isDeleting(): boolean
    {
        return this._isDeleting;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        if (notification == ApiEvent.AccountBudgetEventUpdated && data?.accountBudgetEvent.id == this.id) {
            let newEvent = AccountBudgetEvent.fromJson(data.accountBudgetEvent);
            this.copyFrom(newEvent);
        }
    }

    public registerObservers() {
        NotificationCenter.shared().registerObserver(this);
    }

}

enum AccountBudgetEventAction {
    SET = "SET",
    ADD = "ADD",
    SUBTRACT = "SUB",
}

import axios from "axios";
import { defineStore } from "pinia";
import { Ref, ref } from 'vue';
import { UserFeedback } from '../models/UserFeedback';

export const useFeedbackStore = defineStore('FeedbackStore', () => {
    let feedbacks: Ref<Array<UserFeedback>> = ref([]);

    function getFeedbacks(): Promise<Array<UserFeedback>>
    {
        return new Promise<Array<UserFeedback>>((resolve, reject) => {
            let url = '/api/feedback';

            axios.get(url).then(result => {
                const fetchedResult = UserFeedback.arrayFromJson(result.data);
                feedbacks.value = fetchedResult;

                resolve(feedbacks.value);
            }).catch(error => {
                console.error(error);
                reject(error);
            })

        })
    }
    return {
        feedbacks,
        getFeedbacks,
    }
})

import axios from "axios";
import { DateTime } from 'luxon';
import { ApiNotificationObserver } from '../interfaces/ApiNotificationObserver';
import { ApiEvent } from '../notifications/ApiEvent';
import NotificationCenter from '../notifications/NotificationCenter';
import { User } from "./User";
import { useAuthStore } from '../stores/authStore';

/**
 * A model representing the data for UserSetting.
 * The model-data can be retrieved from /api/families/{family}/account_categories/{account_category}/accounts/{account}/budgets
 */
export class UserSetting implements ApiNotificationObserver
{
    public useSimpleLanguage: boolean = false;

    private _isSaving: boolean = false;

    public observeEvents: ApiEvent[] = [
        ApiEvent.UserSettingUpdated,
    ];

    /**
     * Creates a new empty UserSetting object
     */
    constructor(observe: boolean = false)
    {
        if (observe) {
            this.registerObservers();
        }
    }

    /**
     * Creates a new UserSetting model from json data
     * @param json An object holding the data to parse
     * @returns UserSetting
     */
    static fromJson(json: any, observe: boolean = false): UserSetting
    {
        const obj = new UserSetting(observe);
        obj.useSimpleLanguage = json.use_simple_language;

        return obj;
    }

    /**
     * Returns an array of UserSettings from a json data holding an array of objects
     * @param json An array holding the json objects to include
     * @returns
     */
    static arrayFromJson(json: any, observe: boolean = false): Array<UserSetting>
    {
        const array = new Array<UserSetting>();

        json.forEach((item: any) => {
            const obj = UserSetting.fromJson(item, observe);
            array.push(obj);
        });

        return array;
    }
    /**
     * Returns the relative route to the index route of accounts within a category
     * @param categoryId The ID of the category to get accounts from
     * @returns string
     */
    static getRoute(user: User): string
    {
        if (user.id == null) {
            throw Error("Cannot get settings for unregistered users")
        }

        return `/api/users/${user.id}/user-settings`;
    }

    /**
     * Posts or puts the object to the API, returning a promise of the received model
     * @returns Promise<UserSetting>
     */
    public save(): Promise<UserSetting>
    {
        this._isSaving = true;

        return new Promise<UserSetting>((resolve, reject) => {
            let authStore = useAuthStore();
            axios.put(UserSetting.getRoute(authStore.user), this.toJson()).then(response => {
                const obj = UserSetting.fromJson(response.data);
                this.copyFrom(obj);
                this._isSaving = false;
                resolve(this);
            }).catch(error => {
                console.error(error);
                this._isSaving = false;
                reject(error);
            })
        });
    }

    /**
     * Copies the data from a UserSetting into this instance
     * @param obj Account
     */
    public copyFrom(obj: UserSetting)
    {
        this.useSimpleLanguage = obj.useSimpleLanguage;
    }

    public copy(observe: boolean = false): UserSetting
    {
        let copy = new UserSetting(observe);
        copy.copyFrom(this);

        return copy;
    }

    /**
     * Returns a json object of the model
     * @returns object
     */
    public toJson() : object
    {
        return {
            'use_simple_language': this.useSimpleLanguage,
        };
    }

    get isSaving(): boolean
    {
        return this._isSaving;
    }

    public didReceiveNotification(notification: ApiEvent, data: any) {
        if (notification == ApiEvent.UserSettingUpdated) {
            let obj = UserSetting.fromJson(data.settings);
            this.copyFrom(obj);
        }
    }

    public registerObservers()
    {
        NotificationCenter.shared().registerObserver(this);
    }
}

<template>
    <v-card
        @click.stop="open=true"
        height="50"
        width="100%"
        flat
        border
        style="margin-bottom: 22px;"
    >
        <v-card-text class=" ma-0 fill-height d-flex justify-center align-center center-text">
            <v-row align="center">
                <v-col cols="2">
                    <v-icon size="40">{{ modelValue ?? 'mdi-image-plus-outline' }}</v-icon>
                </v-col>
                <v-col class="">
                    <p v-if="!modelValue">{{ $t('select-an-icon') }}</p>
                    <p v-else @click.stop="removeIcon()">{{ $t('remove-icon') }}</p>

                </v-col>
            </v-row>

        </v-card-text>
    </v-card>
    <fa-dialog
        :open="open"
        :title="$t('select-icon')"
        icon="mdi-image"
        @close="open=false"
        :fullscreen="mobile"
    >
    <template v-slot:header-buttons>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    v-model="searchString"
                    density="compact"
                    :label="$t('search')"
                    append-inner-icon="mdi-magnify"
                    single-line
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>
    </template>

    <template v-slot:main>
            <v-row no-gutters>
                <v-col v-for="icon in filteredIcons" class="ma-1" cols="2">
                    <v-icon
                        @click.stop="didSelectIcon(icon)"
                        :color="modelValue == icon ? 'blue' : ''"
                        size="50"
                    >
                        {{ icon }}
                    </v-icon>
                </v-col>
            </v-row>
    </template>
    </fa-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { FAIcons } from './IconCollection';
import { Ref } from 'vue';
import { useDisplay } from 'vuetify/lib/framework.mjs';

const props = defineProps({
    modelValue: {
        type: [String, null],
        required: true,
        default: null
    },
})

const value = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            emits('update:modelValue', value);
        }
    })

const emits = defineEmits([
    'update:modelValue'
]);

const { mobile } = useDisplay();

let icons: Array<string> = FAIcons;

function didSelectIcon(icon: string)
{
    value.value = icon;
    open.value = false
}

function removeIcon() {
    value.value = null;
}
const searchString: Ref<string> = ref("");
const open: Ref<Boolean> = ref(false);

const filteredIcons = computed(() => {
    if (searchString.value == "" || searchString.value == null) {
        return icons;
    }

    return icons.filter((icon: String) => {
        return icon.includes(searchString.value)
    })
})
</script>
